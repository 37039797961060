import styled, { keyframes, css } from "styled-components";

const ldsRing = keyframes`
    0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledLoader = styled.div`
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;

  ${props =>
    props.size === "medium" &&
    css`
      height: 40px;
      width: 40px;
    `}

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 2px;
    border: 2px solid var(--color-whiteColor);
    border-radius: 50%;
    animation: ${ldsRing} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--color-whiteColor) transparent transparent transparent;
  }

  button:hover & div {
    border: 2px solid var(--color-secondary);
    border-color: var(--color-secondary) transparent transparent transparent;
  }

  ${props =>
    props.theme &&
    props.theme === "secondary" &&
    css`
      div {
        border: 2px solid var(--color-primaryColor);
        border-color: var(--color-primaryColor) transparent transparent transparent;
      }
      button:hover & div {
        border: 2px solid var(--color-whiteColor);
        border-color: var(--color-whiteColor) transparent transparent transparent;
      }
    `}

  ${props =>
    props.theme &&
    props.theme === "ternary" &&
    css`
      button:hover & div {
        border: 2px solid var(--color-secondary);
        border-color: var(--color-secondary) transparent transparent transparent;
      }
    `}

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

export default StyledLoader;
