import React, { Children } from "react";
import uniqueId from "lodash/uniqueId";
import PropTypes from "prop-types";
import StyledH3 from "./StyledH3";

const H3 = props => (
  <StyledH3 key={uniqueId()} {...props} className="custom-h3">
    {Children.toArray(props.children)}
  </StyledH3>
);

export default H3;

H3.propTypes = {
  /** Couleur */
  color: PropTypes.string,
  /** Alignement du titre */
  align: PropTypes.oneOf(["left", "center", "right"]),
  /** Test-transform */
  txtTransform: PropTypes.oneOf(["uppercase", "lowercase", "capitalize"]),
  /** Margin à appliquer */
  margin: PropTypes.string,
  /** Contenu */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object, PropTypes.element]),
};

H3.defaultProps = {
  color: "var(--color-neutralDark)",
  align: "left",
  children: null,
  margin: "0 0 1rem",
  txtTransform: null,
};
