import styled from "styled-components";
import { fontWeight } from "../../global-styles.js";

const StyledTextDecorator = styled.span`
  color: ${props => props.color || null};
  font-weight: ${props => (props.fweight ? fontWeight[props.fweight] : null)};
  font-size: ${props => props.fsize || null};
  padding: ${props => props.padding || null};
`;

export default StyledTextDecorator;
