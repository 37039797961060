import styled, { css } from "styled-components";
import { breakpoints } from "@agence-numerique/common/lib/global-styles.js";
import { THEME_WHITE_LABEL, THEME_CUSTOM } from "@agence-numerique/common/lib/consts/ParametersApplication.const";

const StyledLoginWrapper = styled.div`

 /* White Label theme */
    ${props =>
      props.theme &&
    props.theme === THEME_WHITE_LABEL &&
      css`
        background: var(--color-whiteColor);
        box-shadow: var(--global-shadow);
        max-width: 800px;
        margin: 0 auto;
        padding: 1rem;
        border-radius: 3px;

         img {
      width: 150px;
      height: auto;
     }
      `}

/* Custom theme */
      ${props =>
      props.theme &&
    props.theme === THEME_CUSTOM &&
      css`
        background: var(--color-whiteColor);
        box-shadow: var(--custom-box-shadow);
        margin: 0 1rem;
        border-radius: 25px;
        display: flex;
        padding-top: 0px;
        margin: 0 auto;
        margin-top: 40px !important;
        max-width: 1200px;
        overflow: hidden;

         img {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      width: 100%;
      height: 100%;
      object-fit: cover;
     }
      .first-login-wrapper-right{
             height: 100%;
             width: 60%;
        }

        ${breakpoints.tablet`
    margin: auto;
  `}

        .logo {
          margin: 1rem auto 2rem;
        }

      `}`;

export default StyledLoginWrapper;