import React, { useState } from "react";
import { Field } from "formik";
import PropTypes from "prop-types";

import { FieldInputWithIconWrapper } from "@agence-numerique/common/lib/components/Form/FieldInputWithIconWrapper";
import { ReactComponent as EyeIcon } from "@agence-numerique/common/lib/assets/images/eye.svg";
import { ReactComponent as SightIcon } from "@agence-numerique/common/lib/assets/images/sight.svg";

const VisiblePasswordInput = ({ inputName, handleChange, notPastable }) => {
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const [value, setValue] = useState("");

  return (
    <FieldInputWithIconWrapper>
      {passwordIsVisible ? (
        <EyeIcon data-cy="passwordEyeIcon" onClick={() => setPasswordIsVisible(false)} />
      ) : (
        <SightIcon data-cy="passwordSightIcon" onClick={() => setPasswordIsVisible(true)} />
      )}
      <Field
        type={passwordIsVisible ? "text" : "password"}
        name={inputName}
        onChange={(e) => {
          setValue(e.target.value);
          if (handleChange) {
            handleChange(e);
          }
        }}
        value={value}
        onPaste={(e) => {
          if (notPastable) {
            e.preventDefault();
            return false;
          }
        }}
        data-cy={inputName + "-input"}
      />
    </FieldInputWithIconWrapper>
  );
};

VisiblePasswordInput.propTypes = {
  inputName: PropTypes.string,
  handleChange: PropTypes.func,
  notPastable: PropTypes.string,
};

export default VisiblePasswordInput;
