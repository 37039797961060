import React from "react";
import PropTypes from "prop-types";

import StyledFormSelect from "./StyledFormSelect";

const FormSelect = props => (
  <StyledFormSelect {...props} onClick={props.onClick} value={props.selected}>
    {props.defaultOptionDisabled ? (
      <option disabled>{props.defaultOption}</option>
    ) : props.defaultOption ? (
      <option value={props.defaultOptionValue || ""}>{props.defaultOption}</option>
    ) : (
      ""
    )}
    {props.options.map(({ name, value }) => (
      <option key={value} value={value}>
        {name}
      </option>
    ))}
  </StyledFormSelect>
);

FormSelect.propTypes = {
  onClick: PropTypes.func,
  defaultValue: PropTypes.string,
  defaultOption: PropTypes.string,
  defaultOptionValue: PropTypes.string,
  defaultOptionDisabled: PropTypes.bool,
  options: PropTypes.array,
  selected: PropTypes.bool,
};

export default FormSelect;
