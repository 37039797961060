import React from "react";
import StyledLoginWrapper from "./StyledLoginWrapper";
import PropTypes from "prop-types";
import { THEME_WHITE_LABEL, THEME_CUSTOM } from "@agence-numerique/common/lib/consts/ParametersApplication.const";


const LoginWrapper = ({ portail_theme, ...props }) => {
  return (
    <>
        <StyledLoginWrapper theme={portail_theme}>{props.children}</StyledLoginWrapper>
    </>
  );
};

LoginWrapper.propTypes = {
  /** Theme */
  portail_theme: PropTypes.oneOf([ THEME_WHITE_LABEL ,  THEME_CUSTOM ]),
  children: PropTypes.any,
};

LoginWrapper.defaultProps = {
  portail_theme: 0,
};

export default LoginWrapper;
