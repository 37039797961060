import React from "react";
import StyledLoginWrapperRight from "./StyledLoginWrapperRight";
import {
  THEME_WHITE_LABEL,
  THEME_CUSTOM,
} from "@agence-numerique/common/lib/consts/ParametersApplication.const";

import PropTypes from "prop-types";

const LoginWrapperRight = ({ portail_theme, ...props }) => {
  return (
    <>
        <StyledLoginWrapperRight theme={portail_theme}>{props.children}</StyledLoginWrapperRight>
    </>
  );
};

LoginWrapperRight.propTypes = {
  /** Theme */
  portail_theme: PropTypes.oneOf([ THEME_WHITE_LABEL ,  THEME_CUSTOM ]),
  children: PropTypes.any,
};

LoginWrapperRight.defaultProps = {
  portail_theme: "0",
};

export default LoginWrapperRight;
