import {
  ROUTE_KEY_GET_PROPOSAL,
  ROUTE_KEY_LIST_PROPOSALS,
  ROUTE_KEY_CHECKIN_GET,
  ROUTE_KEY_APPLICANTFILE,
  ROUTE_KEY_APPLICANTFILE_LIST,
  ROUTE_KEY_APPLICANTFILE_SAVE,
  ROUTE_KEY_APPLICANTFILE_COMPLETE,
  ROUTE_KEY_APPLICANTFILE_VALIDATE,
  ROUTE_KEY_APPLICANTFILE_REMIND,
  ROUTE_KEY_ROOT_PROPOSITIONS,
  ROUTE_KEY_DOCUMENT_ID,
  ROUTE_KEY_DOCUMENT,
  ROUTE_KEY_GET_DASHBOARD_STATISTICS,
  ROUTE_KEY_CHANGE_FOLDER_MANAGER,
  ROUTE_KEY_GET_SECURITY_PAYMENT,
  ROUTE_KEY_ROOT_PROPOSITION,
  ROUTE_KEY_GET_COMPANY_INFO,
  ROUTE_KEY_GET_AGENCY_INFO,
  ROUTE_KEY_LIST_SECURITY_DEPOSIT_PAYMENT_MODE,
  ROUTE_KEY_LIST_COMPANY_PAYMENT_MODE,
  ROUTE_KEY_GET_SCELLIUS_REDIRECTION_FORM,
  ROUTE_KEY_SCELLIUS_REDIRECTION_FORM,
  ROUTE_KEY_CREATE_HOUSINGCOMISSION,
  ROUTE_KEY_UPDATE_HOUSINGCOMISSION_STATUS,
  ROUTE_KEY_HOUSINGREQUEST,
  ROUTE_KEY_GET_BAIL_DOCUMENTS,
  ROUTE_KEY_GET_BAIL_DOCUMENT_STREAM,
  ROUTE_KEY_IFRAME_CALENDLY,
  ROUTE_KEY_IFRAME_CALENDLY_PROPOSAL,
  ROUTE_KEY_LIST_CONVERSATION_PROSPECT,
  ROUTE_KEY_LIST_CONVERSATION_PROSPECT_READ,
  ROUTE_KEY_CREATE_INSURANCE,
  ROUTE_KEY_PUT_INSURANCE,
  ROUTE_KEY_PUT_INSURANCE_FILE,
  ROUTE_KEY_PUT_INSURANCE_STATUS,
  ROUTE_KEY_POSSIBLE_DEBIT_DAYS,
  ROUTE_KEY_POSSIBLE_DEBIT_DAYS_FOR_COMPANY,
  ROUTE_KEY_LIST_KEYHANDOVER_MODE,
  ROUTE_KEY_PARAM_APP_LIST,
  ROUTE_KEY_LIST_HOUSINGREQUEST,
  ROUTE_KEY_GET_HOUSINGREQUEST,
  ROUTE_KEY_LIST_CALENDLY,
  ROUTE_KEY_LIST_CALENDLY_FOR_AGENT,
  ROUTE_KEY_HOUSING_REQUEST_HISTORIES,
  ROUTE_KEY_GET_USER_LOGS,
  ROUTE_KEY_LIST_CONVERSATION,
  ROUTE_KEY_GET_CONVERSATION,
  ROUTE_KEY_READ_CONVERSATION,
  ROUTE_KEY_POST_MESSAGE_CONVERSATION,
  ROUTE_KEY_ROUTING,
  ROUTE_KEY_ROUTING_EXECUTE,
  PLACEHOLDER_FRONT_URL,
  PLACEHOLDER_PAYMENT_ID,
  PLACEHOLDER_PROPOSAL_ID,
  PLACEHOLDER_HOUSING_REQUEST_ID,
  PLACEHOLDER_DOCUMENT_ID,
  PLACEHOLDER_STATUS,
  PLACEHOLDER_HOUSING_COMMISSION_ID,
  PLACEHOLDER_COMPANY_ID,
  PLACEHOLDER_INSURANCE_ID,
  PLACEHOLDER_ESTATEAGENT_ID,
  PLACEHOLDER_CONVERSATION_ID,
  PLACEHOLDER_GENERIC_ID,
} from "consts/Parameters.const";

import {
  APPLICANTFILE_SUBMITTYPE_SAVE,
  APPLICANTFILE_SUBMITTYPE_COMPLETE,
  APPLICANTFILE_SUBMITTYPE_VALIDATE,
} from "consts/ApplicantFile.const.js";

export const ConnectParameters = (data) => {
  try {
    return {
      ...data,
      [ROUTE_KEY_GET_PROPOSAL]:
        data[ROUTE_KEY_GET_PROPOSAL] ||
        `${data[ROUTE_KEY_ROOT_PROPOSITIONS]}/${PLACEHOLDER_PROPOSAL_ID}?fields=housingCommissionResponse`,
      [ROUTE_KEY_LIST_PROPOSALS]:
        data[ROUTE_KEY_LIST_PROPOSALS] ||
        `${data[ROUTE_KEY_ROOT_PROPOSITIONS]}?housingRequest=${PLACEHOLDER_HOUSING_REQUEST_ID}`,
      [ROUTE_KEY_APPLICANTFILE_LIST]:
        data[ROUTE_KEY_APPLICANTFILE_LIST] || `${data[ROUTE_KEY_APPLICANTFILE]}/${PLACEHOLDER_HOUSING_REQUEST_ID}/list`,
      [ROUTE_KEY_APPLICANTFILE_SAVE]:
        data[ROUTE_KEY_APPLICANTFILE_SAVE] ||
        `${data[ROUTE_KEY_APPLICANTFILE]}/${PLACEHOLDER_HOUSING_REQUEST_ID}/${APPLICANTFILE_SUBMITTYPE_SAVE}`,
      [ROUTE_KEY_APPLICANTFILE_COMPLETE]:
        data[ROUTE_KEY_APPLICANTFILE_COMPLETE] ||
        `${data[ROUTE_KEY_APPLICANTFILE]}/${PLACEHOLDER_HOUSING_REQUEST_ID}/${APPLICANTFILE_SUBMITTYPE_COMPLETE}`,
      [ROUTE_KEY_APPLICANTFILE_VALIDATE]:
        data[ROUTE_KEY_APPLICANTFILE_VALIDATE] ||
        `${data[ROUTE_KEY_APPLICANTFILE]}/${PLACEHOLDER_HOUSING_REQUEST_ID}/${APPLICANTFILE_SUBMITTYPE_VALIDATE}`,
      [ROUTE_KEY_APPLICANTFILE_REMIND]:
        data[ROUTE_KEY_APPLICANTFILE_REMIND] ||
        `${data[ROUTE_KEY_APPLICANTFILE]}/${PLACEHOLDER_HOUSING_REQUEST_ID}/remind`,
      [ROUTE_KEY_DOCUMENT_ID]: data[ROUTE_KEY_DOCUMENT_ID] || `${data[ROUTE_KEY_DOCUMENT]}/${PLACEHOLDER_DOCUMENT_ID}`,
      [ROUTE_KEY_GET_DASHBOARD_STATISTICS]:
        data[ROUTE_KEY_GET_DASHBOARD_STATISTICS] || "app_nadev.php/api/re/v1/housingrequests/statistics/folders", // TODO Remove as soon as back give that in parameters
      [ROUTE_KEY_CHECKIN_GET]:
        data[ROUTE_KEY_CHECKIN_GET] ||
        `app_nadev.php/api/re/v1/housingrequest/${PLACEHOLDER_HOUSING_REQUEST_ID}/checkin`, // TODO Remove as soon as back give that in parameters
      [ROUTE_KEY_GET_SECURITY_PAYMENT]:
        data[ROUTE_KEY_GET_SECURITY_PAYMENT] ||
        `${data[ROUTE_KEY_ROOT_PROPOSITION]}/${PLACEHOLDER_PROPOSAL_ID}/security-deposit-current-payment`,
      [ROUTE_KEY_GET_COMPANY_INFO]:
        data[ROUTE_KEY_GET_COMPANY_INFO] || `${data[ROUTE_KEY_ROOT_PROPOSITIONS]}/${PLACEHOLDER_GENERIC_ID}/company`,
      [ROUTE_KEY_GET_AGENCY_INFO]:
        data[ROUTE_KEY_GET_AGENCY_INFO] || `${data[ROUTE_KEY_ROOT_PROPOSITIONS]}/${PLACEHOLDER_GENERIC_ID}/agency`,
      [ROUTE_KEY_LIST_SECURITY_DEPOSIT_PAYMENT_MODE]:
        data[ROUTE_KEY_LIST_SECURITY_DEPOSIT_PAYMENT_MODE] ||
        `${data[ROUTE_KEY_LIST_COMPANY_PAYMENT_MODE]}?context=securityDeposit`,
      [ROUTE_KEY_GET_SCELLIUS_REDIRECTION_FORM]:
        data[ROUTE_KEY_GET_SCELLIUS_REDIRECTION_FORM] ||
        `${data[ROUTE_KEY_SCELLIUS_REDIRECTION_FORM]}?payment_mode_id=${PLACEHOLDER_PAYMENT_ID}&normal_return_url=${PLACEHOLDER_FRONT_URL}&proposition_id=${PLACEHOLDER_PROPOSAL_ID}`,
      [ROUTE_KEY_CHANGE_FOLDER_MANAGER]:
        data[ROUTE_KEY_CHANGE_FOLDER_MANAGER] ||
        `app_nadev.php/api/re/v1/housingrequests/${PLACEHOLDER_HOUSING_REQUEST_ID}/reassign`, // TODO Remove as soon as back give that in parameters
      [ROUTE_KEY_GET_BAIL_DOCUMENTS]:
        data[ROUTE_KEY_GET_BAIL_DOCUMENTS] ||
        `${data[ROUTE_KEY_HOUSINGREQUEST]}/${PLACEHOLDER_HOUSING_REQUEST_ID}/document`,
      [ROUTE_KEY_GET_BAIL_DOCUMENT_STREAM]:
        data[ROUTE_KEY_GET_BAIL_DOCUMENT_STREAM] ||
        `${data[ROUTE_KEY_HOUSINGREQUEST]}/${PLACEHOLDER_HOUSING_REQUEST_ID}/document/${PLACEHOLDER_DOCUMENT_ID}`,
      [ROUTE_KEY_UPDATE_HOUSINGCOMISSION_STATUS]:
        data[ROUTE_KEY_UPDATE_HOUSINGCOMISSION_STATUS] ||
        `${data[ROUTE_KEY_CREATE_HOUSINGCOMISSION]}/${PLACEHOLDER_HOUSING_COMMISSION_ID}/${PLACEHOLDER_STATUS}`,
      [ROUTE_KEY_PUT_INSURANCE]:
        data[ROUTE_KEY_PUT_INSURANCE] || `${data[ROUTE_KEY_CREATE_INSURANCE]}/${PLACEHOLDER_INSURANCE_ID}`,
      [ROUTE_KEY_PUT_INSURANCE_FILE]:
        data[ROUTE_KEY_PUT_INSURANCE_FILE] || `${data[ROUTE_KEY_CREATE_INSURANCE]}/${PLACEHOLDER_INSURANCE_ID}/file`,
      [ROUTE_KEY_PUT_INSURANCE_STATUS]:
        data[ROUTE_KEY_PUT_INSURANCE_STATUS] ||
        `${data[ROUTE_KEY_CREATE_INSURANCE]}/${PLACEHOLDER_INSURANCE_ID}/status`,
      [ROUTE_KEY_IFRAME_CALENDLY_PROPOSAL]:
        data[ROUTE_KEY_IFRAME_CALENDLY_PROPOSAL] ||
        `${data[ROUTE_KEY_IFRAME_CALENDLY]}?proposition_id=${PLACEHOLDER_PROPOSAL_ID}`,
      [ROUTE_KEY_LIST_CONVERSATION_PROSPECT_READ]:
        data[ROUTE_KEY_LIST_CONVERSATION_PROSPECT_READ] || `${data[ROUTE_KEY_LIST_CONVERSATION_PROSPECT]}/read`,
      [ROUTE_KEY_POSSIBLE_DEBIT_DAYS_FOR_COMPANY]:
        data[ROUTE_KEY_POSSIBLE_DEBIT_DAYS_FOR_COMPANY] ||
        `${data[ROUTE_KEY_POSSIBLE_DEBIT_DAYS]}?company=${PLACEHOLDER_COMPANY_ID}`,
      [ROUTE_KEY_PARAM_APP_LIST]: data[ROUTE_KEY_PARAM_APP_LIST],
      [ROUTE_KEY_LIST_KEYHANDOVER_MODE]: data[ROUTE_KEY_LIST_KEYHANDOVER_MODE],
      [ROUTE_KEY_GET_HOUSINGREQUEST]:
        data[ROUTE_KEY_GET_HOUSINGREQUEST] ||
        `${data[ROUTE_KEY_LIST_HOUSINGREQUEST]}/${PLACEHOLDER_HOUSING_REQUEST_ID}`,
      [ROUTE_KEY_LIST_CALENDLY_FOR_AGENT]:
        data[ROUTE_KEY_LIST_CALENDLY_FOR_AGENT] ||
        `${data[ROUTE_KEY_LIST_CALENDLY]}?estate_agent=${PLACEHOLDER_ESTATEAGENT_ID}`,
      [ROUTE_KEY_GET_USER_LOGS]:
        data[ROUTE_KEY_GET_USER_LOGS] ||
        `${data[ROUTE_KEY_HOUSING_REQUEST_HISTORIES]}/${PLACEHOLDER_HOUSING_REQUEST_ID}`,
      [ROUTE_KEY_GET_CONVERSATION]:
        data[ROUTE_KEY_GET_CONVERSATION] || `${data[ROUTE_KEY_LIST_CONVERSATION]}/${PLACEHOLDER_CONVERSATION_ID}`,
      [ROUTE_KEY_POST_MESSAGE_CONVERSATION]:
        data[ROUTE_KEY_POST_MESSAGE_CONVERSATION] ||
        `${data[ROUTE_KEY_LIST_CONVERSATION]}/${PLACEHOLDER_CONVERSATION_ID}/messages`,
      [ROUTE_KEY_READ_CONVERSATION]:
        data[ROUTE_KEY_READ_CONVERSATION] || `${data[ROUTE_KEY_LIST_CONVERSATION]}/${PLACEHOLDER_CONVERSATION_ID}/read`,
      [ROUTE_KEY_ROUTING_EXECUTE]: data[ROUTE_KEY_ROUTING_EXECUTE] || `routings/${PLACEHOLDER_GENERIC_ID}/execute`,
      [ROUTE_KEY_ROUTING]: data[ROUTE_KEY_ROUTING] || `routings`,
    };
  } catch (error) {
    console.error("Issue during storage of parameters ", error);
  }
};
