import styled from "styled-components";
import { breakpoints } from "@agence-numerique/common/lib/global-styles.js";

const StyledLoginWrapperLeft = styled.div`
        width: 40%;
        display: none;

        img {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 0;
      vertical-align: middle;
     }

        ${breakpoints.tablet`
    display: block;
  `}
      `;

export default StyledLoginWrapperLeft;