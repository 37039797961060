/* eslint-disable react/prop-types */
import React, { useEffect, Children } from "react";
import Cookies from "./Cookies";
import docCookies from "@agence-numerique/common/lib/utils/Cookie";
import { COOKIE_PORTAIL_URI } from "@agence-numerique/common/lib/consts/Application.const";

const Layout = ({ children }) => {
  useEffect(() => {
    if (!docCookies.hasItem(COOKIE_PORTAIL_URI)) {
      docCookies.setItem(COOKIE_PORTAIL_URI, window.location.origin + window.location.pathname);
    }
  }, []);

  return (
    <main>
      {Children.toArray(children)}
      <Cookies />
    </main>
  );
};

export default Layout;
