import {
  //   PARAMETERS_APPLICATION_PASSWORD_KEY,
  PARAMETERS_APPLICATION_PASSWORD_CHECK_CHAR_REQUIRED_KEY,
  PARAMETERS_APPLICATION_PASSWORD_CHECK_UPPERCASE_REQUIRED_KEY,
  PARAMETERS_APPLICATION_PASSWORD_CHECK_LOWERCASE_REQUIRED_KEY,
  PARAMETERS_APPLICATION_PASSWORD_CHECK_NUMBER_REQUIRED_KEY,
  PARAMETERS_APPLICATION_PASSWORD_CHECK_SPECIAL_CHAR_REQUIRED_KEY,
  PARAMETERS_APPLICATION_PASSWORD_CHECK_SPECIAL_CHAR_LIST_KEY,
} from "@agence-numerique/common/lib/consts/ParametersApplication.const";

export function validatePassword(password, passwordValidateParameters) {
  let error = {
    passwordConfirm: false,
    CharRequiredValid: false,
    CharRequiredValidKey: passwordValidateParameters[PARAMETERS_APPLICATION_PASSWORD_CHECK_CHAR_REQUIRED_KEY],
    SpecialRequiredValid: false,
    SpecialRequiredValidKey:
      passwordValidateParameters[PARAMETERS_APPLICATION_PASSWORD_CHECK_SPECIAL_CHAR_REQUIRED_KEY],
    SpecialRequiredValidListKey:
      passwordValidateParameters[PARAMETERS_APPLICATION_PASSWORD_CHECK_SPECIAL_CHAR_LIST_KEY],
    LowercaseRequiredValid: false,
    LowercaseRequiredValidKey: passwordValidateParameters[PARAMETERS_APPLICATION_PASSWORD_CHECK_LOWERCASE_REQUIRED_KEY],
    UppercaseRequiredValid: false,
    UppercaseRequiredValidKey: passwordValidateParameters[PARAMETERS_APPLICATION_PASSWORD_CHECK_UPPERCASE_REQUIRED_KEY],
    NumberRequiredValid: false,
    NumberRequiredValidKey: passwordValidateParameters[PARAMETERS_APPLICATION_PASSWORD_CHECK_NUMBER_REQUIRED_KEY],
  };

  if (error.CharRequiredValidKey) {
    if (password?.length >= error.CharRequiredValidKey) {
      error.CharRequiredValid = true;
    } else {
      error.CharRequiredValid = false;
    }
  }

  if (error.UppercaseRequiredValidKey) {
    if ((password.match(/[A-Z]/g) || []).length >= error.UppercaseRequiredValidKey) {
      error.UppercaseRequiredValid = true;
    } else {
      error.UppercaseRequiredValid = false;
    }
  }

  if (error.LowercaseRequiredValidKey) {
    if ((password.match(/[a-z]/g) || []).length >= error.LowercaseRequiredValidKey) {
      error.LowercaseRequiredValid = true;
    } else {
      error.LowercaseRequiredValid = false;
    }
  }

  if (error.NumberRequiredValidKey) {
    if ((password.match(/[0-9]/g) || []).length >= error.NumberRequiredValidKey) {
      error.NumberRequiredValid = true;
    } else {
      error.NumberRequiredValid = false;
    }
  }
  if (error.SpecialRequiredValidKey) {
    const regex = new RegExp(
      "[" +
        error.SpecialRequiredValidListKey.replace("[", "\\[")
          .replace("]", "\\]")
          .replace("^", "\\^")
          .replace("$", "\\$")
          .replace(".", "\\.")
          .replace("|", "\\|")
          .replace("?", "\\?")
          .replace("*", "\\*")
          .replace("+", "\\+")
          .replace("(", "\\(")
          .replace(")", "\\)") +
        "]",
      "g",
    );
    if ((password.match(regex) || []).length >= error.SpecialRequiredValidKey) {
      error.SpecialRequiredValid = true;
    } else {
      error.SpecialRequiredValid = false;
    }
  }
  return error;
}

export function passwordValidationParameters(passwordValidateParameters) {
  let passwordValidationParameters = {
    passwordConfirm: false,
    CharRequiredValid: false,
    CharRequiredValidKey: passwordValidateParameters[PARAMETERS_APPLICATION_PASSWORD_CHECK_CHAR_REQUIRED_KEY],
    SpecialRequiredValid: false,
    SpecialRequiredValidKey:
      passwordValidateParameters[PARAMETERS_APPLICATION_PASSWORD_CHECK_SPECIAL_CHAR_REQUIRED_KEY],
    SpecialRequiredValidListKey:
      passwordValidateParameters[PARAMETERS_APPLICATION_PASSWORD_CHECK_SPECIAL_CHAR_LIST_KEY],
    LowercaseRequiredValid: false,
    LowercaseRequiredValidKey: passwordValidateParameters[PARAMETERS_APPLICATION_PASSWORD_CHECK_LOWERCASE_REQUIRED_KEY],
    UppercaseRequiredValid: false,
    UppercaseRequiredValidKey: passwordValidateParameters[PARAMETERS_APPLICATION_PASSWORD_CHECK_UPPERCASE_REQUIRED_KEY],
    NumberRequiredValid: false,
    NumberRequiredValidKey: passwordValidateParameters[PARAMETERS_APPLICATION_PASSWORD_CHECK_NUMBER_REQUIRED_KEY],
  };

  return passwordValidationParameters;
}
