import React from "react";
//import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import PropTypes from "prop-types";

//import A from '../A';
import Button from "../Button";
import { Modal, openModal } from "../Alert/Modal";

const FormWithMentions = ({formId = 'formId', context = 'gestionnaire', mode = 'simple', ...props}) => {
    const { t, i18n } = useTranslation();
    {
      /*const [small, setSmall] = useState(true);
   const toggleText = () => {
        if (small === true) {
            setSmall(false);
        } else {
            setSmall(true);
        }
    };*/
    }
    const formMentions = i18n.t("formMentions", {returnObjects: true});
    if (context === 'gestionnaire'
        || ! ( ( context === 'prospect' || context === 'portail' ) && formMentions[formId]?.small) ) {
        return <Formik {...props}>
            {props.children}
        </Formik>;
    }
   {/*  let addFormik = <>
        <br />
        {small && <div dangerouslySetInnerHTML={{__html: formMentions[formId].small}} />}
        {formMentions[formId]?.big &&
            <>
                {!small &&
                    <>
                        <div dangerouslySetInnerHTML={{__html: formMentions[formId]?.big}} />
                        <br />
                        <center>
                            <A skin="link" onClick={toggleText}>{t('mentions.btn.showLess')}</A>
                        </center>
                    </>
                }
                {small &&
                    <center>
                        <A skin="link" onClick={toggleText}>{t('mentions.btn.showMore')}</A>
                    </center>
                }
            </>
        }
    </>; */}
    if (mode === 'modal') {
       let  addFormik = <>
            <Button onClick={() => openModal('modal' + formId)}>{t('mentions.modal.btn.launch')}</Button>
            <Modal
                id={'modal' + formId}
                cancelLabel={t('mentions.modal.btn.cancel')}
                confirmLabel={t('mentions.modal.btn.ok')}
                confirmActionLoading={false}
            >
                {addFormik}
            </Modal>
        </>;
    }
    return (
        <>
            <Formik {...props}>
                {props.children}
            </Formik>
            {/* on commente l'affichage des bouton de message additionnels*/}
            {/* addFormik*/}
        </>
    );
}

FormWithMentions.propTypes = {
    formId: PropTypes.string,
    context: PropTypes.string,
    mode: PropTypes.string,
    children: PropTypes.any
  };

export default FormWithMentions;
