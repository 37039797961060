import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import H1 from "@agence-numerique/common/lib/components/H1";
import { FormFieldWrapper, FormLabel, FormFooter } from "@agence-numerique/common/lib/components/Form";
import Button from "@agence-numerique/common/lib/components/Button";
import { breakpoints } from "@agence-numerique/common/lib/global-styles.js";
import { ServerMessage } from "@agence-numerique/common/lib/components/Form";
import docCookies from "@agence-numerique/common/lib/utils/Cookie";
import { Link } from "@agence-numerique/common/lib/components/Link";
import chevronIcon from "@agence-numerique/common/lib/assets/images/chevron.png";
import { logOut } from "@agence-numerique/common/lib/utils/Helpers";

import middleware from "services/middleware";

const CustomForm = styled(Form)`
  ${breakpoints.tablet`
      padding: 0 0px;
  `};
`;

const LoginOnBeHalfForm = ({theme}) => {
  const { t } = useTranslation();
  const [loginOnBeHalfMessage, setLoginOnBeHalfMessage] = useState("");
  const [userType, setUserType] = useState("");
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
    let isLoginOnBeHalfAllowed = docCookies.getItem("logInOnBeHalf");
    if (isLoginOnBeHalfAllowed) {
      setUserType("admin");
    } else {
      setUserType("no");
    }
  }, [setUserType, setLoaded]);

  if (!isLoaded) {
      return <></>;
  }
  if (!(userType && userType === "admin")) {
    return <Redirect push to="/" />;
  }

  return (
    <>
      <H1 theme={theme}>{t("login.onBeHalf.title")}</H1>
      <Formik
        enableReinitialize
        initialValues={{ emailOrUsername: "" }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const loginOnBeHalf = await middleware.loginOnBeHalf(values.emailOrUsername);
            if (loginOnBeHalf.token) {
              docCookies.setItem("token", loginOnBeHalf.token);
              docCookies.removeItem("logInOnBeHalf");
              window.location.href = "/";
              return <></>;
            } else {
              setLoginOnBeHalfMessage(t("login.server_error"));
              setSubmitting(false);
            }
          } catch (error) {
            console.log("Error:", error);
            setLoginOnBeHalfMessage(error);
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => (
          <CustomForm data-cy="form">
            <FormFieldWrapper>
              <FormLabel big htmlFor="emailOrUsername">
                {t("login.onBeHalf.subTitle")}
              </FormLabel>
              <Field type="text" name="emailOrUsername" />
              <ErrorMessage className="form-error-input" name="emailOrUsername" component="div" />
            </FormFieldWrapper>
            {loginOnBeHalfMessage.length > 0 && <ServerMessage>{loginOnBeHalfMessage}</ServerMessage>}
            <Button theme={theme}  margin="1rem auto" isLoading={isSubmitting} type="submit" data-cy="loginAuthButton">
              {t("login.onBeHalf.connectAs")}
            </Button>
            <FormFooter>
              <Link to="/" className="link--withIcon" onClick={() => logOut()}>
                {" "}
                <img src={chevronIcon} alt="" /> {t("passwordReset.to_login")}
              </Link>
            </FormFooter>
          </CustomForm>
        )}
      </Formik>
    </>
  );
};

LoginOnBeHalfForm.propTypes = {
  setUrlRedirect: PropTypes.func,
  userType: PropTypes.string,
  theme: PropTypes.string,
};

export default LoginOnBeHalfForm;
