import docCookies from "@agence-numerique/common/lib/utils/Cookie";
import { LIST_URL_DEV_BY_USER_TYPE, URL_DEV_PORTAIL } from "@agence-numerique/common/lib/consts/Application.const";
import middleware from "services/middleware";

export async function userRedirection() {
  let redirectionUrl = await middleware.getRedirect();
  let isAdmin;
  try {
    isAdmin = redirectionUrl?.split('/')?.slice(-2,-1)?.pop();
    isAdmin = isAdmin && isAdmin === 'admin';
  } catch (e) {
    console.log('Error:', e);
  }
  // This is for local purpose
  if (window.location.hostname === "localhost") {
    const user = await middleware.getMe();
    redirectionUrl = LIST_URL_DEV_BY_USER_TYPE[user?.type];
    if (!redirectionUrl) {
      redirectionUrl = URL_DEV_PORTAIL;
    }
  }
  if (isAdmin) {
    docCookies.removeItem("refresh_token");
    docCookies.setItem("logInOnBeHalf", true);
    window.location.href = '/#/admin';
    return;
  }
  if (window.location.href !== redirectionUrl) {
    window.location.href = redirectionUrl;
  }
}
