import React from "react";
// import { Redirect } from "react-router";
import middleware from "services/middleware";
import { isLoggedIn } from "@agence-numerique/common/lib/utils/Helpers";
import { Text } from "@agence-numerique/common/lib/components/Paragraph";
import { useTranslation } from "react-i18next";

const ProspectLogin = () => {
  const { t } = useTranslation();
  async function ssoProspectLoginRedirection() {
    if (!isLoggedIn()) {
      const urlSaml = await middleware.getSSOUrlSaml();
      window.location.href = urlSaml;
    }
  }
  ssoProspectLoginRedirection();

  return (
    <>
      <Text align="center">{t("generic.connecting")}</Text>
    </>
  );
};

export default ProspectLogin;
