export const APPLICANTFILE_AUTHORIZED_EXTENSIONS = ".jpeg,.jpg,.pdf,.png";
export const APPLICANTFILE_MAX_UPLOAD_SIZE = 10000000;

export const APPLICANTFILE_CODE_APPLICANT = "D";
export const APPLICANTFILE_CODE_COAPPLICANT = "C";
export const APPLICANTFILE_CODE_BOTH = "B";

export const APPLICANTFILE_SUBMITTYPE_SAVE = "save";
export const APPLICANTFILE_SUBMITTYPE_COMPLETE = "complete";
export const APPLICANTFILE_SUBMITTYPE_VALIDATE = "validate";

export const APPLICANTFILE_STATUS_INIT = "INIT";
export const APPLICANTFILE_STATUS_INCOMPLETE = "INCOMPLETE";
export const APPLICANTFILE_STATUS_COMPLETE_PROSPECT = "COMPLETE_PROSPECT";
export const APPLICANTFILE_STATUS_COMPLETE = "COMPLETE";

export const APPLICANTFILE_STATUS_LIST_AWAITING_MANAGER_ACTION = [
  APPLICANTFILE_STATUS_INIT,
  APPLICANTFILE_STATUS_COMPLETE_PROSPECT,
];
export const APPLICANTFILE_STATUS_LIST_AWAITING_CLIENT_ACTION = [APPLICANTFILE_STATUS_INCOMPLETE];

export const APPLICANTFILE_LIST_STATUS_CAN_UPDATE = [APPLICANTFILE_STATUS_INIT, APPLICANTFILE_STATUS_INCOMPLETE];

export const DOCUMENT_TYPE_STATUS_INIT = "NEW";
export const DOCUMENT_TYPE_STATUS_SAVED = "RECORDED";
export const DOCUMENT_TYPE_STATUS_ERROR = "REFUSED";
export const DOCUMENT_TYPE_STATUS_VALIDATED = "ACCEPTED";
export const DOCUMENT_TYPE_TYPE_APPLICANT = "demandeur";
export const DOCUMENT_TYPE_TYPE_COAPPLICANT = "co-demandeur";

export const DOCUMENT_TYPE_LIST_STATUS_UPLOADED = [DOCUMENT_TYPE_STATUS_SAVED, DOCUMENT_TYPE_STATUS_VALIDATED];

export const DOCUMENT_TYPE_LIST_STATUS_PROCESSED = [DOCUMENT_TYPE_STATUS_VALIDATED, DOCUMENT_TYPE_STATUS_ERROR];

export const DOCUMENT_STATUS_SAVED = "RECORDED";
export const DOCUMENT_STATUS_ERROR = "REFUSED";
export const DOCUMENT_STATUS_VALIDATED = "ACCEPTED";
export const DOCUMENT_STATUS_REJECTED = "REJECTED";
export const DOCUMENT_STATUS_PREVALIDATED = "PREVALIDATED";
export const DOCUMENT_STATUS_SUBMITTED = "SUBMITTED";
export const DOCUMENT_STATUS_UNVALIDATED = "UNVALIDATED";

export const DOCUMENT_EXTERNAL_ERROR_CODE_OK = "OK";
export const DOCUMENT_EXTERNAL_ERROR_CODE_KO = "KO";
export const DOCUMENT_EXTERNAL_ERROR_CODE_SKIPPED = "SKIPPED";

export const DOCUMENT_LIST_STATUS_UPLOADED = [DOCUMENT_STATUS_SAVED, DOCUMENT_STATUS_VALIDATED];
export const DOCUMENT_LIST_STATUS_CAN_DELETE = [DOCUMENT_STATUS_SAVED, DOCUMENT_STATUS_ERROR, DOCUMENT_STATUS_REJECTED];
export const DOCUMENT_LIST_STATUS_CAN_DELETE_MANAGER = [
  ...DOCUMENT_LIST_STATUS_CAN_DELETE,
  DOCUMENT_STATUS_UNVALIDATED,
];
