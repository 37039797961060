import React from "react";
import PropTypes from "prop-types";
import StyledLoader from "./StyledLoader";

const Loader = ({ theme, size }) => (
  <StyledLoader theme={theme} size={size}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </StyledLoader>
);

Loader.propTypes = {
  /** Theme to apply */
  theme: PropTypes.oneOf(["primary", "secondary", "ternary"]),
  /** Size of the loader */
  size: PropTypes.oneOf(["medium", "small"]),
};

Loader.defaultProps = {
  theme: "primary",
  size: "small",
};

export default Loader;
