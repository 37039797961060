export const ConnectKeyHandoverMode = data => {
  let KeyHandoverModes = {};
  if (data) {
    data.forEach(d => {
      KeyHandoverModes[d.mode] = {
        id: d.id,
        name: d.name,
        type: d.mode,
      };
    });
  }
  
   return KeyHandoverModes;
  };