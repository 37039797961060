import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, Field, ErrorMessage } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import H1 from "@agence-numerique/common/lib/components/H1";
import A from "@agence-numerique/common/lib/components/A";
import { Text } from "@agence-numerique/common/lib/components/Paragraph";
import {
  FormWithMentions,
  FormFieldWrapper,
  FormLabel,
  ServerMessage,
} from "@agence-numerique/common/lib/components/Form";
import Button from "@agence-numerique/common/lib/components/Button";
import { breakpoints } from "@agence-numerique/common/lib/global-styles.js";
import { setToastMessageAction } from "@agence-numerique/common/lib/state/CommonActions";
import { PARAMETERS_APPLICATION_PASSWORD_KEY } from "@agence-numerique/common/lib/consts/ParametersApplication.const";
import middleware from "services/middleware";
import VisiblePasswordInput from "containers/Auth/VisiblePasswordInput";
import VisibleLoginInput from "containers/Auth/VisibleLoginInput";
import LoginErrorText from "containers/Auth/LoginErrorText";
import { validatePassword, passwordValidationParameters } from "utils/loginHelpers";
import * as Yup from "yup";

const CustomForm = styled(Form)`
  ${breakpoints.tablet`
      padding: 0 0px;
  `};
`;

const FirstLoginForm = ({ parameters, setToastMessage, applicationParameters, theme }) => {
  const { t } = useTranslation();
  const search = useLocation().search;
  const [firstLoginResponseMessage, setFirstLoginResponseMessage] = useState("in");
  const email = new URLSearchParams(search).get("email");
  const token = new URLSearchParams(search).get("token");

  const [modifyLogin, setModifyLogin] = useState(false);
  const [newLogin, setNewLogin] = useState(email);
  const [newLoginConfirm, setNewLoginConfirm] = useState();
  const [isLoginConfirmValid, setLoginConfirmValid] = useState(true);
  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();
  const [isLoginEmailValid, setLoginEmailValid] = useState(true);

  const passwordParameters = applicationParameters[PARAMETERS_APPLICATION_PASSWORD_KEY];
  const [isPasswordConfirmValid, setPasswordConfirmValid] = useState();
  const [passwordValidation, setPasswordValidation] = useState(passwordValidationParameters(passwordParameters));


  let history = useHistory();

  const passwordValidationError = async (error) => setPasswordValidation(error);

  const onPasswordChange = async (password) => {
    let validationErrors = validatePassword(password, passwordParameters);
    await passwordValidationError(validationErrors);
    setPassword(password);
    if (passwordConfirm === password) {
      setPasswordConfirmValid(true);
    } else {
      setPasswordConfirmValid(false);
    }
  };

  const onNewLoginChange = (newLogin) => {
    setNewLogin(newLogin);
    if (newLogin === newLoginConfirm) {
      setLoginConfirmValid(true);
    } else {
      setLoginConfirmValid(false);
    }
    validateEmail(newLogin);
  };

  function validateEmail(value) {
    if (!value) {
      setLoginEmailValid(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      setLoginEmailValid(false);
    } else {
      setLoginEmailValid(true);
    }
  }

  const onNewLoginConfirmChange = (loginConfirm) => {
    setNewLoginConfirm(loginConfirm);
    setLoginConfirmValid(loginConfirm === newLogin);
  };

  const onPasswordConfirmChange = (passwordConfirm) => {
    setPasswordConfirm(passwordConfirm);
    setPasswordConfirmValid(passwordConfirm === password);
  };

  const cancelModifyLogin = () => {
    setModifyLogin(false);
    setNewLogin(email);
    setLoginEmailValid(true);
    setLoginConfirmValid(true);
  };

  const openModifyLogin = () => {
    setModifyLogin(true);
    setLoginEmailValid(false);
    setLoginConfirmValid(false);
  };

  const firstLogin = async () => {
    try {
      let firstLoginReturn = await middleware.firstLogin(password, token, newLogin);
      if (firstLoginReturn.status === 201) {
        history.push("/");
      } else if (firstLoginReturn?.data?.errors?.children) {
        if (firstLoginReturn?.data?.errors?.children?.login?.errors) {
          setFirstLoginResponseMessage(t("firstLogin.err_006"));
        } else if (
          firstLoginReturn?.data?.errors?.children.token.errors ||
          firstLoginReturn?.data?.errors?.children.password.errors
        ) {
          setFirstLoginResponseMessage(t("generic.error_occured"));
        } else if (firstLoginReturn?.data?.err_010) {
          setFirstLoginResponseMessage(t("firstLogin.err_010"));
        }
      } else {
        setFirstLoginResponseMessage(t("generic.error_occured"));
      }
    } catch (error) {
      setFirstLoginResponseMessage();
      switch (error?.status) {
        case 400:
          setFirstLoginResponseMessage(t("login.bad_password"));
          break;
        case 401:
          setFirstLoginResponseMessage(t("login.bad_password"));
          break;
        case 403:
          setFirstLoginResponseMessage(t("login.forbidden"));
          break;
        case 500:
          setFirstLoginResponseMessage(t("login.server_error"));
          break;
        default:
          setFirstLoginResponseMessage(t("generic.error_occured"));
          break;
      }
    }
  };

  return (
    <>
      <H1 theme={theme}>{t("passwordRecover.title")}</H1>
      <FormWithMentions
        context="portail"
        formId="FirstConnexion"
        initialValues={{ password: "", passwordConfirm: "", newLogin: "", newLoginConfirm: "" }}
        validationSchema={Yup.object().shape({
          newLogin: Yup.string().email("invalid email"),
        })}
        onSubmit={firstLogin}
      >
        {({ isSubmitting, errors }) => (
          <CustomForm data-cy="form">
            <FormFieldWrapper>
              <FormLabel small htmlFor="email">
                {t("firstLogin.my_login")}
              </FormLabel>
              <Field type="text" name="email" data-cy="email-input" placeholder={email} disabled />
              <ErrorMessage className="form-error-input" name="email" component="div" />
            </FormFieldWrapper>

            {modifyLogin && (
              <>
                <FormFieldWrapper>
                  <FormLabel small htmlFor="newLogin">
                    {t("firstLogin.modify_login")}
                  </FormLabel>
                  <VisibleLoginInput
                    type="email"
                    name="newLogin"
                    data-cy="new-login-input"
                    handleChange={(e) => onNewLoginChange(e.target.value)}
                  />
                  <ErrorMessage className="form-error-input" name="newLogin" component="div" />
                </FormFieldWrapper>

                <FormFieldWrapper>
                  <FormLabel small htmlFor="newLoginConfirm">
                    {t("firstLogin.new_login_confirm")}
                  </FormLabel>
                  <VisibleLoginInput
                    type="email"
                    name="newLoginConfirm"
                    data-cy="new-login-confirm-input"
                    handleChange={(e) => onNewLoginConfirmChange(e.target.value)}
                    notPastable="not pastable"
                  />
                  <LoginErrorText isfieldValid={isLoginEmailValid} error_text={t("firstLogin.login_email_valid")} />
                  <LoginErrorText isfieldValid={isLoginConfirmValid} error_text={t("firstLogin.login_confirm")} />
                </FormFieldWrapper>
              </>
            )}
            {!modifyLogin ? (
              <>
                <Text theme={theme} className="text__theme--portail--regular">
                  {t("firstLogin.modify")}
                  <A onClick={openModifyLogin} skin="link">
                    {t("firstLogin.modify_end")}
                  </A>
                  .
                </Text>
              </>
            ) : (
              <Text className="text__theme--login">
                <A skin="link" onClick={cancelModifyLogin}>
                  {t("firstLogin.cancel")}
                </A>
              </Text>
            )}

            <FormFieldWrapper>
              <FormLabel small htmlFor="password">
                {t("passwordRecover.new_password")}
              </FormLabel>
              <VisiblePasswordInput inputName="password" handleChange={(e) => onPasswordChange(e.target.value)} />
            </FormFieldWrapper>
            <FormFieldWrapper>
              <FormLabel small htmlFor="passwordConfirm">
                {t("passwordRecover.new_password_confirm")}
              </FormLabel>
              <VisiblePasswordInput
                inputName="passwordConfirm"
                handleChange={(e) => onPasswordConfirmChange(e.target.value)}
                notPastable="not pastable"
              />
              <Text theme={theme} className="text__theme--portail--title" >
                {t("passwordRecover.passwordRequiredTitle")}
              </Text>
              {!!passwordValidation.CharRequiredValidKey && (
                <LoginErrorText
                  isfieldValid={passwordValidation.CharRequiredValid}
                  error_text={t("passwordRecover.passwordCharRequired", {
                    count: passwordValidation.CharRequiredValidKey,
                  })}
                />
              )}
              {!!passwordValidation.UppercaseRequiredValidKey && (
                <LoginErrorText
                  isfieldValid={passwordValidation.UppercaseRequiredValid}
                  error_text={t("passwordRecover.passwordUppercaseRequired", {
                    count: passwordValidation.UppercaseRequiredValidKey,
                  })}
                />
              )}
              {!!passwordValidation.LowercaseRequiredValidKey && (
                <LoginErrorText
                  isfieldValid={passwordValidation.LowercaseRequiredValid}
                  error_text={t("passwordRecover.passwordLowercaseRequired", {
                    count: passwordValidation.LowercaseRequiredValidKey,
                  })}
                />
              )}
              {!!passwordValidation.NumberRequiredValidKey && (
                <LoginErrorText
                  isfieldValid={passwordValidation.NumberRequiredValid}
                  error_text={t("passwordRecover.passwordNumberRequired", {
                    count: passwordValidation.NumberRequiredValidKey,
                  })}
                />
              )}
              {!!passwordValidation.SpecialRequiredValidKey && (
                <LoginErrorText
                  isfieldValid={passwordValidation.SpecialRequiredValid}
                  error_text={t("passwordRecover.passwordSpecialRequired", {
                    count: passwordValidation.SpecialRequiredValidKey,
                    list: passwordValidation.SpecialRequiredValidListKey,
                  })}
                />
              )}
              <LoginErrorText isfieldValid={isPasswordConfirmValid} error_text={t("passwordRecover.passwordConfirm")} />
            </FormFieldWrapper>
            {firstLoginResponseMessage?.length > 3 && (
              <ServerMessage data-cy="loginErrorMessage">{firstLoginResponseMessage}</ServerMessage>
            )}
            <Button
              type="submit"
              inactiv={
                !passwordValidation.CharRequiredValid ||
                !passwordValidation.UppercaseRequiredValid ||
                !-passwordValidation.LowercaseRequiredValid ||
                !passwordValidation.NumberRequiredValid ||
                !passwordValidation.SpecialRequiredValid ||
                !isPasswordConfirmValid ||
                !isLoginConfirmValid ||
                !isLoginEmailValid
              }
              isLoading={isSubmitting}
              margin="1rem auto"
              theme={theme}
            >
              {t("passwordRecover.retrieve")}
            </Button>
          </CustomForm>
        )}
      </FormWithMentions>
    </>
  );
};

FirstLoginForm.propTypes = {
  parameters: PropTypes.object,
  setToastMessage: PropTypes.func,
  applicationParameters: PropTypes.object,
  theme: PropTypes.string,
};

function mapStateToProps(state) {
  const { CommonReducers } = state;

  return {
    applicationParameters: CommonReducers.applicationParameters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setToastMessage: (message, type) => dispatch(setToastMessageAction({ message, type })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstLoginForm);
