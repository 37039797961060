import { css } from "styled-components";

export const AIconStyle = css`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;

  &:before {
    content: "";
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: block;
    margin-right: 0.5rem;
    height: 9px;
    width: 14px;
  }
`;
