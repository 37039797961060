import React, { Children } from "react";
import PropTypes from "prop-types";

import StyledFormLabel from "./StyledFormLabel";

export const FormLabel = props => (
  <StyledFormLabel {...props} className="custom-form_label">
    {Children.toArray(props.children)}
  </StyledFormLabel>
);
FormLabel.propTypes = {
  big: PropTypes.bool,
  small: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default FormLabel;
