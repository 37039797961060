import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Row, Col } from "react-flexbox-grid";

import StyledSection from "@agence-numerique/common/lib/components/Section/StyledSection";
import H3 from "@agence-numerique/common/lib/components/H3";
import Button from "@agence-numerique/common/lib/components/Button";
import { Text } from "@agence-numerique/common/lib/components/Paragraph";
import docCookies from "@agence-numerique/common/lib/utils/Cookie";
import { LoaderContent } from "@agence-numerique/common/lib/components/Loader";
import { COOKIE_PORTAIL_URI } from "@agence-numerique/common/lib/consts/Application.const";

const Cookies = () => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [displayCookie, setDisplayCookie] = useState(false);
  const [domLoaded, setDomLoaded] = useState(false);
  const [nativeCookieBehavior, setNativeCookieBehavior] = useState(false);

  const handleCookieAgreement = () => {
    docCookies.setItem("cookie_agreement", 1);
    setDisplayCookie(false);
  };

  useEffect(() => {
    setLoading(true);
    setDomLoaded(true);
    setNativeCookieBehavior(window.disableNativeCookieBehavior || false);
    setLoading(false);
  }, [nativeCookieBehavior]);
  
  useEffect(() => {
    if (!domLoaded) return;
    if (nativeCookieBehavior) return;
    if (!docCookies.hasItem("cookie_agreement")) {
      setDisplayCookie(true);
    }
    if (!docCookies.hasItem(COOKIE_PORTAIL_URI)) {
      docCookies.setItem(COOKIE_PORTAIL_URI, window.location.origin + window.location.pathname);
    }
  }, [displayCookie, domLoaded, nativeCookieBehavior]);
  
  return (
    <>
      {isLoading ? (
        <LoaderContent theme="secondary" />
      ) : nativeCookieBehavior || !displayCookie
      ? <></> :
      (
        <StyledSection className="section__cookies" size="fullWidth">
          <Grid fluid>
            <Row middle="xs">
              <Col lgOffset={2} lg={8}>
                <H3 color="var(--color-whiteColor)">{t("cookie.title")}</H3>
                <Text padding="0" color="var(--color-whiteColor)">
                  {t("cookie.desc")}
                </Text>
              </Col>
              <Col lg={2}>
                <Button onClick={() => handleCookieAgreement()}> {t("cookie.accept")} </Button>
              </Col>
            </Row>
          </Grid>
        </StyledSection>
      )}
    </>
  );
};

export default Cookies;
