import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import uniqueId from "lodash/uniqueId";
import { useTranslation } from "react-i18next";

import H3 from "../H3";
import { Text } from "../Paragraph";
import Button from "../Button";
import { Modal, openModal, closeModal } from "../Alert/Modal";
import { FormTextarea } from "../Form";

const ButtonWithValidationModal = ({
  theme,
  validationMessage,
  hasComment,
  executeIfValidate,
  inactiv,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [uid, setUid] = useState(false);
  const [comment, setComment] = useState("");

  useEffect(() => {
    setUid(uniqueId());
  }, []);

  const onClickAction = async () => {
    setLoading(true);
    try {
      if (hasComment) {
        await executeIfValidate(comment);
      } else {
        await executeIfValidate();
      }
    } catch (error) {
      setLoading(false);
      closeModal(uid);
    }
  };

  return (
    <>
      <Button
        theme={theme}
        inactiv={inactiv}
        {...props}
        onClick={() => {
          openModal(uid);
        }}
      >
        {children}
      </Button>
      <Modal
        id={uid}
        cancelLabel={t("generic.modal.cancel")}
        confirmLabel={t("generic.modal.confirm")}
        confirmAction={async () => {
          onClickAction();
        }}
        confirmActionLoading={isLoading}
      >
        <H3 secondary>{t("generic.modal.title")}</H3>
        <Text align="center">{validationMessage}</Text>
        {hasComment && <FormTextarea onChange={e => setComment(e.currentTarget.value)} data-cy="modalComment" />}
      </Modal>
    </>
  );
};

ButtonWithValidationModal.propTypes = {
  /** Affiche ce message dans la modale */
  validationMessage: PropTypes.string.isRequired,
  /** Theme du bouton d'ouverture de la modale */
  theme: PropTypes.string,
  /** Le bouton est-il cliquable */
  inactiv: PropTypes.bool,
  /** Permet d'afficher un champs de TextArea à remplir dans la modale */
  hasComment: PropTypes.bool,
  /** Fonction à executer si l'utilisateur clique sur le bouton d'acceptation de la modale */
  executeIfValidate: PropTypes.func.isRequired,
  /** Contenu textuel du bouton */
  children: PropTypes.string.isRequired,
};

ButtonWithValidationModal.defaultProps = {
  validationMessage: "",
  theme: "secondary",
  inactiv: false,
  hasComment: false,
  executeIfValidate: function() {},
  children: "Button",
};

export default ButtonWithValidationModal;
