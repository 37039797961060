import React, { Children } from "react";
import PropTypes from "prop-types";
import StyledH2 from "./StyledH2";

const H2 = props => (
  <StyledH2 {...props} className={`${props.className} custom-h2`}>
    {Children.toArray(props.children)}
  </StyledH2>
);

H2.propTypes = {
  /** Alignement du titre */
  align: PropTypes.oneOf(["center", "left", "right"]),
  /** Contenu à afficher */
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.number]).isRequired,
  /** Classes supplémentaire à utiliser */
  className: PropTypes.oneOf(["h2--success", "h2--error", "h2--icon"]),
  /** Couleur */
  color: PropTypes.string,
  /** Poids de la font à appliquer */
  fweight: PropTypes.oneOf(["regular", "semiBold", "bold"]),
  /** Margin à appliquer */
  margin: PropTypes.string,
};

H2.defaultProps = {
  align: "left",
  children: null,
  className: null,
  color: "var(--color-primaryColor)",
  fweight: "bold",
  margin: "0 0 0.5rem",
};

export default H2;
