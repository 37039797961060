import styled, { css } from "styled-components";
import chevronBtn from "../../assets/images/chevron-btn.png";
import StyledH2 from "../H2/StyledH2";
import { breakpoints } from "global-styles.js";

import ContainerListItemStyle from "./ContainerListItemStyle";

const StyledContainer = styled.div`
  background: var(--color-whiteColor);
  border-radius: 3px;
  box-shadow: var(--global-shadow);
  margin: 0.5rem 0;
  padding: ${props => props.padding || "1rem"};
  height: ${props => props.height || null};

  ${breakpoints.tablet`
    margin: 0 0 1rem;
  `}

  ${props =>
    props.unavailable &&
    css`
      background-color: var(--color-ternaryColor);
    `}

  // TODO Incorporate in props instead of className
  &.container-listHeader {
    background: none;
    box-shadow: none;
    margin-bottom: 0;
    padding-right: 3rem;
    padding-bottom: 0;
  }

  // TODO Incorporate in props instead of className
  &.container-listItem {
    ${ContainerListItemStyle}
  }

  // TODO Incorporate in props instead of className
  &.container-planningListItem {
    ${ContainerListItemStyle};
    border: 1px solid var(--color-secondaryColorLighter);
    cursor: pointer;
    display: flex;
    margin: 0 -0.5rem 0 0;
    padding: 0;

    &:hover,
    &.isActive {
      border: 1px solid var(--color-primaryColor);
    }
  }

  // TODO Incorporate in props instead of className
  &.container--thin-gutter {
    padding: 0.5rem;

    ${StyledH2} {
      margin-bottom: 0;
    }
  }

  ${props =>
    props.expandable &&
    css`
      ${ContainerListItemStyle}

      &:hover {
        cursor: pointer;
      }

      &:after {
        transform: scale(-1) translateY(-50%);
        transform-origin: 50% 0;
        background-image: url(${chevronBtn});
        width: 36px;
        height: 36px;
      }

      &.expandable--isOpen:after {
        transform: scale(1) translateY(-50%);
      }
    `}

  ${props =>
    props.disable &&
    css`
      background-color: transparent;
    `}
`;

export default StyledContainer;
