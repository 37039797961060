export const ConnectNextStepList = data => {
  const sanitized = data.filter(stepItem => stepItem.description && typeof stepItem.description === "string");
  return sanitized.map(stepItem => {
    const descriptionParts = stepItem.description?.split("|");
    return {
      id: stepItem.id,
      code: stepItem.mediationCode,
      display: descriptionParts[0],
      theme: descriptionParts[1] || "primary",
      withModal: descriptionParts[2] === "withModal",
    };
  });
};
