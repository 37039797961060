/* eslint-disable react/prop-types */
import React, { Children } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import StyledFormFieldWrapper from "./StyledFormFieldWrapper";
import StyledFormFieldExpandableWrapper from "./StyledFormFieldExpandableWrapper";
import StyledFormFieldFileWrapper from "./StyledFormFieldFileWrapper";
import StyledFormFieldExpandableFooter from "./StyledFormFieldExpandableFooter";
import StyledFieldSet from "./StyledFieldSet";
import StyledTextArea from "./StyledTextArea";
import StyledFormFieldMultiselectWrapper from "./StyledFormFieldMultiselectWrapper";

import ServerMessage from "./ServerMessage";
import FormFooter from "./FormFooter";
import FormLabel from "./FormLabel";
import FormRadio from "./FormRadio";
import FormSelect from "./FormSelect";
import FormCheckbox from "./FormCheckbox";
import FormWithMentions from "./FormWithMentions";

import Button from "../Button";
import A from "../A";
import { Text } from "../Paragraph";
import cancelIcon from "../../assets/images/cross.svg";

export const FormFieldWrapper = (props) => {
  if (props.type && props.type === "file") {
    return <FormFieldFileWrapper {...props} />;
  }

  return (
    <StyledFormFieldWrapper {...props} className="custom-form_field_wrapper">
      {props.icon && <div className={props.iconClassName}>{props.icon}</div>}
      {Children.toArray(props.children)}
    </StyledFormFieldWrapper>
  );
};

const FormFieldFileWrapper = (props) => (
  <StyledFormFieldFileWrapper
    {...props}
    className={`custom-form_field_wrapper ${props.applicantFile && "form_field_wrapper_applicant"} ${
      props.isLoading && "form_field_wrapper--isLoading"
    }`}
  >
    <span>
      {Children.toArray(props.children)}
      <label>{props.inputFileName}</label>
      <Button
        className={props.divBtnClassName}
        theme={props.applicantFile ? "primary" : "secondary"}
        isLoading={props.isLoading}
      >
        {props.btnLabel}
      </Button>
      {props.cancelAction && !props.isLoading && (
        <A
          href="#"
          onClick={(event) => {
            event.preventDefault();
            props.cancelAction();
          }}
        >
          {" "}
          <img src={cancelIcon} />
        </A>
      )}
    </span>
  </StyledFormFieldFileWrapper>
);

export const FormFieldExpandableWrapper = (props) => (
  <StyledFormFieldExpandableWrapper
    {...props}
    className={`${props.className || ""} custom-form_field_expandable_wrapper`}
  >
    {Children.toArray(props.children)}
  </StyledFormFieldExpandableWrapper>
);

export const FormFieldExpandableFooter = (props) => (
  <StyledFormFieldExpandableFooter {...props} className="custom-form_field_expandable_footer">
    {Children.toArray(props.children)}
  </StyledFormFieldExpandableFooter>
);

export const FormFieldMultiselectWrapper = (props) => {
  const { t } = useTranslation();
  function handleMultiSelectToggle(event) {
    if (event.target.classList.contains("text_placeholder")) {
      event.currentTarget.classList.toggle("isOpen");
    }
  }

  return (
    <StyledFormFieldMultiselectWrapper {...props} onClick={(event) => handleMultiSelectToggle(event)}>
      {props.selectedCount ? (
        <Text className="text_placeholder">
          {t("form.multiSelectWrapper.selected_elements", { count: props.selectedCount })}
        </Text>
      ) : (
        <Text className="text_placeholder">{t("form.multiSelectWrapper.placeholder")}</Text>
      )}
      {Children.toArray(props.children)}
    </StyledFormFieldMultiselectWrapper>
  );
};

export const FormTextarea = (props) => <StyledTextArea {...props} />;

export const Fieldset = (props) => <StyledFieldSet> {Children.toArray(props.children)} </StyledFieldSet>;

FormFieldWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  btnLabel: PropTypes.string,
  divBtnClassName: PropTypes.string,
};

export { ServerMessage, FormWithMentions, FormFooter, FormLabel, FormRadio, FormSelect, FormCheckbox };
