import {
  PARAMETERS_APPLICATION_CODE_TYPE_OF_INVENTORY,
  PARAMETERS_APPLICATION_CODE_DIRECT_DEBIT_MANDATORY,
  PARAMETERS_APPLICATION_CODE_TRACKINGURL,
  PARAMETERS_APPLICATION_CODE_GTM_ID,
  PARAMETERS_APPLICATION_TIMER_PROPOSITION_APPOINTMENT_MADE,
  PARAMETERS_APPLICATION_PASSWORD_KEY,
  PARAMETERS_APPLICATION_PASSWORD_CHECK_CHAR_REQUIRED_CODE,
  PARAMETERS_APPLICATION_PASSWORD_CHECK_CHAR_REQUIRED_KEY,
  PARAMETERS_APPLICATION_PASSWORD_CHECK_UPPERCASE_REQUIRED_CODE,
  PARAMETERS_APPLICATION_PASSWORD_CHECK_UPPERCASE_REQUIRED_KEY,
  PARAMETERS_APPLICATION_PASSWORD_CHECK_LOWERCASE_REQUIRED_CODE,
  PARAMETERS_APPLICATION_PASSWORD_CHECK_LOWERCASE_REQUIRED_KEY,
  PARAMETERS_APPLICATION_PASSWORD_CHECK_NUMBER_REQUIRED_CODE,
  PARAMETERS_APPLICATION_PASSWORD_CHECK_NUMBER_REQUIRED_KEY,
  PARAMETERS_APPLICATION_PASSWORD_CHECK_SPECIAL_CHAR_REQUIRED_CODE,
  PARAMETERS_APPLICATION_PASSWORD_CHECK_SPECIAL_CHAR_REQUIRED_KEY,
  PARAMETERS_APPLICATION_PASSWORD_CHECK_SPECIAL_CHAR_LIST_CODE,
  PARAMETERS_APPLICATION_PASSWORD_CHECK_SPECIAL_CHAR_LIST_KEY,
  PARAMETERS_APPLICATION_VISITS_AFTER_COMMISSION_KEY,
  PARAMETERS_APPLICATION_VISITS_AFTER_COMMISSION,
  PARAMETERS_APPLICATION_CODE_ALLOW_CALENDLY_MODIF,
  PARAMETERS_APPLICATION_HIDE_REFUSED_PROPOSALS_CODE,
  PARAMETERS_APPLICATION_HIDE_REFUSED_PROPOSALS_KEY,
  PARAMETERS_APPLICATION_FAKE_EXTERNAL_CALL,
  PARAMETERS_APPLICATION_CHECK_ADDRESS,
  MESSAGE_ENABLED,
  SSO_ENABLED,
  PARAMETERS_CALL_TO_GED,
  PORTAIL_THEMING,
  PARAMETERS_APPLICATION_CODE_URL_EL,
} from "consts/ParametersApplication.const";

const asIsParameters = [
  PARAMETERS_APPLICATION_CODE_GTM_ID,
  PARAMETERS_APPLICATION_CODE_TRACKINGURL,
  PARAMETERS_APPLICATION_TIMER_PROPOSITION_APPOINTMENT_MADE,
  PARAMETERS_APPLICATION_CODE_ALLOW_CALENDLY_MODIF,
  PARAMETERS_APPLICATION_FAKE_EXTERNAL_CALL,
  PARAMETERS_APPLICATION_CHECK_ADDRESS,
  MESSAGE_ENABLED,
  SSO_ENABLED,
  PARAMETERS_CALL_TO_GED,
  PORTAIL_THEMING,
  PARAMETERS_APPLICATION_CODE_URL_EL,
];

export const ConnectParametersApplicationList = data => {
  if (data) {
    return {
      ...data
        .filter(parameter => asIsParameters.includes(parameter.code))
        .reduce((accumulator, parameter) => {
          accumulator[parameter.code] = parameter.value;
          return accumulator;
        }, {}),
      inventoryType: data.find(inventory => inventory.code === PARAMETERS_APPLICATION_CODE_TYPE_OF_INVENTORY)?.value,
      directDebit: data.find(inventory => inventory.code === PARAMETERS_APPLICATION_CODE_DIRECT_DEBIT_MANDATORY)?.value,
      [PARAMETERS_APPLICATION_CHECK_ADDRESS]:
        data.find(inventory => inventory.code === PARAMETERS_APPLICATION_CHECK_ADDRESS)?.value === "agency" ? "agency" : "company",
      [PARAMETERS_APPLICATION_VISITS_AFTER_COMMISSION_KEY]:
        data.find(inventory => inventory.code === PARAMETERS_APPLICATION_VISITS_AFTER_COMMISSION)?.value === "true",
      [PARAMETERS_APPLICATION_PASSWORD_KEY]: {
        [PARAMETERS_APPLICATION_PASSWORD_CHECK_CHAR_REQUIRED_KEY]:
          data.find(inventory => inventory.code === PARAMETERS_APPLICATION_PASSWORD_CHECK_CHAR_REQUIRED_CODE)?.value ||
          1,
        [PARAMETERS_APPLICATION_PASSWORD_CHECK_UPPERCASE_REQUIRED_KEY]: data.find(
          inventory => inventory.code === PARAMETERS_APPLICATION_PASSWORD_CHECK_UPPERCASE_REQUIRED_CODE,
        )?.value,
        [PARAMETERS_APPLICATION_PASSWORD_CHECK_LOWERCASE_REQUIRED_KEY]: data.find(
          inventory => inventory.code === PARAMETERS_APPLICATION_PASSWORD_CHECK_LOWERCASE_REQUIRED_CODE,
        )?.value,
        [PARAMETERS_APPLICATION_PASSWORD_CHECK_NUMBER_REQUIRED_KEY]: data.find(
          inventory => inventory.code === PARAMETERS_APPLICATION_PASSWORD_CHECK_NUMBER_REQUIRED_CODE,
        )?.value,
        [PARAMETERS_APPLICATION_PASSWORD_CHECK_SPECIAL_CHAR_REQUIRED_KEY]: data.find(
          inventory => inventory.code === PARAMETERS_APPLICATION_PASSWORD_CHECK_SPECIAL_CHAR_REQUIRED_CODE,
        )?.value,
        [PARAMETERS_APPLICATION_PASSWORD_CHECK_SPECIAL_CHAR_LIST_KEY]: data.find(
          inventory => inventory.code === PARAMETERS_APPLICATION_PASSWORD_CHECK_SPECIAL_CHAR_LIST_CODE,
        )?.value,
      },
      [PARAMETERS_APPLICATION_HIDE_REFUSED_PROPOSALS_KEY]: data.find(
        inventory => inventory.code === PARAMETERS_APPLICATION_HIDE_REFUSED_PROPOSALS_CODE || false,
      )?.value,
    };
  }

  return null;
};
