import { css } from "styled-components";

const StyledFormField = css`
  border: 1px solid var(--color-secondaryColorLighter);
  border-radius: 5px;
  height: 38px;
  padding-left: 3px;
  width: 100%;
`;

export default StyledFormField;
