import styled from "styled-components";
import { FormSelectStyle } from "./StyledFormSelect";

const StyledFormFieldMultiselectWrapper = styled.div`
  ${FormSelectStyle};
  overflow: hidden;
  transition: height 0.3s;
  background-position: calc(100% - 0.5rem) 0.85rem;

  .text_placeholder {
    background: var(--color-whiteColor);
    font-size: 14px;
    line-height: 38px;
    margin-right: 1.5rem;
    padding: 0;
    position: sticky;
    top: 0;
  }

  &.isOpen {
    height: 160px;
    overflow-y: scroll;
    background-position: calc(100% - 1.5rem) 0.85rem;
  }

  label {
    margin: 0 0 0 0.25rem;
    padding-bottom: 0.625rem;
  }
`;

export default StyledFormFieldMultiselectWrapper;
