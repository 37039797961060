import i18n from "i18next";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { differenceInCalendarDays } from "date-fns";

const locales = { fr };

// Try to load custom Translation files in public/i18n folder,
// Add to i18n ressources if successful, ignore otherwise
export const loadCustomI18nRessources = languages => {
  const promises = [];
  languages.forEach(language => {
    const promise = fetch("./custom/i18n/" + language + ".json").then(res => {
        if (res.ok && !res.redirected) {
          return res.json();
        }
      })
      .then(data => {
        i18n.addResourceBundle(language, "custom", data);
      });
    promises.push(promise);
  });
  return Promise.all(promises);
};

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
export const anFormatDate = (date, formatStr = "PP", locale = fr) => {
  try {
    if (!date) {
      return null;
    }
    if (date && !(date instanceof Date)) {
      date = new Date(date);
    }
    return format(date, formatStr, {
      locale,
    });
  } catch (error) {
    console.warn(error);
    return date.toString();
  }
};

export function formatDate(date, formatStr, t) {
  if (date) {
    return anFormatDate(date, formatStr, locales[t("generic.date_locale")]);
  } else {
    return t("generic.date_empty");
  }
}

export const formatUntilNowDate = (date, formatStr, text, nowText, locale) => {
  const currentDate = new Date();
  const availibilityDate = new Date(date);

  if (currentDate > availibilityDate) {
    return nowText;
  }

  const displayDate = anFormatDate(availibilityDate, formatStr, locale);

  return " ".concat(text, displayDate);
};

export function formatHumanReadableDayDate(date, formatStr, t) {
  const today = new Date();
  const testedDate = new Date(date);
  const difference = differenceInCalendarDays(testedDate, today);
  if (difference === 0) {
    return t("generic.dates.today");
  }
  if (difference === 1) {
    return t("generic.dates.tomorow");
  }
  if (difference === -1) {
    return t("generic.dates.yesterday");
  }

  return anFormatDate(date, formatStr, getLocale(t));
}

function getLocale(t) {
  return locales[getLocaleString(t)];
}

function getLocaleString(t) {
  return t("generic.dates.locale");
}

export function formatDateForAPI(date) {
  if (!date) {
    return null;
  }
  if (date && !(date instanceof Date)) {
    date = new Date(date);
  }

  return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS");
}
