import React, { Children, forwardRef } from "react";
import PropTypes from "prop-types";
import StyledContainer from "./StyledContainer";

function handleContainerChild(e) {
  e.preventDefault();
  const element = e.currentTarget;
  element.classList.toggle("expandable--isOpen");
  element.nextSibling.classList.toggle("expandable-child--isOpen");
}

const Container = forwardRef(({ expandable, children, expanded, ...props }, ref) => {
  if (expandable) {
    return (
      <StyledContainer
        {...props}
        ref={ref}
        onClick={e => handleContainerChild(e)}
        className={expanded ? "expandable--isOpen" : ""}
        expandable={true}
      >
        {Children.toArray(children)}
      </StyledContainer>
    );
  }

  return (
    <StyledContainer {...props} ref={ref}>
      {Children.toArray(children)}
    </StyledContainer>
  );
});

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  expandable: PropTypes.bool,
  expanded: PropTypes.bool,
};

Container.displayName = "Container";

export default Container;
