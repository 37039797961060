import { css } from "styled-components";

import chevron from "assets/images/chevron.png";

const containerListItem = css`
  margin-bottom: 1rem;
  padding-right: 3rem;
  position: relative;

  & > *:not(a) {
    pointer-events: none;
  }

  &:after {
    content: "";
    display: block;
    background-image: url(${chevron});
    width: 9px;
    height: 18px;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }
`;

export default containerListItem;
