import React, { useState } from "react";
import { Field } from "formik";
import PropTypes from "prop-types";

const VisibleLoginInput = ({ inputName, handleChange, notPastable }) => {
  const [value, setValue] = useState("");

  return (
    <Field
      name={inputName}
      onChange={(e) => {
        setValue(e.target.value);
        if (handleChange) {
          handleChange(e);
        }
      }}
      value={value}
      data-cy={inputName + "-input"}
      onPaste={(e) => {
        if (notPastable) {
          e.preventDefault();
          return false;
        }
      }}
    />
  );
};

VisibleLoginInput.propTypes = {
  inputName: PropTypes.string,
  handleChange: PropTypes.func,
  notPastable: PropTypes.string,
};

export default VisibleLoginInput;
