/* eslint-disable react/prop-types */
import React, { Children } from "react";
import uniqueId from "lodash/uniqueId";
import PropTypes from "prop-types";
import StyledH1 from "./StyledH1";
import { THEME_WHITE_LABEL, THEME_CUSTOM } from "@agence-numerique/common/lib/consts/ParametersApplication.const";


const H1 = ({ icon, className, children, theme, ...props }) => (
  <StyledH1 key={uniqueId()} {...props} className={`${className} custom-h1`}  theme={theme}>
    {icon && icon}
    {Children.toArray(children)}
  </StyledH1>
);

//* ************ Proptypes *************//
H1.propTypes = {
  /** Taille de la police de caractère en médium */
  medium: PropTypes.bool,
  /** Taille de la police de caractère en large */
  large: PropTypes.bool,
  /** Theme */
  theme: PropTypes.oneOf(["secondary", THEME_WHITE_LABEL, THEME_CUSTOM]),
  /** icon */
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
  /** Classes supplémentaire à utiliser */
  className: PropTypes.oneOf(["h1--success", "h1--error"]),
  /** Contenu */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.array]),
};

H1.defaultProps = {
  medium: false,
  large: false,
  theme: null,
  icon: null,
  children: null,
  className: null,
};

export default H1;
