import styled from "styled-components";
import { breakpoints } from "global-styles.js";

const StyledModal = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.35);
  display: none;
  justify-content: center;
  height: 100%;
  left: 0;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  transition: opacity 0.3s;
  width: 100%;
  z-index: 9999;

  &.isVisible {
    display: flex;
  }

  .modal-container {
    background: var(--color-whiteColor);
    max-width: 545px;
    padding: 1rem 2rem;

    ${breakpoints.tablet`
      padding: 1rem 2rem;
    `};

    .modal-control {
      margin-top: 3rem;
      display: flex;
      justify-content: flex-end;

      button {
        min-width: auto;
        padding: 10px 2rem;
      }

      button:first-child {
        margin-right: 1rem;
      }
    }
  }
`;

export default StyledModal;
