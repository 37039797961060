import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./i18n";
import App from "./App";
import "@agence-numerique/common/lib/assets/css/globalColors.css";
import * as serviceWorker from "./serviceWorker";

console.log(`Starting ${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION} | ${process.env.NODE_ENV}`);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
