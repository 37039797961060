import React from "react";
import PropTypes from "prop-types";
import Loader from "./index";
import StyledLoaderContent from "./StyledLoaderContent";

const LoaderContent = ({ theme, size }) => (
  <StyledLoaderContent size={size}>
    <Loader theme={theme} size={size} />
  </StyledLoaderContent>
);

LoaderContent.propTypes = {
  theme: PropTypes.string,
  size: PropTypes.string,
};

LoaderContent.defaultProps = {
  theme: "secondary",
  size: "medium",
};

export default LoaderContent;
