import { css } from "styled-components";
import { fontWeight, breakpoints } from "../../global-styles.js";
import { THEME_WHITE_LABEL, THEME_CUSTOM } from "@agence-numerique/common/lib/consts/ParametersApplication.const";

const H1Style = css`
  font-size: 1.25rem;
  font-weight: ${fontWeight.bold};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  //letter-spacing: normal;
  color: ${props => props.color || "var(--color-primaryColor)"};
  margin: ${props => props.margin || "0 0 22px"};
  text-align: ${props => props.align || "left"};
  position: relative;

  ${props =>
    props.medium &&
    css`
      font-size: 1.45rem;

      ${breakpoints.tablet`
        font-size: 1.75rem;
      `}
    `}

  ${props =>
    props.large &&
    css`
      font-size: 1.55rem;

      ${breakpoints.tablet`
        font-size: 2rem;
      `}
    `}

    /* White Label theme */
    ${props =>
      props.theme &&
    props.theme === THEME_WHITE_LABEL &&
      css`
        color: var(--color-whitelabel-primary);
        text-align: left;
        font-weight: var(--font-weight-whitelabel-H1);
        font-size: var(--font-size-whitelabel-H1);
        margin-bottom: 20px;
      `}

/* Custom theme */
      ${props =>
      props.theme &&
    props.theme === THEME_CUSTOM &&
      css`
        color: var(--color-custom-primary);
        text-align: left;
        font-weight: var(--font-weight-custom-H1);
        font-size: var(--font-size-custom-H1);
        margin-bottom: 20px;
      `}
`;

export default H1Style;
