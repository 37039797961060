import React from "react";
import PropTypes from "prop-types";

import StyledServerMessage from './StyledServerMessage'

const ServerMessage = props => (
  <StyledServerMessage {...props} className="custom-server_message">
    {props.children}
  </StyledServerMessage>
);

ServerMessage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string]),
};

export default ServerMessage;