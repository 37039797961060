import styled, { keyframes } from "styled-components";
import { breakpoints } from "global-styles.js";
import infoWhite from "assets/images/info-white.png";

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const StyledToast = styled.div`
  align-items: center;
  animation: ${fadeOut} 0.5s ${props => `${props.visibility}s` || "5s"} linear forwards;
  background-color: var(--color-whiteColor);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.35);
  border-radius: 6px;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  padding: 1rem 1rem 1rem 3rem;
  position: fixed;
  top: 0;
  transition: opacity 0.3s;
  width: 100%;
  z-index: 9999;

  ${breakpoints.tablet`
    width: 820px;
  `}

  &.toast-success {
    background: var(--color-successColor) url(${infoWhite}) no-repeat 1rem 50%;
    color: var(--color-whiteColor);
  }

  &.toast-error {
    background: var(--color-error) url(${infoWhite}) no-repeat 1rem 50%;
    color: var(--color-whiteColor);
  }

  &.isVisible {
    display: flex;
  }
`;

export default StyledToast;
