/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import StyledSection from "@agence-numerique/common/lib/components/Section/StyledSection";
import { sizes } from "@agence-numerique/common/lib/global-styles.js";
import Toast from "@agence-numerique/common/lib/components/Alert/Toast";
import {
  THEME_WHITE_LABEL,
  THEME_CUSTOM,
  SSO_ACTIVATED,
  SSO_DISABLED,
} from "@agence-numerique/common/lib/consts/ParametersApplication.const";

import LoginForm from "./LoginForm";
import LoginOnBeHalfForm from "./LoginOnBeHalfForm";
import RequireResetMailForm from "./RequireResetMailForm";
import ResetPasswordForm from "./ResetPasswordForm";
import FirstLoginForm from "./FirstLoginForm";
import SSOlogin from "./SSOLogin";
import ProspectLogin from "./ProspectLogin";
import LoginWrapper, {
  LoginWrapperRight,
  LoginWrapperLeft,
} from "@agence-numerique/common/lib/components/LoginWrapper";
import { LineSeparator } from "@agence-numerique/common/lib/components/Separator";
import { useTranslation } from "react-i18next";
import { Text } from "@agence-numerique/common/lib/components/Paragraph";

const Login = ({ dispatch, login, toast, location, parameters, applicationParameters }) => {
  const logoRoot = `${process.env.PUBLIC_URL}/custom/img/logo`;
  let ImgRoot = `${process.env.PUBLIC_URL}/custom/img/visuel.jpg`;
  const { t } = useTranslation();

  return (
    <StyledSection className="section-home">
      {toast.message && (
        <Toast dispatch={dispatch} type={toast.type}>
          {toast.message}
        </Toast>
      )}
      <LoginWrapper portail_theme={applicationParameters["PORTAIL_THEMING"]}>
        <LoginWrapperLeft portail_theme={applicationParameters["PORTAIL_THEMING"]}>
          <img src={`${ImgRoot}`} alt="" />
        </LoginWrapperLeft>
        <LoginWrapperRight portail_theme={applicationParameters["PORTAIL_THEMING"]}>
          {applicationParameters["PORTAIL_THEMING"] === THEME_CUSTOM && (
            <img
              className="logo u-obj-center"
              srcSet={`${logoRoot}/logo-172w.png 172w, ${logoRoot}/logo-344w.png 344w`}
              sizes={`(max-width: ${sizes.tablet}px) 172px, 344px`}
              src={`${logoRoot}/logo-344w.png`}
              alt="logo"
            />
          )}
          <Switch>
            <Route exact path="/">
              {applicationParameters["SSO_ENABLED"] === SSO_ACTIVATED && <ProspectLogin />}
              {applicationParameters["SSO_ENABLED"] === SSO_DISABLED && (
                <LoginForm login={login} theme={applicationParameters["PORTAIL_THEMING"]} />
              )}
            </Route>
            <Route exact path="/sso-login">
              <SSOlogin theme={applicationParameters["PORTAIL_THEMING"]} />
            </Route>
            <Route exact path="/prospect-login">
              <ProspectLogin theme={applicationParameters["PORTAIL_THEMING"]} />
            </Route>
            <Route exact path={["/login-on-be-half", "/admin"]}>
              <LoginOnBeHalfForm theme={applicationParameters["PORTAIL_THEMING"]} />
            </Route>
            <Route path="/password-recover">
              <ResetPasswordForm location={location} theme={applicationParameters["PORTAIL_THEMING"]} />
            </Route>
            <Route path="/password-reset">
              <RequireResetMailForm parameters={parameters} theme={applicationParameters["PORTAIL_THEMING"]} />
            </Route>
            <Route path="/first-login">
              <FirstLoginForm parameters={parameters} theme={applicationParameters["PORTAIL_THEMING"]} />
            </Route>
            <Route path="/gestionnaire-login">
              <LoginForm login={login} theme={applicationParameters["PORTAIL_THEMING"]} />
            </Route>
          </Switch>
          <LineSeparator></LineSeparator>

          {applicationParameters["PORTAIL_THEMING"] === THEME_WHITE_LABEL && (
            <img
              className="logo u-obj-center"
              srcSet={`${logoRoot}/logo-footer.png 172w, ${logoRoot}/logo-footer.png 344w`}
              sizes={`(max-width: ${sizes.tablet}px) 172px, 344px`}
              src={`${logoRoot}/logo-footer.png`}
              alt="logo"
            />
          )}

          <Text theme={applicationParameters["PORTAIL_THEMING"]} className="text__theme--portail--footer">
            {t("generic.copy_right")}
          </Text>
        </LoginWrapperRight>
      </LoginWrapper>
    </StyledSection>
  );
};

function mapStateToProps(state) {
  const { CommonReducers } = state;

  return {
    applicationParameters: CommonReducers.applicationParameters,
    parameters: CommonReducers.parameters,
    toast: CommonReducers.toast,
  };
}

export default connect(mapStateToProps)(Login);
