import React from "react";
import PropTypes from "prop-types";

import { Grid, Row, Col } from "react-flexbox-grid";

import Container, { ContainerExpandableContent } from "@agence-numerique/common/lib/components/Container";
import H2 from "@agence-numerique/common/lib/components/H2";
import {Text} from "@agence-numerique/common/lib/components/Paragraph";

const ShowContent = ({title, txt}) => {
  return (
    <>
      {title &&
        <Container expandable={true} className="container--thin-gutter">
          <H2 align="center" className="h2--icon">
            {title}
          </H2>
        </Container>
      }
      {txt && 
        <ContainerExpandableContent margin="-1rem 0 1rem">
          <Container>
            <Text dangerouslySetInnerHTML={{__html: txt}}></Text>
          </Container>
        </ContainerExpandableContent>
      }
    </>
  )
};
ShowContent.propTypes = {
  title: PropTypes.string,
  txt: PropTypes.string
};

const PageContent = ({ content }) => {
  return (
    <Grid>
      <Row center="xs">
        <Col xs={12} md={10}>
          <div style={{marginTop:'10px'}}>
            {content.intro && 
              <Text dangerouslySetInnerHTML={{__html: content.intro}}></Text>
            }
            {Array.isArray(content.items) && content.items.map(({title, txt}, i) => (
              <ShowContent key={i} title={title} txt={txt} />
            ))}
            {!Array.isArray(content.items) && <ShowContent title={content.title} txt={content.txt} />}
          </div>
        </Col>
      </Row>
    </Grid>
  );
};

PageContent.propTypes = {
  content: PropTypes.object,
};

export default PageContent;
