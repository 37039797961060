import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import styled from "styled-components";
import H1 from "@agence-numerique/common/lib/components/H1";
import { FormWithMentions, FormFieldWrapper, FormLabel } from "@agence-numerique/common/lib/components/Form";
import Button from "@agence-numerique/common/lib/components/Button";
import { breakpoints } from "@agence-numerique/common/lib/global-styles.js";
import { setToastMessageAction } from "@agence-numerique/common/lib/state/CommonActions";
import { ServerMessage, FormFooter } from "@agence-numerique/common/lib/components/Form";
import { Link } from "@agence-numerique/common/lib/components/Link";
import chevronIcon from "@agence-numerique/common/lib/assets/images/chevron.png";
import { Text } from "@agence-numerique/common/lib/components/Paragraph";

import middleware from "services/middleware";
import routes from "routes";

const CustomForm = styled(Form)`
  ${breakpoints.tablet`
       padding: 0 0px;
  `};
`;

const RequireResetMailForm = ({ setToastMessage, parameters, theme }) => {
  const { t } = useTranslation();
  let history = useHistory();

  const [resetResponseMessage, setResetResponseMessage] = useState("");
  const [formIsProcessing, setFormIsProcessing] = useState(false);

  return (
    <>
      <H1 theme={theme}>{t("passwordReset.title")}</H1>
      <Text theme={theme} className="text__theme--portail--regular">
        {t("passwordReset.intro")}
      </Text>
      <FormWithMentions
        context="portail"
        formId="ResetMailForm"
        initialValues={{ email: "", password: "" }}
        validationSchema={Yup.object().shape({
          email: Yup.string().required(t("generic.required")),
        })}
        onSubmit={async function requestResetMail(values) {
          setFormIsProcessing(true);
          setResetResponseMessage("");
          try {
            let resetReturn = await middleware.requestResetMail(values.email);
            if (resetReturn?.status === 201) {
              setFormIsProcessing(false);
              await setToastMessage(t("passwordReset.init_email_sent"), "success");
              history.push("/");
            } else {
              setFormIsProcessing(false);

              let err = [];
              [
                resetReturn?.data?.err_001,
                resetReturn?.data?.err_002,
                resetReturn?.data?.err_003,
                resetReturn?.data?.err_004,
              ].forEach(function callback(value, index) {
                let error_index = index + 1;
                if (value) {
                  err.push(t("passwordReset.err_00" + error_index));
                }
              });
              if (resetReturn?.data?.err_009) {
                err.push(t("passwordReset.err_009"));
              }

              setResetResponseMessage(err);
              if (resetReturn?.data?.err_009) {
                err.push(t("passwordReset.err_009"));
              }
            }
          } catch {
            setFormIsProcessing(false);
            setResetResponseMessage(t("generic.error_occured"));
          }
        }}
      >
        <CustomForm data-cy="form">
          <FormFieldWrapper>
            <FormLabel small htmlFor="email">
              {t("generic.username")}
            </FormLabel>
            <Field type="text" name="email" data-cy="email-input" />
            <ErrorMessage className="form-error-input" name="email" component="div" />
          </FormFieldWrapper>
          {resetResponseMessage.length > 0 && <ServerMessage>{resetResponseMessage}</ServerMessage>}
          <Button
            theme={theme}
            type="submit"
            isLoading={formIsProcessing}
            disabled={formIsProcessing}
            margin="1rem auto"
          >
            {t("passwordReset.retrieve")}
          </Button>
          <FormFooter>
            <Link to={routes.home} className="link--withIcon">
              {" "}
              <img src={chevronIcon} alt="" /> {t("passwordReset.to_login")}
            </Link>
          </FormFooter>
        </CustomForm>
      </FormWithMentions>
    </>
  );
};

RequireResetMailForm.propTypes = {
  setToastMessage: PropTypes.func,
  parameters: PropTypes.object,
  theme: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
  return {
    setToastMessage: (message, type) => dispatch(setToastMessageAction({ message, type })),
  };
}

export default connect(null, mapDispatchToProps)(RequireResetMailForm);
