import styled from "styled-components";
import { fontWeight } from "../../global-styles.js";

const StyledFieldSet = styled.fieldset`
  border: 0;
  margin-top: 1rem;

  legend {
    color: var(--color-neutralDark);
    font-size: 1rem;
    font-weight: ${fontWeight.semiBold};
    margin-bottom: 1rem;
  }
`;

export default StyledFieldSet;
