import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Field, ErrorMessage } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import H1 from "@agence-numerique/common/lib/components/H1";
import { FormFieldWrapper, FormLabel, FormWithMentions } from "@agence-numerique/common/lib/components/Form";
import StyledFormCheckbox from "@agence-numerique/common/lib/components/Form/StyledFormCheckbox";
import Button from "@agence-numerique/common/lib/components/Button";
import { breakpoints } from "@agence-numerique/common/lib/global-styles.js";
import { ServerMessage, FormFooter } from "@agence-numerique/common/lib/components/Form";
import { PortailLink } from "@agence-numerique/common/lib/components/Link";
import VisiblePasswordInput from "containers/Auth/VisiblePasswordInput";
import middleware from "services/middleware";
import { userRedirection } from "utils/helpers";
import routes from "../../routes";

const CustomForm = styled(Form)`
  ${breakpoints.tablet`
      padding: 0 0px;
  `};
`;

const LoginForm = ({theme}) => {
  const { t } = useTranslation();
  const [loginResponseMessage, setLoginResponseMessage] = useState("");
  const [password, setPassword] = useState(); // TODO: see why we have to set it manuallty
  const onPasswordChange = (password) => {
    setPassword(password);
  };
  return (
    <>
      <H1 theme={theme}>{t("login.title")}</H1>
      <FormWithMentions
        context="portail"
        formId="LoginForm"
        enableReinitialize
        initialValues={{ email: "", password: "" }}
        validationSchema={Yup.object().shape({
          email: Yup.string().required(t("generic.required")),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const loginResponse = await middleware.login(values.email, password, values.remember);
            if (loginResponse?.token && loginResponse?.refresh_token) {
              await userRedirection();
            } else {
              setLoginResponseMessage(t("login.server_error"));
              setSubmitting(false);
            }
          } catch (error) {
            switch (error?.status) {
              case 401:
                if (error?.data?.code === "err_005") {
                  setLoginResponseMessage(t("login.err_005"));
                } else {
                  setLoginResponseMessage(t("login.bad_password"));
                }
                break;
              case 403:
                setLoginResponseMessage(t("login.forbidden"));
                break;
              case 500:
                setLoginResponseMessage(t("login.server_error"));
                break;
              default:
                setLoginResponseMessage(t("generic.error_occured"));
                break;
            }
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => (
          <CustomForm data-cy="form">
            <FormFieldWrapper>
              <FormLabel small htmlFor="email">
                {t("generic.username")}
              </FormLabel>
              <Field type="text" name="email" data-cy="email-input" />
              <ErrorMessage className="form-error-input" name="email" component="div" />
            </FormFieldWrapper>
            <FormFieldWrapper>
              <FormLabel small htmlFor="password">
                {t("login.password")}
              </FormLabel>
              <VisiblePasswordInput inputName="password" handleChange={(e) => onPasswordChange(e.target.value)} />
            </FormFieldWrapper>
            <StyledFormCheckbox>
              <Field type="checkbox" className="form__checkbox" name="remember" value="1" /> {t("login.rememberMe")}
            </StyledFormCheckbox>
            {loginResponseMessage.length > 0 && (
              <ServerMessage data-cy="loginErrorMessage">{loginResponseMessage}</ServerMessage>
            )}

            <FormFooter>
              <Button theme={theme} margin="1rem auto" isLoading={isSubmitting} type="submit" data-cy="loginAuthButton">
                {t("login.connect")}
              </Button>
              <PortailLink linkTo={routes.passwordReset} theme={theme}>
                {t("login.forgot_password")}
              </PortailLink>
            </FormFooter>
          </CustomForm>
        )}
      </FormWithMentions>
    </>
  );
};

LoginForm.propTypes = {
  setUrlRedirect: PropTypes.func,
  theme: PropTypes.string,
};

export default LoginForm;
