import styled, { css } from "styled-components";
import { breakpoints, fontWeight } from "../../global-styles.js";

const StyledRent = styled.p`
  color: var(--color-primaryColor);
  font-size: 12px;
  text-align: ${props => props.align || "left"};

  ${breakpoints.tablet`
        font-size: 14px;
        text-align: ${props => props.tabletAlign || "left"};
    `}

  .rent-value {
    font-size: 16px;
    font-weight: ${fontWeight.bold};

    ${breakpoints.tablet`
            font-size: 18px;
        `}
  }

  .rent-availability {
    color: var(--color-neutral);
  }

  ${props =>
    props.size &&
    props.size === "big" &&
    css`
      font-size: 16px;

      .rent-value {
        font-size: 22px;
      }
    `};
`;

export default StyledRent;
