import React from "react";
import PropTypes from "prop-types";
import StyledRent from "./StyledRent";

const Rent = ({ children, ...props }) => (
  <StyledRent {...props} className="custom-rent">
    {children}
  </StyledRent>
);

Rent.propTypes = {
  /** Contenu du composant */
  children: PropTypes.array,
};

export default Rent;
