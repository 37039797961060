import React, { useEffect, Children } from "react";
import PropTypes from "prop-types";
import StyledToast from "./StyledToast";
import { cleanToastAction } from "../../state/CommonActions";

// Toast visibility in second
const ToastTimer = 5;

const Toast = ({ dispatch, type, children, ...props }) => {
  useEffect(() => {
    setTimeout(() => dispatch(cleanToastAction()), ToastTimer * 1000);
  }, [dispatch]);

  return (
    <StyledToast id="toast-wrapper" className={type && `toast-${type}`} visibility={ToastTimer} {...props}>
      {Children.toArray(children)}
    </StyledToast>
  );
};

Toast.propTypes = {
  /** Fonction utilisée pour lancer des actions dans le store (cleaner le toast) */
  dispatch: PropTypes.func.isRequired,
  /** Type valid ou error du Toast */
  type: PropTypes.string.isRequired,
  /** Contenu du Toast */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};
export default Toast;
