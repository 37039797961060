import React, { Children } from "react";
import PropTypes from "prop-types";
import StyledA from "./StyledA";

function handleContainerChild(e) {
  e.preventDefault();
  const element = e.target;
  element.classList.toggle("expandable--isOpen");
  element.nextSibling.classList.toggle("expandable-child--isOpen");
}

const A = props => (
  <StyledA
    {...props}
    className={`custom-link ${props.className || ""}`}
    onClick={e => (props.expandable ? handleContainerChild(e) : props.onClick ? props.onClick(e) : null)}
  >
    {Children.toArray(props.children)}
  </StyledA>
);

A.propTypes = {
  /** Donne un theme au lien avec un rendu de style buttonZ */
  skin: PropTypes.oneOf(["button", "button-secondary", "button-ternary", "link"]),
  /** Permet de cacher/afficher le next sibling au click sur cet élément */
  expandable: PropTypes.bool,
  /** Rajoute les className */
  className: PropTypes.string,
  /** Fonction à lancer au click sur cet élément, écrasé si expandable est à true */
  onClick: PropTypes.func,
  /** Contenu du A */
  children: PropTypes.any,
  /** Contenu du A */
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

A.defaultProps = {
  expandable: false,
  children: null,
  className: null,
  onClick: null,
  icon: null,
};

export default A;
