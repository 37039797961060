import React from "react";
import StyledLoginWrapperLeft from "./StyledLoginWrapperLeft";
import PropTypes from "prop-types";
import {
  //THEME_WHITE_LABEL,
  THEME_CUSTOM,
} from "@agence-numerique/common/lib/consts/ParametersApplication.const";

const LoginWrapperLeft = ({ portail_theme, ...props }) => {
  return (
    <>
      {portail_theme === THEME_CUSTOM && <StyledLoginWrapperLeft>{props.children}</StyledLoginWrapperLeft>}
    </>
  );
};

LoginWrapperLeft.propTypes = {
  portail_theme: PropTypes.string,
  children: PropTypes.any,
};

LoginWrapperLeft.defaultProps = {
  portail_theme: "0",
};

export default LoginWrapperLeft;
