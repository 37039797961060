import styled from "styled-components";
import { fontWeight, breakpoints } from "global-styles.js";

const StyledFormCheckbox = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem 0;
  color: var(--color-neutralDark);
  font-size: 14px;
  font-weight: ${props => fontWeight[props.fweight] || fontWeight.regular};

  a {
    cursor: pointer;
    color: blue;
  }

  input[type="checkbox"] {
    margin: 0 0.5rem 0 0;
  }

  &.checkbox-cgu {
    font-size: 14px;
    font-weight: ${fontWeight.regular};
    margin-top: 1rem;
    input {
      width: 2rem;
      height: 2rem;
      ${breakpoints.tablet`
        width: inherit;
        height: inherit;
      `}
    }
  }
`;

export default StyledFormCheckbox;
