const routes = {
  home: "/",
  loginOnBeHalf: "/login-on-be-half",
  admin: "/admin",
  passwordRecover: "/password-recover",
  passwordReset: "/password-reset",
  firstConnexion: "/first-connexion",
  ssoLogin: "/sso-login",
  prospectLogin: "/prospect-login",
  gestionnaireLogin: "/gestionnaire-login",
};

export default routes;
