import styled from "styled-components";
import StyledButton from "../Button/StyledButton";

const StyledFormFieldExpandableWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }

  height: 65px;
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  padding: 1.25rem 1rem;
  background-color: var(--color-whiteColor);
  border: 1px solid var(--color-secondaryColorLighter);
  border-bottom: 0;

  &:first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &:last-of-type {
    border-bottom: 1px solid var(--color-secondaryColorLighter);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &.isOpened {
    height: auto;
  }

    ${StyledButton} + ${StyledButton} {
      margin-left: 0.5rem;
    }
`;

export default StyledFormFieldExpandableWrapper;
