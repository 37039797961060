import React from "react";
import PropTypes from "prop-types";

import StyledFormFooter from "./StyledFormFooter";

const FormFooter = ({ children, ...props }) => (
  <StyledFormFooter {...props} className="custom-form_footer">
    {children}
  </StyledFormFooter>
);

FormFooter.propTypes = {
  /** Contenu du footer */
  children: PropTypes.any,
};

FormFooter.defaultProps = {
  children: null,
};

export default FormFooter;
