import React, { Children } from "react";
import PropTypes from "prop-types";
import StyledContainerChild from "./StyledContainerChild";

export const ContainerExpandableContent = ({ expanded, overflowVisible, children, ...props }) => {
  return (
    <StyledContainerChild
      className={
        (expanded ? "expandable-child--isOpen" : "") + (overflowVisible ? " expandable-child--overflowVisible" : "")
      }
      {...props}
    >
      {Children.toArray(children)}
    </StyledContainerChild>
  );
};

ContainerExpandableContent.propTypes = {
  /** Est ce que la zone est ouvrable */
  expanded: PropTypes.bool,
  /** Est ce que le contenu peut dépasser la zone */
  overflowVisible: PropTypes.bool,
  /** Contenu de la partie ouverte */
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default ContainerExpandableContent;
