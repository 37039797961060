import React from "react";
// import { Redirect } from "react-router";
import middleware from "services/middleware";
import { useLocation } from "react-router-dom";
import { isLoggedIn } from "@agence-numerique/common/lib/utils/Helpers";
import { userRedirection } from "utils/helpers";
import { Text } from "@agence-numerique/common/lib/components/Paragraph";
import { useTranslation } from "react-i18next";
import {ServerMessage} from "@agence-numerique/common/lib/components/Form";
import A from "@agence-numerique/common/lib/components/A";
import { TextDecorator } from "@agence-numerique/common/lib/components/Paragraph";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { PARAMETERS_APPLICATION_CODE_URL_EL } from "@agence-numerique/common/lib/consts/ParametersApplication.const";

const SSOLogin = (applicationParameters) => {
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const error = new URLSearchParams(search).get("err_007");
  const { t } = useTranslation();

  const login = async () => {
    if (!isLoggedIn() && !error && token) {
      await middleware.getSSOTokenAndLogin(token);
      await userRedirection();
  }};

  login();

  return (
    <>
      {error?.length > 3 && <ServerMessage data-cy="loginErrorMessage">
        {t("SSOLogin.err_007")}
            <A href={applicationParameters.applicationParameters[PARAMETERS_APPLICATION_CODE_URL_EL]}>
              <TextDecorator color="var(--color-error)" fweight="semiBold">{t("SSOLogin.err_007_link")}</TextDecorator>
            </A>
        </ServerMessage>
      }

      {error?.length < 3 && <Text align="center">{t("generic.connecting")}</Text>}
    </>
  );
};

SSOLogin.propTypes = {
  applicationParameters: PropTypes.object,
};

function mapStateToProps(state) {
  const { CommonReducers } = state;

  return {
    applicationParameters: CommonReducers.applicationParameters,
  };
}


export default connect(mapStateToProps)(SSOLogin);