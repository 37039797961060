import React, { Children } from "react";
import PropTypes from "prop-types";
import Loader from "components/Loader";

export const ContainerLoader = ({ theme, isLoading, children }) => {
  return isLoading ? <Loader theme={theme || "secondary"} size="medium" /> : Children.toArray(children);
};

ContainerLoader.propTypes = {
  /** Si le container est en loading, affiche le loader, sinon affiche les children*/
  isLoading: PropTypes.bool,
  /** Affiche un theme en particulier pour le loader */
  theme: PropTypes.oneOf(["primary", "secondary", "ternary"]),
  /** Contenu à afficher si le loader est pas en état isLoading*/
  children: PropTypes.any,
};

export default ContainerLoader;
