import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationFR from "./i18n/fr.json";
import commonTranslationFR from "@agence-numerique/common/lib/i18n/fr.json";

export const AVAILABLE_LANGUAGES = ["fr"];
// the translations
const resources = {
  fr: {
    translation: translationFR,
    common: commonTranslationFR
  },
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    languages: ["fr"],
    fallbackLng: "fr",
    namespaces: ["custom", "translation", "common"],
    defaultNS: "custom",
    fallbackNS: ["translation", "common"],
    nsSeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"], // don't convert to <1></1> if simple react elements
    },
  });

export default i18n;
