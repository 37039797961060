import React, { Children } from "react";
import PropTypes from "prop-types";

import StyledTextDecorator from "./StyledTextDecorator";

const TextDecorator = ({ children, ...props }) => (
  <StyledTextDecorator {...props} className={`custom-text-decorator`}>
    {Children.toArray(children)}
  </StyledTextDecorator>
);

TextDecorator.propTypes = {
  /** Couleur du texte */
  color: PropTypes.string,
  /** Taille de la fonte */
  fsize: PropTypes.string,
  /** Poids de la fonte à appliquer */
  fweight: PropTypes.oneOf(["regular", "semiBold", "bold"]),
  /** Padding */
  padding: PropTypes.string,
  /** Cpntenu du text décorator */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]),
};

TextDecorator.defaultProps = {
  color: null,
  fsize: null,
  fweight: null,
  padding: null,
  children: null,
};

export default TextDecorator;
