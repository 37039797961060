import {
  GET_PARAMETERS,
  SAVE_APPLICATION_PARAMETERS,
  SET_USER,
  ACTION_LIST_ALLOWED,
  SET_LOADING_USER,
  SET_TOAST_MESSAGE,
  CLEAN_TOAST,
  UPDATE_INSURANCE_INFO,
  UPDATE_PAYMENT_STATUS,
  UPDATE_PAYMENT_STATUS_TO_VALID,
  UPDATE_KEYHANDOVER_TYPE,
  UPDATE_DEBITMANDATE,
} from "./CommonTypes";

const initialState = {
  parameters: {},
  applicationParameters: {},
  actionListAllowed: {},
  user: {},
  toast: {
    message: "",
    type: "",
  },
  payment: {
    isDone: false,
    isValid: false,
  },
  keyHandover: {
    type: null,
    adresse: null,
    trackingNumber: null,
    keyboxCode: null,
    appointmentHour: null,
  },
  debitMandate: {
    signingDate: null,
    periodeDay: null,
  }
};

export default function CommonReducers(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PARAMETERS:
      return Object.assign({}, state, {
        parameters: payload,
      });
    case SAVE_APPLICATION_PARAMETERS:
      return Object.assign({}, state, {
        applicationParameters: payload,
      });
    case ACTION_LIST_ALLOWED:
      return Object.assign({}, state, {
        actionListAllowed: payload,
      });
    case SET_USER:
      return Object.assign({}, state, {
        user: payload,
      });
    case SET_LOADING_USER:
      return Object.assign({}, state, {
        user: {
          ...state.user,
          isLoading: payload,
        },
      });
    case SET_TOAST_MESSAGE:
      return Object.assign({}, state, {
        toast: payload,
      });
    case CLEAN_TOAST:
      return Object.assign({}, state, {
        toast: initialState.toast,
      });
    case UPDATE_INSURANCE_INFO:
      return Object.assign({}, state, {
        user: {
          ...state.user,
          housingRequest: {
            ...state.user.housingRequest,
            insurance: {
              ...state.user.housingRequest.insurance,
              contractNumber: payload.insurance.contractNumber,
              insurer: {
                id: payload.insurance.insurer.id,
              },
              beginValidityDate: payload.insurance.beginValidityDate.toString(),
              endValidityDate: payload.insurance.endValidityDate.toString(),
            },
            insuranceDocument: {
              ...state.user.housingRequest.insuranceDocument,
              document: {
                ...state.user.housingRequest.insuranceDocument?.document,
                name: payload.document.name,
              },
              status: {
                ...state.user.housingRequest.insuranceDocument?.status,
                updatedAt: payload.updatedAt,
                code: payload.status,
              },
            },
          },
        },
      });
    case UPDATE_PAYMENT_STATUS:
      return Object.assign({}, state, {
        payment: { isDone: payload },
      });
    case UPDATE_PAYMENT_STATUS_TO_VALID:
      return Object.assign({}, state, {
        payment: { isValid: payload },
      });
    case UPDATE_KEYHANDOVER_TYPE:
      return Object.assign({}, state, {
        keyHandover: {
          type: payload?.type,
          address: payload?.address,
          trackingNumber: payload?.trackingNumber,
          keyboxCode: payload?.keyboxCode,
          appointmentDate: payload?.appointmentDate,
          realEstateAddress: payload?.realEstateAddress,
          realEstateAddressComplement: payload?.realEstateAddressComplement,
          realEstateCity: payload?.realEstateCity,
          realEstateCp: payload?.realEstateCp,
        },
      });
    case UPDATE_DEBITMANDATE:
      return Object.assign({}, state, {
        debitMandate: { ...payload },
      });
    default:
      return state;
  }
}
