import { createGlobalStyle, css } from "styled-components";

export const settings = {
  SectionMediumMaxWidth: "820px",
  SectionLargeMaxWidth: "940px",
  SectionXLargeMaxWidth: "1248px",
  footerHeight: "45px",
  headerMarginBottom: "0px",
};

// MediaQueries
export const sizes = {
  phone: 320,
  tablet: 768,
  desktop: 1024,
  wideDesktop: 1200,
  xWideDesktop: 1450,
};

export const fontWeight = {
  regular: "400",
  semiBold: "600",
  bold: "700",
  veryBold: "800",
};

export const breakpoints = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});

export const breakpointsMax = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${(sizes[label] - 1) / 16}em) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});

export const GlobalStyle = createGlobalStyle`
  * { box-sizing: border-box; margin: 0; padding: 0; outline: 0;}

  body {
    counter-reset: section;
    background-color: var(--color-ternaryColor);
    font-family: 'Open Sans', sans-serif;
    ${props =>
      props?.theme?.backgroundImg
        ? `background: url(${props.theme.backgroundImg}) no-repeat; background-size: cover;`
        : null}

    ${props =>
      props?.theme?.custom &&
        css`
          background-color: var(--color-custom-primary);
        `
      }

    ${breakpoints.tablet`
      background-color: ${props => props?.theme?.bodyColor};
    `}
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  main {
    flex-grow: 1;

    ${breakpointsMax.tablet`
          padding: 0 0.5rem;
    `}
  }

  h1 {
      margin-bottom: 1.5rem;
  }

  .open-sans *{
    font-family: 'Open Sans', sans-serif;
  }

  p,
  label {
    line-height: 1.5em;
  }

  p {
    font-size: 14px;
    color: var(--color-neutral);
  }

  a {
    color: var(--color-neutral);
    text-decoration: none;
  }

  a svg {
    position: relative;
  }

  small {
    font-size: 14px;
  }


  .wrapper--bordered {
    background-color: var(--color-whiteColor);
    border: 1px solid var(--color-secondaryColorLighter);
    border-radius: 3px;
  }

  .wrapper--guttered {
    padding: 1rem;
  }

  .svg-color-primaryColor {
    rect, path {
      fill: var(--color-primaryColor);
    }
  }

  .svg-color-secondary {
    rect, path {
      fill: var(--color-secondary);
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .form__calendly {
    min-width: 250px;
  }

  .form-error-input {
    color: var(--color-error);
    font-size: 0.85rem;
  }

  .link--withIcon {
   cursor: pointer;
    img {
      transform: rotate(180deg);
      vertical-align: middle;
      margin-right: 0.5rem;
    }
  }

  .link--withIcon-right {
   cursor: pointer;
    img {
      vertical-align: middle;
      margin-right: 0.5rem;
    }
  }

  .link--flip-horizontal {
    transform: scale(-1, 1);
  }

  /*********************************
  * UTILS *
  *********************************/
  .u-flex {
    display: flex;
  }

  .u-flex-1 {
    flex: 1;
  }

  .u-flex-2-per-row {
    ${breakpoints.tablet`
      flex: 1 0 50% !important;
    `}
  }

  .u-flex-3-per-row {
    flex: 1 0 100% !important;
    ${breakpoints.tablet`
      flex: 1 0 28% !important;
    `}
  }

  .u-flex-4-per-row {
    ${breakpoints.tablet`
      flex: 1 0 21% !important;
    `}
  }

  .u-flex--nowrap {
    ${breakpoints.tablet`
      flex-wrap: nowrap;
    `}
  }

  .u-flex-column {
    flex-direction: column;
  }

  .u-flex-row-reverse {
    flex-direction: row-reverse;
  }

  .u-txt-center {
    text-align: center;
  }

  .u-obj-center {
    display: block;
    margin: auto;
  }

  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .show-for-mobile-only {
    ${breakpoints.tablet`
      display: none!important;
    `};
  }

  .show-for-tablet {
    display: none!important;

    ${breakpoints.tablet`
      display: block!important;
    `};
  }

  .show-inline-for-tablet {
    display: none!important;

    ${breakpoints.tablet`
      display: inline!important;
    `};
  }

  .show-flex-for-tablet {
    display: none!important;

    ${breakpoints.tablet`
      display: flex!important;
    `};
  }

  .hidden {
    display: none!important;
  }

  .show-block {
    display: block!important;
  }

  .show-flex {
    display: flex!important;
  }

  .center-for-phone {
    ${breakpoints.phone`
      text-align: center!important;
    `};
  }

  .align-left-for-tablet {
    ${breakpoints.tablet`
      text-align: left!important;
    `};
  }
`;
