import { css } from "styled-components";
import { breakpoints } from "../../global-styles.js";

export const ButtonStyle = css`
  font-size: ${props => (props.fsize || "14px")};
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
  border-radius: 2rem;
  display: block;
  margin: ${props => props.margin || "1rem auto"};
  padding: 10px 2rem;
  text-align: center;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${breakpoints.tablet`
    margin: ${props => props.margin || "0.25rem auto"};
  `};

  &,
  & > a {
    color: var(--color-whiteColor);
  }

  &:hover {
    cursor: pointer;
    background-color: var(--color-whiteColor);
    color: var(--color-secondary);
    svg {
      &,
      rect,
      path {
        fill: var(--color-secondary);
      }
    }
  }
`;

export const ButtonSecondaryStyle = css`
  ${ButtonStyle};
  background-color: var(--color-whiteColor);
  border: 1px solid var(--color-secondary);
  &,
  & > a {
    color: var(--color-secondary);
  }
  &:hover {
    background-color: var(--color-secondary);
    color: var(--color-whiteColor);

    svg path {
      fill: var(--color-whiteColor);
    }
  }
`;

export const ButtonTernaryStyle = css`
  ${ButtonStyle};
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
  &,
  & > a {
    color: var(--color-whiteColor);
  }
  &:hover {
    background-color: var(--color-whiteColor);
    color: var(--color-secondary);

    svg path {
      fill: var(--color-secondary);
    }
  }
`;

export const ButtonWhiteLabel = css`
  ${ButtonStyle};
   text-transform: uppercase;
  width: auto;
  padding:10px 60px;
  text-align: left;
  margin: 1rem 0px !important;
  display: inline-block;
  background-color: var(--color-whitelabel-button);
  border: 1px solid var(--color-whitelabel-button);
  &,
  & > a {
    color: var(--color-whiteColor);
  }
  &:hover {
    background-color: var(--color-whitelabel-button-hover);
    color: var(--color-whiteColor);
    svg path {
      fill: var(--color-whiteColor);
    }
  }
`;

export const ButtonCustom = css`
  ${ButtonStyle};
  text-transform: uppercase;
  width: auto;
  padding:10px 60px;
  text-align: left;
  margin: 1rem 0px !important;
  display: inline-block;
  background-color: var(--color-custom-button);
  border: 1px solid var(--color-custom-button);
  &,
  & > a {
    color: var(--color-whiteColor);
  }
  &:hover {
    background-color: var(--color-custom-button-hover);
    color: var(--color-whiteColor);
    svg path {
      fill: var(--color-whiteColor);
    }
  }
`;
