/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import SectionStyle from "./SectionStyle";

const StyledSection = props => (
  <SectionStyle {...props} size={props.size} className={`${props.className} custom-section`}>
    {props.children}
  </SectionStyle>
);

StyledSection.propTypes = {
  backgroundImg: PropTypes.string
};

StyledSection.defaultProps = {
  className: "",
  size: undefined
};

export default StyledSection;
