import styled, { css } from "styled-components";
import { fontWeight, breakpoints } from "../../global-styles.js";

const StyledFormFieldWrapper = styled.label`
  color: var(--color-neutralDark);
  display: block;
  font-size: 14px;
  font-weight: ${fontWeight.semiBold};
  margin-bottom: 9px;

  ${(props) =>
    props.big &&
    css`
      font-size: 1rem;
      font-weight: ${fontWeight.bold};

      ${breakpoints.tablet`
        font-size: 1.125rem;
      `}
    `}

  ${(props) =>
    props.small &&
    css`
      font-size: 12px;
      font-weight: ${fontWeight.regular};
      margin-bottom: 0px;
      color: var(--color-login-label);
      ${breakpoints.tablet`
        font-size:12px;
      `};
    `}
`;

export default StyledFormFieldWrapper;
