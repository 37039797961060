import React, { Children } from "react";
import PropTypes from "prop-types";
import StyledFormRadio from "./StyledFormRadio";

const FormRadio = props => {
  function handleFormRadioClick(e) {
    const elementName = e.currentTarget.getAttribute("name");
    const elementChecked = document.querySelector(elementName ? `[name='${elementName}'].isChecked` : ".isChecked");

    if (props.expandable) {
      const elementOpened = document.querySelector(".isOpened");
      if (elementOpened) {
        elementOpened.classList.remove("isOpened");
      }
      e.currentTarget.parentNode.classList.add("isOpened");
    }

    if (elementChecked) {
      elementChecked.classList.remove("isChecked");
    }

    e.currentTarget.classList.add("isChecked");
    props.onClick && props.onClick();
  }

  return (
    <StyledFormRadio {...props} onClick={e => handleFormRadioClick(e)}>
      <label>{Children.toArray(props.children)}</label>
    </StyledFormRadio>
  );
};

FormRadio.propTypes = {
  expandable: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
};

export default FormRadio;
