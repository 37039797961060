import styled, { css } from "styled-components";

import { breakpoints, fontWeight } from "global-styles.js";
import validImg from "assets/images/valid.png";
import invalidImg from "assets/images/invalid.png";

import H1Style from "./H1Style";

const StyledH1 = styled.h1`
  ${H1Style};

  ${breakpoints.tablet`
    ${props =>
      props.theme === "secondary" &&
      css`
        color: var(--color-neutralDark);
        font-size: 20px;
        font-weight: ${fontWeight.bold};
      `}
  `}

  &.h1--success:after,
  &.h1--error:after {
    content: "";
    background: url(${validImg}) no-repeat;
    display: block;
    width: 27px;
    height: 27px;
    position: absolute;
    right: 0;
    top: 0;
  }

  &.h1--error:after {
    background: url(${invalidImg}) no-repeat;
  }

  svg {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 1rem;

    path {
      fill: ${props => props.color || "var(--color-primaryColor)"};
    }
  }
`;

export default StyledH1;
