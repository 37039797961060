import axios from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import docCookies from "../utils/Cookie";
import { logOut } from "utils/Helpers";

const getParameterValueFromStore = () => {
  // FIXME: à Ajuster, ceci ne passe pas avec le styleguide
  return /* store && store.getState().CommonReducers.parameters[ROUTE_KEY_REFRESH_TOKEN] ||*/ "/api/token/refresh";
};

const API = axios.create({
  baseURL: "/",
  responseType: "json",
});

if (process.env.NODE_ENV === 'development' &&  process.env.REACT_APP_MOCK) {
  import("./../../mock").then((dynamicImport) => {
    dynamicImport.Mock(API);
  });
}

// Function that will be called to refresh authorization
const refreshAuthLogic = failedRequest => {
  return API
    .post(getParameterValueFromStore(), {
      token: docCookies.getItem("token"),
      refresh_token: docCookies.getItem("refresh_token"), 
    })
    .then(response => {
      docCookies.setItem("token", response.data.token, parseFloat(process.env.REACT_APP_COOKIE_EXPIRE));
      docCookies.setItem("refresh_token", response.data.refresh_token, parseFloat(process.env.REACT_APP_COOKIE_EXPIRE));
      failedRequest.response.config.headers["Authorization"] = `Bearer  ${response.data.token}`;
      return Promise.resolve();
    }).catch((error) => {
      if (error.response.status === 401) {
        docCookies.removeItem("token");
        docCookies.removeItem("refresh_token");
      }
      return Promise.reject(error.response);
    });
};


docCookies.getItem("refresh_token") ?
  createAuthRefreshInterceptor(API, refreshAuthLogic)
  : API.interceptors.response.use( (response) => response, (error) => {
      if (error?.response?.status === 401) {
        docCookies.removeItem("token");
        docCookies.removeItem("refresh_token");
      }

      return Promise.reject(error.response);
    });

API.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  console.log(error);
  if (error.status === 401) {
    if (error.config.url.includes('login')) {
      // nothing to do
    } else {
      logOut();
    }
  }
  return Promise.reject(error);
});

API.interceptors.request.use(request => {
  const jwtToken = docCookies.getItem("token");

  if (jwtToken !== null) {
    request.headers["Authorization"] = `Bearer ${jwtToken}`;
  }

  return request;
});

export default API;
