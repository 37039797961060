import React, { Children } from "react";
import PropTypes from "prop-types";
import StyledFieldInputWithIconWrapper from "./StyledFieldInputWithIconWrapper";

export const FieldInputWithIconWrapper = props => (
  <StyledFieldInputWithIconWrapper> {Children.toArray(props.children)} </StyledFieldInputWithIconWrapper>
);

FieldInputWithIconWrapper.propTypes = {
  /** Contenu de l'input */
  children: PropTypes.array,
};

FieldInputWithIconWrapper.defaultProps = {
  children: null,
};
