import React from "react";
import PropTypes from "prop-types";
import StyledSeparator from "./StyledSeparator";

const Separator = props => <StyledSeparator {...props} className="custom-separator" />;

export default Separator;

Separator.propTypes = {
  /** Espaces autour du séparator (s'applique à margin) */
  gutter: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Separator.defaultProps = {
  gutter: "1.25rem -1rem",
  height: "1px",
  width: "auto",
};
