import React, { Children } from "react";
import PropTypes from "prop-types";

import Loader from "../Loader";

import StyledButton from "./StyledButton";
import { THEME_WHITE_LABEL, THEME_CUSTOM } from "@agence-numerique/common/lib/consts/ParametersApplication.const";

const Button = ({ isLoading, inactiv, className, theme, icon, ...props }) => (
  <StyledButton
    {...props}
    inactiv={inactiv}
    disabled={isLoading || inactiv}
    theme={theme}
    icon={icon}
    isLoading={isLoading}
    className={`custom-button ${className || ""}`}
  >
    <>
      {icon}
      <span className="button__label">{Children.toArray(props.children)}</span> {isLoading && <Loader theme={theme} />}
    </>
  </StyledButton>
);

Button.propTypes = {
  /** Permet d'aligner le bouton */
  align: PropTypes.oneOf(["left", "center", "right"]),
  /** Contenu du bouton */
  children: PropTypes.any,
  /** Rajoute les className */
  className: PropTypes.string,
  /** Permet d'utiliser un icon comme contenu  */
  icon: PropTypes.element,
  /** Affiche un loader dans le bouton à la place des children */
  isLoading: PropTypes.bool,
  /** Affiche le bouton en ecriture primary sur fond blanc */
  theme: PropTypes.oneOf(["primary", "secondary", "ternary", THEME_WHITE_LABEL, THEME_CUSTOM]),
  /** Floute le bouton et le rend impossible à cliquer */
  unavailable: PropTypes.bool,
  /** Rend le bouton impossible à cliquer */
  inactiv: PropTypes.bool,
};

Button.defaultProps = {
  align: "center",
  children: null,
  className: null,
  icon: null,
  isLoading: false,
  theme: "primary",
  ternary: false,
  unavailable: false,
  inactiv: false,
};

export default Button;
