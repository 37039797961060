export const ConnectMe = data => {
  try {
    return {
      id: data.id,
      type: data.type,
    };
  } catch {
    console.warn(`CONNECTOR - Me - format from API issue`);
    return null;
  }
};
