import styled, { css } from "styled-components";

const StyledSeparator = styled.div`
  background: var(--color-secondaryColorLighter);
  height: ${props => props.height || "auto"};
  width: ${props => props.width || "auto"};
  margin: ${props => props.gutter || null};

  ${props =>
    props.stretch &&
    css`
      display: flex;
      align-self: stretch;
    `}
`;

export default StyledSeparator;
