import styled from "styled-components";

const StyledFieldInputWithIconWrapper = styled.div`

  position: relative;

  input:focus-visible {
    border: solid 2px blue;
  }


  svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
`;

export default StyledFieldInputWithIconWrapper;
