import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

import styled from "styled-components";
import H1 from "@agence-numerique/common/lib/components/H1";
import { Text } from "@agence-numerique/common/lib/components/Paragraph";
import {
  FormWithMentions,
  FormFieldWrapper,
  FormLabel,
  ServerMessage,
} from "@agence-numerique/common/lib/components/Form";
import Button from "@agence-numerique/common/lib/components/Button";
import { breakpoints } from "@agence-numerique/common/lib/global-styles.js";
import { setToastMessageAction } from "@agence-numerique/common/lib/state/CommonActions";
import { PARAMETERS_APPLICATION_PASSWORD_KEY } from "@agence-numerique/common/lib/consts/ParametersApplication.const";
import middleware from "services/middleware";
import VisiblePasswordInput from "containers/Auth/VisiblePasswordInput";
import { validatePassword, passwordValidationParameters } from "utils/loginHelpers";
import LoginErrorText from "containers/Auth/LoginErrorText";

const CustomForm = styled(Form)`
  ${breakpoints.tablet`
      padding: 0 0px;
  `};
`;

const ResetPasswordForm = ({ location, setToastMessage, applicationParameters, theme }) => {
  const { t } = useTranslation();
  const urlParameters = queryString.parse(location.search);
  const userToken = urlParameters.token;

  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();

  const passwordParameters = applicationParameters[PARAMETERS_APPLICATION_PASSWORD_KEY];
  const [isPasswordConfirmValid, setPasswordConfirmValid] = useState();
  const [passwordValidation, setPasswordValidation] = useState(passwordValidationParameters(passwordParameters));
  const [resetPasswordErrorMessage, setResetPasswordErrorMessage] = useState("");

  let history = useHistory();

  const passwordValidationError = async (error) => setPasswordValidation(error);

  const onPasswordChange = async (password) => {
    let validationErrors = validatePassword(password, passwordParameters);
    await passwordValidationError(validationErrors);
    setPassword(password);
    setPasswordConfirmValid(passwordConfirm === password);
  };

  const onPasswordConfirmChange = (passwordConfirm) => {
    setPasswordConfirm(passwordConfirm);
    setPasswordConfirmValid(passwordConfirm === password);
  };

  return (
    <>
      <H1 theme={theme}>{t("passwordRecover.title")}</H1>
      <FormWithMentions
        context="portail"
        formId="ResetPwd"
        initialValues={{ password: "", passwordConfirm: "" }}
        onSubmit={async function resetPassword(values) {
          try {
            let resetPasswordReturn = await middleware.resetPassword(password, userToken);
            if (resetPasswordReturn.status === 201) {
              await setToastMessage(t("passwordRecover.toast.success"), "success");
              if (resetPasswordReturn?.data?.redirect) {
                history.push("/gestionnaire-login");
              } else {
                history.push("/");
              }
            } else if (resetPasswordReturn.status === 200 && resetPasswordReturn?.data?.err_008) {
              setResetPasswordErrorMessage(t("passwordRecover.err_008"));
            }
          } catch {
            await setToastMessage(t("passwordRecover.toast.error"), "error");
          }
        }}
      >
        {({ isSubmitting }) => (
          <CustomForm data-cy="form">
            <FormFieldWrapper>
              <FormLabel small htmlFor="password">
                {t("passwordRecover.new_password")}
              </FormLabel>
              <VisiblePasswordInput inputName="password" handleChange={(e) => onPasswordChange(e.target.value)} />
            </FormFieldWrapper>
            <FormFieldWrapper>
              <FormLabel small htmlFor="passwordConfirm">
                {t("passwordRecover.new_password_confirm")}
              </FormLabel>
              <VisiblePasswordInput
                inputName="passwordConfirm"
                handleChange={(e) => onPasswordConfirmChange(e.target.value)}
                notPastable="not pastable"
              />
              <Text
                theme={theme}
                className="text__theme--portail--title"
              >
                {t("passwordRecover.passwordRequiredTitle")}
              </Text>
              {!!passwordValidation.CharRequiredValidKey && (
                <LoginErrorText
                  isfieldValid={passwordValidation.CharRequiredValid}
                  error_text={t("passwordRecover.passwordCharRequired", {
                    count: passwordValidation.CharRequiredValidKey,
                  })}
                />
              )}
              {!!passwordValidation.UppercaseRequiredValidKey && (
                <LoginErrorText
                  isfieldValid={passwordValidation.UppercaseRequiredValid}
                  error_text={t("passwordRecover.passwordUppercaseRequired", {
                    count: passwordValidation.UppercaseRequiredValidKey,
                  })}
                />
              )}
              {!!passwordValidation.LowercaseRequiredValidKey && (
                <LoginErrorText
                  isfieldValid={passwordValidation.LowercaseRequiredValid}
                  error_text={t("passwordRecover.passwordLowercaseRequired", {
                    count: passwordValidation.LowercaseRequiredValidKey,
                  })}
                />
              )}
              {!!passwordValidation.NumberRequiredValidKey && (
                <LoginErrorText
                  isfieldValid={passwordValidation.NumberRequiredValid}
                  error_text={t("passwordRecover.passwordNumberRequired", {
                    count: passwordValidation.NumberRequiredValidKey,
                  })}
                />
              )}
              {!!passwordValidation.SpecialRequiredValidKey && (
                <LoginErrorText
                  isfieldValid={passwordValidation.SpecialRequiredValid}
                  error_text={t("passwordRecover.passwordSpecialRequired", {
                    count: passwordValidation.SpecialRequiredValidKey,
                    list: passwordValidation.SpecialRequiredValidListKey,
                  })}
                />
              )}
              <LoginErrorText isfieldValid={isPasswordConfirmValid} error_text={t("passwordRecover.passwordConfirm")} />
            </FormFieldWrapper>
            {resetPasswordErrorMessage?.length > 3 && (
              <ServerMessage data-cy="loginErrorMessage">{resetPasswordErrorMessage}</ServerMessage>
            )}
            <Button
              type="submit"
              inactiv={
                !passwordValidation.CharRequiredValid ||
                !passwordValidation.UppercaseRequiredValid ||
                !passwordValidation.LowercaseRequiredValid ||
                !passwordValidation.NumberRequiredValid ||
                !passwordValidation.SpecialRequiredValid ||
                !isPasswordConfirmValid
              }
              isLoading={isSubmitting}
              margin="1rem auto"
              theme={theme}
            >
              {t("passwordRecover.retrieve")}
            </Button>
          </CustomForm>
        )}
      </FormWithMentions>
    </>
  );
};

ResetPasswordForm.propTypes = {
  location: PropTypes.object,
  setToastMessage: PropTypes.func,
  applicationParameters: PropTypes.object,
  theme: PropTypes.string,
};

function mapStateToProps(state) {
  const { CommonReducers } = state;

  return {
    applicationParameters: CommonReducers.applicationParameters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setToastMessage: (message, type) => dispatch(setToastMessageAction({ message, type })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
