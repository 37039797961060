import styled, { css } from "styled-components";
import { breakpoints, fontWeight } from "../../global-styles.js";
import { THEME_WHITE_LABEL, THEME_CUSTOM } from "@agence-numerique/common/lib/consts/ParametersApplication.const";


const StyledText = styled.p`
  color: ${props => props.color || "var(--color-neutral)"};
  font-size: ${props => props.fsize || "0.85rem"};
  font-weight: ${props => (props.fweight ? fontWeight[props.fweight] : null)};
  margin: ${props => props.margin || null};
  text-align: ${props => props.align || null};
  padding: ${props => props.padding || "0 0 1rem"};
  text-decoration: ${props => props.tdecoration || "none"};

  strong > strong {
    color: var(--color-primaryColor);
  }

  /** FIXME: transform classes to props theme */
  &.text__emphaze {
    background-color: var(--color-neutralLight);
    border: 1px solid var(--color-secondaryColorLighter);
    padding: 1rem;
  }

  &.text__italic {
    font-style: italic
  }

  &.text__theme--manager {
    color: var(--color-actionRealEstate);
  }

  &.text__theme--client {
    color: var(--color-actionProspect);
  }

  &.text__theme--neutral {
    color: var(--color-neutralDarker);
  }

   &.text__theme--login {
    color: var(--color-custom-secondary);
    font-size: 0.875rem;;
    line-height: 1.66666667;
  }

  ${breakpoints.tablet`
    font-size: ${props => props.fsize || "14px"};
    margin: ${props => props.tabletMargin || null};
    text-align: ${props => props.align || props.tabletAlign || null};
  `};

  ${breakpoints.desktop`
    white-space: ${props => (props.desktopWspace ? props.desktopWspace : null)};
  `};

  ${props =>
    props.ellipsis &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}

  ${props =>
    props.bordered &&
    css`
      border: 1px solid var(--color-secondaryColorLighter);
      padding: 1rem;
    `}

  ${props =>
    props.small &&
    css`
      font-size: 12px;
    `}

  ${props =>
    props.flex &&
    css`
      display: flex;
      justify-content: ${props.flex};
    `}

  ${props =>
    props.gutter &&
    props.gutter === "small" &&
    css`
      padding: 0 0 0.5rem;
    `}

  ${props =>
    props.gutter &&
    props.gutter === "no" &&
    css`
      padding: 0;
    `}

    ${props =>
    props.theme &&
    props.theme === THEME_CUSTOM &&
    css`
      &.text__theme--portail--title {
        color: var(--color-custom-primary);
        font-weight: bold;
        padding: 15px 0px 0px;
        font-size: 18px;
      }
      &.text__theme--portail--regular{
        color: var(--color-custom-secondary);
        font-size: 0.875rem;;
        line-height: 1.66666667;
        margin-bottom: 5px;
        margin-top: 20px;
      }
      &.text__theme--portail--footer{
        text-align: center;
        color: var(--color-custom-footer);
        font-size: var(--font-size-custom-small);
      }
    `}

    ${props =>
    props.theme &&
    props.theme === THEME_WHITE_LABEL &&
    css`
      &.text__theme--portail--title {
        color: var(--color-whitelabel-primary);
        font-weight: var(--font-weight-whitelabel-title);
        padding: 15px 0px 0px;
        font-size: var(--font-size-whitelabel-title);
      }
      &.text__theme--portail--regular{
        color: var(--color-whitelabel-secondary);
        line-height: 1.66666667;
        font-weight:var(--font-weight-whitelabel-regular);
        padding: 15px 0px 0px;
        font-size: var(--font-size-whitelabel-regular);
        margin-bottom: 20px;
        margin-top: 10px;
      }
      &.text__theme--portail--footer{
        text-align: center;
        color: var(--color-whitelabel-footer);
        font-size: var(--font-size-whitelabel-small);
      }
    `}
`;

export default StyledText;
