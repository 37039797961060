import styled from "styled-components";
import { fontWeight } from "../../global-styles.js";

const StyledFormRadio = styled.div`
  margin: ${props => props.margin || null};
  label {
    padding-left: 1.5rem;
    position: relative;
    cursor: pointer;
  }

  label svg,
  label img {
    margin: 0 0.5rem;
    max-width: 25px;
  }

  label strong {
    font-weight: ${fontWeight.semiBold};
  }

  label:before {
    border-radius: 50%;
    border: 1px solid var(--color-secondaryColorLighter);
    content: "";
    height: 16px;
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
  }

  // When selected
  &.isChecked {
    label:after {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: var(--color-primaryColor);
      position: absolute;
      top: 3px;
      left: 3px;
    }
  }
`;

export default StyledFormRadio;
