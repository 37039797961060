import React from "react";
import PropTypes from "prop-types";
import { HashRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";

import "@agence-numerique/common/lib/assets/css/globalColors.css";
import Layout from "./containers/Layout";
import Auth from "./containers/Auth";
import routes from "./routes";

import PageContent from "@agence-numerique/common/lib/shared/PageContent";
import { useTranslation } from "react-i18next";


const PortalRouter = ({ applicationParameters }) => {
  const { t, i18n } = useTranslation();
  const contentPages = i18n.t('contentPages', {returnObjects: true}) || [];
  const pageContent = (current) => {
    let found = contentPages.find(content => Object.keys(content).pop() === current);
    return found[current];
  };
  let urls = [];
  if (Array.isArray(contentPages)) {
    urls = contentPages.map(item => ('/' + Object.keys(item)));
  }
  return (
    <Router>
      <Layout>
        <Route path={Object.values(routes)} component={Auth} />
        <Route exact path={urls} render={routeProps => {
              const call = routeProps.location.pathname.substring(1).toLowerCase();
              const content = pageContent(call);
              if (content) {
                return <PageContent content={content} {...routeProps}/>;
              } else {
                return (
                  <div>
                    <h1>{t("error_404")}</h1>
                  </div>
                );
              }
        }} />
      </Layout>
    </Router>
  );
};

PortalRouter.propTypes = {
  applicationParameters: PropTypes.object,
};

function mapStateToProps(state) {
  const { CommonReducers } = state;

  return {
    applicationParameters: CommonReducers.applicationParameters,
  };
}

export default connect(mapStateToProps)(PortalRouter);
