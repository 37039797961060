import React from "react";
import { Link } from "@agence-numerique/common/lib/components/Link";
import PropTypes from "prop-types";
import { THEME_WHITE_LABEL, THEME_CUSTOM } from "@agence-numerique/common/lib/consts/ParametersApplication.const";

let linkStyle = {};
const linkStyleWhiteLabel = {
  color: "#a60726"
};

const linkStyleCustom = {
  color: "#f3a648"
};

const PortailLink = ({ linkTo, children, theme }) => {

    if( theme === THEME_CUSTOM) {
        linkStyle = linkStyleCustom;
    } else {
        linkStyle = linkStyleWhiteLabel;
    }
    return (
      <>
        <Link to={linkTo} style={linkStyle}>
          {children}
        </Link>
      </>
    );
};

//* ************ Proptypes *************//
PortailLink.propTypes = {
  /** Theme */
  theme: PropTypes.oneOf([THEME_WHITE_LABEL, THEME_CUSTOM]),
  /** Contenu */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.array]),
  /** Linked content */
  linkTo: PropTypes.any,
};

PortailLink.defaultProps = {
  theme: null,
  children: null,
  linkTo: null,
};

export default PortailLink;
