export const GET_PARAMETERS = "GET_PARAMETERS";
export const SAVE_APPLICATION_PARAMETERS = "SAVE_APPLICATION_PARAMETERS";
export const SET_USER = "SET_USER";
export const SET_LOADING_USER = "SET_LOADING_USER";
export const SET_TOAST_MESSAGE = "SET_TOAST_MESSAGE";
export const CLEAN_TOAST = "CLEAN_TOAST";
export const UPDATE_INSURANCE_INFO = "UPDATE_INSURANCE_INFO";
export const UPDATE_PAYMENT_STATUS = "UPDATE_PAYMENT_STATUS";
export const UPDATE_PAYMENT_STATUS_TO_VALID = "UPDATE_PAYMENT_STATUS_TO_VALID";
export const UPDATE_KEYHANDOVER_TYPE = "UPDATE_KEYHANDOVER_TYPE";
export const UPDATE_DEBITMANDATE = "UPDATE_DEBITMANDATE";
export const ACTION_LIST_ALLOWED = "ACTION_LIST_ALLOWED";
