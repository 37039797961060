import styled, { css } from "styled-components";
import { fontWeight, breakpoints } from "../../global-styles.js";

const StyledH3 = styled.h3`
  color: ${props => props.color || "var(--color-neutralDark)"};
  font-size: 1rem;
  text-align: ${props => props.align || "left"};
  text-transform: ${props => props.txtTransform || null};
  margin: ${props => props.margin || "0 0 1rem"};

  ${breakpoints.tablet`
    font-size: 18px;
  `}

  ${props =>
    props.medium &&
    css`
      font-size: 1rem;
      font-weight: ${fontWeight.semiBold};
    `};
  ${props =>
    props.bold &&
    css`
      font-size: 1rem;
      font-weight: ${fontWeight.bold};
    `};
  ${props =>
    props.secondary &&
    css`
      color: var(--color-primaryColor);
    `};

  .h3_date--formated {
    background: var(--color-neutralLight);
    color: var(--color-neutralDark);
    font-weight: ${fontWeight.semiBold};
    padding: 0.25rem 0.5rem;
  }
`;

export default StyledH3;
