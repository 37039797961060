import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";

import { GlobalStyle } from "@agence-numerique/common/lib/global-styles.js";
import "@agence-numerique/common/lib/assets/css/globalColors.css";
import { storeParameters, storeApplicationParameters } from "@agence-numerique/common/lib/utils/Helpers";
import { loadCustomI18nRessources } from "@agence-numerique/common/lib/utils/I18n";
import { isLoggedIn, logOut } from "@agence-numerique/common/lib/utils/Helpers";
import "@agence-numerique/common/lib/assets/css/fonts.css";

import store from "store";
import PortalRouter from "./PortalRouter";
import { userRedirection } from "./utils/helpers";
import { AVAILABLE_LANGUAGES } from "./i18n";

import {
  THEME_WHITE_LABEL,
} from "@agence-numerique/common/lib/consts/ParametersApplication.const";


import docCookies from "@agence-numerique/common/lib/utils/Cookie";

const App = () => {
  const [appLoading, setAppLoading] = useState(true);
  const [applicationParameters, setApplicationParameters] = useState(true);

  useEffect(() => {
    const checkUserInfo = async () => {
      await storeParameters(store);
      setApplicationParameters(await storeApplicationParameters(store));
      if (isLoggedIn()) {
        try {
          if (!docCookies.getItem("logInOnBeHalf")) {
            await userRedirection();
          }
        } catch (error) {
          switch (error.status) {
            case 403:
              logOut(store);
              break;
            default:
              break;
          }
        }
      }
    };
    Promise.all([loadCustomI18nRessources(AVAILABLE_LANGUAGES), checkUserInfo()]).then(() => {
      setAppLoading(false);
    });
  }, []);

  return appLoading ? (
    <p>Chargement...</p>
  ) : applicationParameters?.payload?.PORTAIL_THEMING === THEME_WHITE_LABEL ? (
    <ThemeProvider theme={{ backgroundImg: "custom/img/background.jpg" }}>
      <GlobalStyle />
      <Provider store={store}>
        <PortalRouter />
      </Provider>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={{ custom: true }}>
      <GlobalStyle />
      <Provider store={store}>
        <PortalRouter />
      </Provider>
    </ThemeProvider>
  );
};

export default App;
