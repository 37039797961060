import React from "react";
import PropTypes from "prop-types";
import StyledText from "./StyledText";
import { THEME_WHITE_LABEL, THEME_CUSTOM } from "@agence-numerique/common/lib/consts/ParametersApplication.const";

const Text = ({ children, color, ellipsis, bordered, small, flex, gutter, className, theme, ...props }) => (
  <StyledText
    {...props}
    color={color}
    ellipsis={ellipsis}
    bordered={bordered}
    small={small}
    flex={flex}
    gutter={gutter}
    className={`${className || ""} custom-text`}
    theme={theme}
  >
    {children}
  </StyledText>
);

Text.propTypes = {
  /** Contenu d'un composant Text */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  /** Couleur du texte */
  color: PropTypes.string,
  /** Taille de la fonte */
  fsize: PropTypes.string,
  /** Poids de la fonte à appliquer */
  fweight: PropTypes.oneOf(["regular", "semiBold", "bold", "veryBold"]),
  /** Marge */
  margin: PropTypes.string,
  /** Padding */
  padding: PropTypes.string,
  /** Alignement du texte */
  align: PropTypes.string,
  /** Permet de limiter le texte avec des ... */
  ellipsis: PropTypes.bool,
  /** Permet de border le texte ... */
  bordered: PropTypes.bool,
  /** Permet de réduire la taille de la police ... */
  small: PropTypes.bool,
  /** Permet d'afficher le contenu du composant en flex ... */
  flex: PropTypes.bool,
  /** Permet de gérer la taille des espacements ... */
  gutter: PropTypes.oneOf(["small", "no"]),
  /** Permet de gérer le soulignement ...  */
  tdecoration: PropTypes.string,
  /** ClassName personnalisée à utiliser */
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([
      "text__emphaze",
      "text__italic",
      "text__theme--manager",
      "text__theme--client",
      "text__theme--neutral",
      "text__desc",
      "text__theme--portail--title"
    ]),
  ]),
  /** Theme */
  theme: PropTypes.oneOf([THEME_WHITE_LABEL, THEME_CUSTOM]),
};

Text.defaultProps = {
  color: "var(--color-neutral)",
  children: null,
  className: null,
  fsize: "0.85rem",
  margin: null,
  align: null,
  padding: "0 0 1rem",
  ellipsis: false,
  bordered: false,
  small: false,
  flex: false,
  gutter: null,
  theme: null,
};

export default Text;
