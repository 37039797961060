import API from "utils/API";
import { ConnectParameters } from "connectors/Parameter.connector";
import { ConnectCountryList } from "connectors/Country.connector";
import { ConnectDebitDayList } from "connectors/DebitDay.connector";
import { ConnectMandateDebit } from "connectors/DebitMandate.connector";
import {
  ApplicantFileConnector,
  ApplicantFileReturnDocumentConnector,
  ApplicantFileDeleteResponseConnector,
} from "connectors/ApplicantFile.connector";

import {
  ConnectDepositCurrentStatus,
  ConnectPaymentCompany,
  ConnectPaymentMode,
  ConnectScelliusRedirectionForm,
} from "connectors/Payments.connector";
import { ConnectNextStepList } from "connectors/NextStep.connector";
import { ConnectAssistanceMeanList } from "connectors/AssistanceMean.connector";
import { ConnectKeyHandoverMode } from "connectors/KeyHandover.connector";
import { ConnectParametersApplicationList } from "connectors/ParametersApplication.connector";

import { formatDateForAPI } from "utils/I18n";

import {
  ROUTE_PARAMETERS,
  ROUTE_KEY_LOGOUT,
  ROUTE_KEY_POSSIBLE_DEBIT_DAYS_FOR_COMPANY,
  ROUTE_KEY_POSSIBLE_COUNTRIES,
  ROUTE_KEY_MANDATE_DEBIT_DOCUMENT,
  ROUTE_KEY_MANDATE_DEBIT,
  ROUTE_KEY_MANDATE_REDIRECT_URL,
  ROUTE_KEY_MANDATE_REDIRECT_URL_MOCK,
  ROUTE_KEY_APPLICANTFILE_LIST,
  ROUTE_KEY_APPLICANTFILE_SAVE,
  ROUTE_KEY_DOCUMENT_ID,
  ROUTE_KEY_CREATE_INSURANCE,
  ROUTE_KEY_GET_SECURITY_PAYMENT,
  ROUTE_KEY_GET_COMPANY_INFO,
  ROUTE_KEY_GET_AGENCY_INFO,
  ROUTE_KEY_LIST_SECURITY_DEPOSIT_PAYMENT_MODE,
  ROUTE_KEY_GET_SCELLIUS_REDIRECTION_FORM,
  ROUTE_KEY_SCELLIUS_REDIRECTION_FORM_MOCK,
  ROUTE_KEY_UPDATE_HOUSINGCOMISSION_STATUS,
  ROUTE_KEY_GET_BAIL_DOCUMENTS,
  ROUTE_KEY_GET_BAIL_DOCUMENT_STREAM,
  ROUTE_KEY_PUT_INSURANCE_FILE,
  ROUTE_KEY_PUT_INSURANCE,
  ROUTE_KEY_REQUEST_NEXT_STEPS,
  ROUTE_KEY_REQUEST_CHANGE_STEPS,
  ROUTE_KEY_DOWNLOAD_MESSAGE_DOCUMENT,
  ROUTE_KEY_IFRAME_CALENDLY_PROPOSAL,
  ROUTE_KEY_IFRAME_CALENDLY_MOCK,
  ROUTE_KEY_PARAM_APP_LIST,
  ROUTE_KEY_PUT_KEY_HANDOVER,
  ROUTE_KEY_LIST_KEYHANDOVER_MODE,
  ROUTE_KEY_GET_ASSISTANCE_MEANS,
  ROUTE_KEY_POST_ASSISTANCE_MEANS,
  PLACEHOLDER_PROPOSAL_ID,
  PLACEHOLDER_PAYMENT_ID,
  PLACEHOLDER_DOCUMENT_ID,
  PLACEHOLDER_DOCUMENT_TYPE,
  PLACEHOLDER_HOUSING_REQUEST_ID,
  PLACEHOLDER_FRONT_URL,
  PLACEHOLDER_HOUSING_COMMISSION_ID,
  PLACEHOLDER_STATUS,
  PLACEHOLDER_COMPANY_ID,
  PLACEHOLDER_INSURANCE_ID,
  PLACEHOLDER_REQUEST_ID,
  PLACEHOLDER_STEP_ID,
  PLACEHOLDER_GENERIC_ID,
  PLACEHOLDER_RENTALFILE_ID,
} from "consts/Parameters.const";

import { KEYHANDOVER_MODE_HOME } from "consts/KeyHandover.const";

import { formatEndpoint } from "utils/Helpers";

async function getParameters() {
  const response = await API.get(ROUTE_PARAMETERS);
  return ConnectParameters(response.data);
}

function getParameterUrlFromStore(store, key) {
  return store.getState().CommonReducers.parameters[key];
}

async function getCalendlyIframeInfos(store, propositionId) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_IFRAME_CALENDLY_PROPOSAL);
  const response = await API.get(endpoint.replace(PLACEHOLDER_PROPOSAL_ID, propositionId));
  return response;
}

async function fakeCalendlyCall(store, propositionId, email, date) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_IFRAME_CALENDLY_MOCK);
  const response = await API.post(endpoint, {
    proposition_id: propositionId,
    email,
    date,
  });
  return response;
}

export function callParameterUrl(endpoint) {
  return API.get(endpoint)
    .then((response) => response.data)
    .catch((error) => error.response);
}

function changePropositionStatus(endpoint, id, status, description) {
  return API.put(`${endpoint}/${id}/status`, {
    code: status,
    description,
  })
    .then((response) => response)
    .catch((error) => error);
}

async function putHousingCommissionStatus(store, housingCommissionId, status, comment) {
  const endpoint = store.getState().CommonReducers.parameters[ROUTE_KEY_UPDATE_HOUSINGCOMISSION_STATUS];
  const mapPlaceholder = {
    [PLACEHOLDER_HOUSING_COMMISSION_ID]: housingCommissionId,
    [PLACEHOLDER_STATUS]: status,
  };
  const response = await API.put(formatEndpoint(endpoint, mapPlaceholder), {
    description: comment,
  });

  return response.data;
}

async function downloadDocument(name, uri) {
  const response = await API({
    url: uri,
    method: "GET",
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
}

async function downloadConversationDocument(store, documentId, name) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_DOWNLOAD_MESSAGE_DOCUMENT);
  const uri = endpoint.replace(PLACEHOLDER_GENERIC_ID, documentId);

  return downloadDocument(name, uri);
}

async function getPossibleDebitDays(store, proposalId) {
  const company = await getCompanyInfo(store, proposalId);
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_POSSIBLE_DEBIT_DAYS_FOR_COMPANY);
  const response = await API.get(endpoint.replace(PLACEHOLDER_COMPANY_ID, company.id));

  return ConnectDebitDayList(response.data);
}

async function getPossibleCountries(store) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_POSSIBLE_COUNTRIES);
  const response = await API.get(endpoint);

  return ConnectCountryList(response.data);
}

async function getMandateDebit(store, housingRequestId) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_MANDATE_DEBIT);
  const response = await API.get(endpoint.replace(PLACEHOLDER_HOUSING_REQUEST_ID, housingRequestId));
  if (response.data) {
    return ConnectMandateDebit(response.data);
  }
  return null;
}

function getMandateDebitDocumentUrl(store, housingRequestId) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_MANDATE_DEBIT_DOCUMENT);

  return endpoint.replace(PLACEHOLDER_HOUSING_REQUEST_ID, housingRequestId);
}

async function postMandateRedirectURL(store, housingRequestId, infos) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_MANDATE_REDIRECT_URL);
  const response = await API.post(endpoint, { ...infos, housingRequestId: housingRequestId });
  return response.data[0];
}

async function fakeSlimpayCall(store, housingRequestId, infos) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_MANDATE_REDIRECT_URL_MOCK);
  const response = await API.post(endpoint, { ...infos, housingRequestId: housingRequestId });
  return response.data[0];
}

async function getProspectFiles(store, housingRequestId) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_APPLICANTFILE_LIST);
  const response = await API.get(endpoint.replace(PLACEHOLDER_HOUSING_REQUEST_ID, housingRequestId));

  return ApplicantFileConnector(response.data);
}

async function postSaveProspectFile(store, housingRequestId, documentTypeId, id, file) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_APPLICANTFILE_SAVE);
  const data = await getBase64(file);
  const response = await API.post(endpoint.replace(PLACEHOLDER_HOUSING_REQUEST_ID, housingRequestId), {
    document_type_id: documentTypeId,
    id,
    data,
    name: file.name,
  });

  return ApplicantFileReturnDocumentConnector(response.data);
}

async function deleteDocument(store, documentId) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_DOCUMENT_ID);
  const response = await API.delete(endpoint.replace(PLACEHOLDER_DOCUMENT_ID, documentId));

  return ApplicantFileDeleteResponseConnector(response.data);
}

export function getBase64(file) {
  const reader = new FileReader();
  return new Promise((resolve) => {
    reader.onload = (ev) => {
      resolve(ev.target.result);
    };
    reader.readAsDataURL(file);
  });
}

async function postInitializePayment(endpoint, propositionId) {
  const response = await API.post(endpoint, { proposition_id: propositionId });

  return response;
}

async function getSecurityDepositPaymentStatus(store, propositionId) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_GET_SECURITY_PAYMENT);

  const response = await API.get(endpoint.replace(PLACEHOLDER_PROPOSAL_ID, propositionId));

  return ConnectDepositCurrentStatus(response.data);
}

async function getAgencyInfo(store, proposalId) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_GET_AGENCY_INFO);
  const response = await API.get(endpoint.replace(PLACEHOLDER_GENERIC_ID, proposalId));
  return ConnectPaymentCompany(response.data);
}

async function getCompanyInfo(store, proposalId) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_GET_COMPANY_INFO);
  const response = await API.get(endpoint.replace(PLACEHOLDER_GENERIC_ID, proposalId));
  return ConnectPaymentCompany(response.data);
}

async function getPaymentsMethods(store) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_LIST_SECURITY_DEPOSIT_PAYMENT_MODE);
  const response = await API.get(endpoint);

  return ConnectPaymentMode(response.data);
}

async function postScelliusRedirectionForm(store, propositionId, frontUrl, cbPaymentId) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_GET_SCELLIUS_REDIRECTION_FORM);

  const mapPlaceholder = {
    [PLACEHOLDER_FRONT_URL]: encodeURIComponent(frontUrl),
    [PLACEHOLDER_PROPOSAL_ID]: propositionId,
    [PLACEHOLDER_PAYMENT_ID]: cbPaymentId,
  };
  const response = await API.get(formatEndpoint(endpoint, mapPlaceholder));

  return ConnectScelliusRedirectionForm(response.data);
}

async function fakeScelliusCall(store, propositionId, frontUrl, cbPaymentId) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_SCELLIUS_REDIRECTION_FORM_MOCK);
  const response = await API.get(
    endpoint,
    {
      params: {
        proposition_id: propositionId,
        payment_mode_id: cbPaymentId,
        normal_return_url: frontUrl,
      },
    },
  );

  return ConnectScelliusRedirectionForm(response.data);
}

async function postInsurance(store, values) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_CREATE_INSURANCE);
  let bodyFormData = new FormData();
  bodyFormData.append("file", values.file);
  bodyFormData.append("insurer", values.insurerId);
  bodyFormData.append("beginValidityDate", formatDateForAPI(values.beginValidityDate));
  bodyFormData.append("endValidityDate", formatDateForAPI(values.endValidityDate));
  bodyFormData.append("contractNumber", values.contractNumber);
  bodyFormData.append("rentalFile", values.rentalFileId);
  const response = await API.post(endpoint, bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
}

async function putInsurance(store, insuranceId, values) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_PUT_INSURANCE);
  const response = await API.put(endpoint.replace(PLACEHOLDER_INSURANCE_ID, insuranceId), {
    insurer: values.insurerId,
    beginValidityDate: formatDateForAPI(values.beginValidityDate),
    endValidityDate: formatDateForAPI(values.endValidityDate),
    contractNumber: values.contractNumber,
  });

  return response.data;
}

async function getDocuments(store, housingRequestId) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_GET_BAIL_DOCUMENTS);
  const response = await API.get(endpoint.replace(PLACEHOLDER_HOUSING_REQUEST_ID, housingRequestId));

  return response.data;
}

async function getDocumentStream(store, housingRequestId, documentId, documentType, documentDisplayName) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_GET_BAIL_DOCUMENT_STREAM);
  const mapPlaceholder = {
    [PLACEHOLDER_HOUSING_REQUEST_ID]: housingRequestId,
    [PLACEHOLDER_DOCUMENT_ID]: documentId,
    [PLACEHOLDER_DOCUMENT_TYPE]: documentType,
  };

  return downloadDocument(documentDisplayName, formatEndpoint(endpoint, mapPlaceholder));
}

async function postInsuranceFile(store, insuranceId, file) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_PUT_INSURANCE_FILE);
  let bodyFormData = new FormData();
  bodyFormData.append("file", file);
  const response = await API.post(endpoint.replace(PLACEHOLDER_INSURANCE_ID, insuranceId), bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
}

async function getNextSteps(store, requestId) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_REQUEST_NEXT_STEPS);
  const response = await API.get(endpoint.replace(PLACEHOLDER_REQUEST_ID, requestId));
  return ConnectNextStepList(response.data);
}

async function putMoveNextStep(store, requestId, stepId) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_REQUEST_CHANGE_STEPS);
  const mapPlaceholder = {
    [PLACEHOLDER_REQUEST_ID]: requestId,
    [PLACEHOLDER_STEP_ID]: stepId,
  };
  const response = await API.put(formatEndpoint(endpoint, mapPlaceholder));
  return response.data;
}

async function getParametersApplicationsList(store) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_PARAM_APP_LIST);
  const response = await API.get(endpoint);

  return ConnectParametersApplicationList(response.data);
}

async function putKeyHandvoverModeByPost(store, rentalFileId, values) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_PUT_KEY_HANDOVER);
  const response = await API.put(endpoint.replace(PLACEHOLDER_RENTALFILE_ID, rentalFileId), {
    code: KEYHANDOVER_MODE_HOME,
    firstname: values?.firstName,
    lastname: values?.lastName,
    civility: values?.civility,
    address: values?.address,
    address_more: values?.addressMore,
    city: values?.city,
    zipcode: values?.postCode,
  });

  return response.data;
}

async function putKeyHandvoverMode(store, rentalFileId, keyHandoverMode) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_PUT_KEY_HANDOVER);
  const response = await API.put(endpoint.replace(PLACEHOLDER_RENTALFILE_ID, rentalFileId), {
    code: keyHandoverMode,
  });

  return response.data;
}

async function getKeyHandOverModeList(store) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_LIST_KEYHANDOVER_MODE);
  const response = await API.get(endpoint);

  return ConnectKeyHandoverMode(response.data);
}

async function getAssistanceMeanList(store) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_GET_ASSISTANCE_MEANS);
  const response = await API.get(endpoint);

  return ConnectAssistanceMeanList(response.data);
}

async function postAssistanceMeanSelected(store, assistanceMeansSelected, requestId) {
  const endpoint = getParameterUrlFromStore(store, ROUTE_KEY_POST_ASSISTANCE_MEANS);

  const response = await API.post(endpoint.replace(PLACEHOLDER_HOUSING_REQUEST_ID, requestId), {
    housingAssistances: assistanceMeansSelected.map((item) => +item),
  });

  return response.data;
}

async function logOut(store) {
  let endpoint;
  if (store) {
    endpoint = getParameterUrlFromStore(store, ROUTE_KEY_LOGOUT);
  } else {
    endpoint = 'logout';
  }
  const response = await API.get(endpoint);
  return response.data;
}


export default {
  logOut,
  getParameters,
  changePropositionStatus,
  putHousingCommissionStatus,
  downloadDocument,
  downloadConversationDocument,
  getPossibleDebitDays,
  getPossibleCountries,
  getMandateDebit,
  getMandateDebitDocumentUrl,
  postMandateRedirectURL,
  fakeSlimpayCall,
  getProspectFiles,
  postSaveProspectFile,
  deleteDocument,
  getBase64,
  postInitializePayment,
  getSecurityDepositPaymentStatus,
  getCompanyInfo,
  getAgencyInfo,
  getPaymentsMethods,
  postScelliusRedirectionForm,
  fakeScelliusCall,
  postInsurance,
  getDocuments,
  getDocumentStream,
  postInsuranceFile,
  putInsurance,
  getNextSteps,
  putMoveNextStep,
  getParametersApplicationsList,
  putKeyHandvoverModeByPost,
  getKeyHandOverModeList,
  putKeyHandvoverMode,
  getCalendlyIframeInfos,
  fakeCalendlyCall,
  getAssistanceMeanList,
  postAssistanceMeanSelected,
};
