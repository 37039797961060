import styled from "styled-components";

const StyledSeparator = styled.div`
  hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #f1f1f1;
}
`;

export default StyledSeparator;