export const ConnectAssistanceMeanList = data => {
  const list = data?.map(assistanceMean => {
    return {
      id: assistanceMean.id,
      code: assistanceMean.code,
      name: assistanceMean.name,
      description: assistanceMean.description,
    };
  });
  return list || [];
};
