import styled from "styled-components";

const StyledContainerChild = styled.div`
  max-height: 0;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
  margin: ${props => (props.margin ? props.margin : null)};

  &.expandable-child--isOpen {
    max-height: 9999px;
    transition: max-height 1s ease-in;
  }

  &.expandable-child--overflowVisible {
    overflow: visible;
  }
  @media (prefers-reduced-motion: reduce) {
    .expandable-child--isOpen,
    .expandable-child--overflowVisible {
      transition: none;
    }
  }
`;

export default StyledContainerChild;
