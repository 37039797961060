import docCookies from "utils/Cookie";
import middleware from "services/middleware";
import { getParametersAction, saveApplicationParametersAction } from "state/CommonActions";
import { COOKIE_PORTAIL_URI } from "consts/Application.const";

// --------------------------------------------------------------------------- //
//  DOM Helpers
// --------------------------------------------------------------------------- //
import { format } from "date-fns";
import { fr } from "date-fns/locale";

export function handleCloseContainersChilds() {
  const openedParentsContainer = document.querySelectorAll(".expandable--isOpen");
  openedParentsContainer.forEach(container => {
    container.classList.remove("expandable--isOpen");
    container.nextSibling.classList.remove("expandable-child--isOpen");
  });
}

export function formatAvailabilityDate(date, text) {
  const currentDate = new Date();
  const availibilityDate = new Date(date);

  if (currentDate > availibilityDate) {
    return text.availableNow;
  }

  return `${text.availablefrom} : ${format(availibilityDate, "dd MMMM yyyy", {
    locale: fr,
  })}`;
}

export const storeParameters = async store => {
  const getParametersResponse = await middleware.getParameters();
  await store.dispatch(getParametersAction(getParametersResponse));
};

export const storeApplicationParameters = async store => {
  const getParametersResponse = await middleware.getParametersApplicationsList(store);
  return await store.dispatch(saveApplicationParametersAction(getParametersResponse));
};

export const createLinkAndDownload = (name, data) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
};

export const isLoggedIn = () => {
  return docCookies.hasItem("token");
};

export const logOut = async (store) => {
  try {
    await middleware.logOut(store);
  } catch (e) {
    console.log('LogOutError: ', e);
  }
  docCookies.removeItem("token");
  docCookies.removeItem("refresh_token");
  docCookies.removeItem("logInOnBeHalf");
  window.location.replace(docCookies.getItem(COOKIE_PORTAIL_URI));
};

export const formatEndpoint = (endpoint, mapPlaceholder) => {
  let endPointFormated = [];
  endpoint
    .split(/(&|=|\/)/)
    .forEach(item =>
      mapPlaceholder[item] ? endPointFormated.push(mapPlaceholder[item]) : endPointFormated.push(item),
    );

  return endPointFormated.join("");
};

// Files helpers

export const validateFileExtension= (fileName) => {
    const regexp = /\.jpeg|\.jpg|\.png|\.pdf/g;
    var lowerCaseFile = fileName.toLowerCase();
    return lowerCaseFile.match(regexp);
  };
