export const PARAMETERS_APPLICATION_CODE_TYPE_OF_INVENTORY = "TYPE_OF_INVENTORY";
export const PARAMETERS_APPLICATION_VALUE_TYPE_OF_INVENTORY_ON_SITE = "on_site";
export const PARAMETERS_APPLICATION_VALUE_TYPE_OF_INVENTORY_DIGITAL = "digital";
export const PARAMETERS_APPLICATION_CODE_ALLOW_CALENDLY_MODIF = "CALENDLY_MODIFICATION";

export const PARAMETERS_APPLICATION_CODE_DIRECT_DEBIT_MANDATORY = "DIRECT_DEBIT_MANDATORY";
export const PARAMETERS_APPLICATION_VALUE_DIRECT_DEBIT_MANDATORY = "mandatory";
export const PARAMETERS_APPLICATION_VALUE_DIRECT_DEBIT_OPTIONAL = "optional";

export const PARAMETERS_APPLICATION_CODE_TRACKINGURL = "TRACKINGURL";

export const PARAMETERS_APPLICATION_CODE_GTM_ID = "GTM_ID";

export const PARAMETERS_APPLICATION_TIMER_PROPOSITION_APPOINTMENT_MADE = "TIMER_PROPOSITION_APPOINTMENT_MADE";

export const PARAMETERS_APPLICATION_VISITS_AFTER_COMMISSION = "VISITS_AFTER_COMMISSION";
export const PARAMETERS_APPLICATION_VISITS_AFTER_COMMISSION_KEY = "visitAfterCommissionEnabled";

export const PARAMETERS_APPLICATION_PASSWORD_KEY = "password";
export const PARAMETERS_APPLICATION_PASSWORD_CHECK_CHAR_REQUIRED_CODE = "PASSWORD_char_min";
export const PARAMETERS_APPLICATION_PASSWORD_CHECK_CHAR_REQUIRED_KEY = "charRequired";
export const PARAMETERS_APPLICATION_PASSWORD_CHECK_UPPERCASE_REQUIRED_CODE = "PASSWORD_uppercase";
export const PARAMETERS_APPLICATION_PASSWORD_CHECK_UPPERCASE_REQUIRED_KEY = "uppercaseRequired";
export const PARAMETERS_APPLICATION_PASSWORD_CHECK_LOWERCASE_REQUIRED_CODE = "PASSWORD_lowercase";
export const PARAMETERS_APPLICATION_PASSWORD_CHECK_LOWERCASE_REQUIRED_KEY = "lowercaseRequired";
export const PARAMETERS_APPLICATION_PASSWORD_CHECK_NUMBER_REQUIRED_CODE = "PASSWORD_number";
export const PARAMETERS_APPLICATION_PASSWORD_CHECK_NUMBER_REQUIRED_KEY = "numberRequired";
export const PARAMETERS_APPLICATION_PASSWORD_CHECK_SPECIAL_CHAR_REQUIRED_CODE = "PASSWORD_special_char";
export const PARAMETERS_APPLICATION_PASSWORD_CHECK_SPECIAL_CHAR_REQUIRED_KEY = "specialCharRequired";
export const PARAMETERS_APPLICATION_PASSWORD_CHECK_SPECIAL_CHAR_LIST_CODE = "PASSWORD_special_char_list";
export const PARAMETERS_APPLICATION_PASSWORD_CHECK_SPECIAL_CHAR_LIST_KEY = "specialCharList";
export const PARAMETERS_APPLICATION_FAKE_EXTERNAL_CALL = "FAKE_EXTERNAL_CALL";

export const PARAMETERS_APPLICATION_HIDE_REFUSED_PROPOSALS_CODE = "HIDE_REFUSED_PROPOSALS";
export const PARAMETERS_APPLICATION_HIDE_REFUSED_PROPOSALS_KEY = "hideRefusedProposals";
export const PARAMETERS_APPLICATION_CHECK_ADDRESS = "CHECK_ADDRESS";
export const MESSAGE_ENABLED = "MESSAGE_ENABLED";

export const SSO_ENABLED = "SSO_ENABLED";
export const PARAMETERS_CALL_TO_GED = "CALL_TO_GED";

export const SSO_ACTIVATED = 1;
export const SSO_DISABLED = 0;
export const PORTAIL_THEMING = "PORTAIL_THEMING";
export const THEME_WHITE_LABEL = "0";
export const THEME_CUSTOM = "1";
export const PARAMETERS_APPLICATION_CODE_URL_EL = 'URL_EL'
