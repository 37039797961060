import { PAYMENT_BY_CB, PAYMENT_BY_CHEQUE } from "consts/Payment.const";

export const ConnectPaymentMode = (data) => {
  let paymentModes = {};
  data.forEach((d) => {
    if (d.type === PAYMENT_BY_CB) {
      paymentModes.cb = {
        id: d.id,
        name: d.name,
        type: d.type,
      };
    } else if (d.type === PAYMENT_BY_CHEQUE) {
      paymentModes.cheque = {
        id: d.id,
        name: d.name,
        type: d.type,
        initPaymentLink: d._links.init_payment,
      };
    }
  });

  return paymentModes;
};

export const ConnectDepositCurrentStatus = (data) => {
  const currentPayment = data.shift();
  if (currentPayment) {
    return {
      id: currentPayment.id,
      isValid: currentPayment.isValid,
      isCancel: currentPayment.isCancel,
      paymentMode: currentPayment.companyPaymentMode?.type,
    };
  }

  return null;
};

export const ConnectPaymentCompany = (data) => {
  const paymentCompany = {
    id: data.id,
    city: data.city,
    postalCode: data.postalCode,
    address: data.address,
    additionalAddress: data.additionalAddress,
    email: data.email,
  };

  return paymentCompany;
};

export const ConnectScelliusRedirectionForm = (data) => {
  return {
    vadsSiteId: data.vadsSiteId,
    vadsActionMode: data.vadsActionMode,
    vadsCaptureDelay: data.vadsCaptureDelay,
    vadsCtxMode: data.vadsCtxMode,
    vadsCurrency: data.vadsCurrency,
    vadsTransDate: data.vadsTransDate,
    vadsAmount: data.vadsAmount,
    vadsOrderId: data.vadsOrderId,
    vadsTransId: data.vadsTransId,
    vadsPageAction: data.vadsPageAction,
    vadsPaymentCard: data.vadsPaymentCard,
    vadsPaymentConfig: data.vadsPaymentConfig,
    vadsVersion: data.vadsVersion,
    vadsUrlReturn: data.vadsUrlReturn,
    vadsValidationMode: data.vadsValidationMode,
    vadsCustId: data.vadsCustId,
    signature: data.signature,
    vadsActionUrl: data.vadsActionUrl
  };
};
