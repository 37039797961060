import styled, { css } from "styled-components";
import { fontWeight, breakpoints } from "../../global-styles.js";
import validImg from "../../assets/images/valid.png";
import invalidImg from "../../assets/images/invalid.png";

const StyledH2 = styled.h2`
  color: ${(props) => props.color || "var(--color-primaryColor)"};
  font-size: 1.125rem;
  font-weight: ${(props) => (props.fweight ? fontWeight[props.fweight] : fontWeight.bold)};
  margin: ${(props) => props.margin || "0 0 0.5rem"};
  text-align: ${(props) => props.align || "left"};
  position: relative;

  ${(props) =>
    props.small &&
    css`
      font-size: 1rem;
    `}

  ${(props) =>
    props.medium &&
    css`
      font-size: 1.25rem;
    `}

  ${(props) =>
    props.large &&
    css`
      font-size: 1.75rem;
    `}

  ${breakpoints.tablet`
    font-size: 22px;
  `}

  &.h2--success:after,
  &.h2--error:after {
    content: "";
    background: url(${validImg}) no-repeat;
    display: block;
    width: 27px;
    height: 27px;
    position: absolute;
    right: 0;
    top: 0;
  }

  &.h2--error:after {
    background: url(${invalidImg}) no-repeat;
  }

  &.h2--icon {
    display: flex;
    justify-content: center;
    align-items: center;

    svg,
    img {
      flex-basis: 27px;
      margin-left: 1.25rem;
    }
  }
`;

export default StyledH2;
