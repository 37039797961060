import styled, { css } from "styled-components";
import selectArrow from "../../assets/images/select-arrow.png";

export const FormSelectStyle = css`
  border: 1px solid var(--color-secondaryColorLighter);
  border-radius: 3px;
  height: 38px;
  padding-left: 3px;
  width: 100%;
  appearance: none;
  outline: 0;
  box-shadow: none;
  flex: 1;
  padding: 0 0.5em;
  cursor: pointer;

  background-color: var(--color-whiteColor);
  background-image: url(${selectArrow});
  background-position: calc(100% - 0.5rem) center;
  background-repeat: no-repeat;

  /* Remove IE arrow */
  &::-ms-expand {
    display: none;
  }
`;

const StyledFormSelect = styled.select`
  ${FormSelectStyle};
`;

export default StyledFormSelect;
