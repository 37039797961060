import styled, { css } from "styled-components";
import { breakpoints } from "@agence-numerique/common/lib/global-styles.js";
import {
  THEME_WHITE_LABEL,
  THEME_CUSTOM,
} from "@agence-numerique/common/lib/consts/ParametersApplication.const";

const StyledLoginWrapperRightCustom = styled.div`

/* White Label theme */
 ${props =>
      props.theme &&
    props.theme === THEME_WHITE_LABEL &&
      css`
        height: 100%;
        width: 100%;
        text-align: left;

        a {
          color: var(--color-whitelabel-link);
          font-size: var(--font-size-whitelabel-link);
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
          color: var(--color-whitelabel-link-hover);
        }

        img {
          width: 150px !important;
          height: auto !important;
          vertical-align: middle !important;
          margin-bottom: 15px!important;
          margin-top: 20px!important;
        }

        .link--withIcon img{
          width: auto !important;
        }
      `}

      /* Custom theme */
  ${props =>
      props.theme &&
    props.theme === THEME_CUSTOM &&
      css`
        height: 100%;
        width: 100%;
        text-align: left;
        padding: 40px;

         a {
          color: var(--color-custom-button-hover);
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
          color: var(--color-custom-button);
        }

         ${breakpoints.tablet`
    margin: auto;
    width: 60%;
    padding: 0px 100px 15px 100px;
  `}
      `}



  img {
    width: auto;
    height: 100px;
    vertical-align: middle;
  }

  a img {
    height: auto;
  }



        .logo {
          margin: 1rem auto 2rem;
        }
      `;

export default StyledLoginWrapperRightCustom;