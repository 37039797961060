import {
  GET_PARAMETERS,
  SAVE_APPLICATION_PARAMETERS,
  SET_USER,
  ACTION_LIST_ALLOWED,
  SET_LOADING_USER,
  SET_TOAST_MESSAGE,
  CLEAN_TOAST,
  UPDATE_INSURANCE_INFO,
  UPDATE_PAYMENT_STATUS,
  UPDATE_PAYMENT_STATUS_TO_VALID,
  UPDATE_KEYHANDOVER_TYPE,
  UPDATE_DEBITMANDATE,
} from "./CommonTypes";

export function getParametersAction(payload) {
  return {
    type: GET_PARAMETERS,
    payload,
  };
}

export function saveApplicationParametersAction(payload) {
  return {
    type: SAVE_APPLICATION_PARAMETERS,
    payload,
  };
}

export function setCurrentUserAction(payload) {
  return {
    type: SET_USER,
    payload,
  };
}

export function setActionListAllowed(payload) {
  return {
    type: ACTION_LIST_ALLOWED,
    payload,
  };
}

export function setLoadingUserAction(payload) {
  return {
    type: SET_LOADING_USER,
    payload,
  };
}

export function setToastMessageAction(payload) {
  return {
    type: SET_TOAST_MESSAGE,
    payload,
  };
}

// Remove toast message from store after delay
export function cleanToastAction() {
  return {
    type: CLEAN_TOAST,
  };
}

export function updateInsuranceInfosAction(payload) {
  return {
    type: UPDATE_INSURANCE_INFO,
    payload,
  };
}

export function updatePaymentStatusAction(payload) {
  return {
    type: UPDATE_PAYMENT_STATUS,
    payload,
  };
}

export function updatePaymentStatusToValidAction(payload) {
  return {
    type: UPDATE_PAYMENT_STATUS_TO_VALID,
    payload,
  };
}

export function updateKeyHandOverModeAction(payload) {
  return {
    type: UPDATE_KEYHANDOVER_TYPE,
    payload,
  };
}

export function updateDebitMandateAction(payload) {
  return {
    type: UPDATE_DEBITMANDATE,
    payload,
  };
}