import { APPLICANTFILE_STATUS_COMPLETE } from "consts/ApplicantFile.const";

export const ApplicantFileStats = (data, status) => {
  return status === APPLICANTFILE_STATUS_COMPLETE
    ? {
        recorded: 0,
        accepted: data.stats.nb_total,
        total: data.stats.nb_total,
      }
    : {
        recorded: data.stats.nb_recorded,
        accepted: data.stats.nb_accepted,
        total: data.stats.nb_total,
      };
};

export const ApplicantFileReturnDocumentConnector = (document) => {
  return ApplicantFileDocumentErrorConnector({
    id: document.document.id,
    name: document.document.name,
    status: document.status,
    comment: document.comment,
    uri: document.document.uri,
    documentTypeStatus: document.applicant_file_supporting_document_type.status.code,
    applicantFileStatus: document.applicant_file_supporting_document_type.applicantFile.status.code,
    stats: ApplicantFileStats(document, document.applicant_file_supporting_document_type.applicantFile.status.code),
  });
};

export const ApplicantFileProcessResponseConnector = (data) => {
  return {
    documentTypeStatus: data.applicant_file_supporting_document_type.status.code,
    applicantFileStatus: data.applicant_file_supporting_document_type.applicantFile.status.code,
    comment: data.comment,
    stats: ApplicantFileStats(data, data.applicant_file_supporting_document_type.applicantFile.status.code),
  };
};

const ApplicantFileDocumentErrorConnector = (document) => {
  const regep = /.*\{"error":\{.*"code":(\d+),.*"message":"(.+)"\}/;
  const match = document.comment?.match(regep);

  return {
    ...document,
    errorCode: match ? match[1] : null,
    errorMessage: match ? match[2] : null,
    externalErrors: document.errors
      ? Object.entries(document.errors).map(([externalErrorKeys, externalErrorCode]) => {
          return {
            externalErrorKeys: externalErrorKeys,
            externalErrorCode: externalErrorCode,
          };
        })
      : [],
  };
};

export const ApplicantFileDocumentTypeConnector = (document_type) => {
  return {
    id: document_type.id,
    code: document_type.code,
    name: document_type.name,
    status: document_type.status,
    max: document_type.max,
    comment: document_type.comment,
    documents: document_type.documents ? document_type.documents.map(ApplicantFileDocumentErrorConnector) : [],
    type: document_type.type,
  };
};

export const ApplicantFileConnector = (data) => {
  const applicantFile = {
    id: data.id,
    status: data.status,
    stats: ApplicantFileStats(data, data.status),
    targets: Object.entries(data.documents_types).map(([code, document_types]) => {
      return {
        code: code,
        documentTypes: document_types.map((document_type) => ApplicantFileDocumentTypeConnector(document_type)),
      };
    }),
  };

  return applicantFile;
};

export const ApplicantFileDeleteResponseConnector = (data) => {
  return {
    documentTypeStatus: data.applicant_file_supporting_document_type.status.code,
    applicantFileStatus: data.applicant_file_supporting_document_type.applicantFile.status.code,
    stats: ApplicantFileStats(data, data.applicant_file_supporting_document_type.applicantFile.status.code),
  };
};
