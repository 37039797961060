import styled, { css } from "styled-components";
import StyledFormField from "./StyledFormField";

const StyledFormFieldWrapper = styled.div`
  position: relative;

  & + & {
    margin-top: 1rem;
  }

  input,
  textarea {
    ${StyledFormField};
  }

  textarea {
    height: auto;
    max-height: 82px;
  }

  ${(props) =>
    props.labelPos &&
    props.labelPos === "left" &&
    css`
    display: flex;
    align-items: center;

    label {
      margin 0 0.5rem 0 0;
    }
  `}

  /* Icon style */
  .magnifier--right {
    display: flex;
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
  }

  input:focus-visible {
    border: solid 2px blue;
  }
`;

export default StyledFormFieldWrapper;
