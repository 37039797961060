export const ROUTE_PARAMETERS = "/api/re/v1/parameters";
export const ROUTE_KEY_ME = "me";
export const ROUTE_KEY_USER_ACTION_LIST = "user_action_list";
export const ROUTE_KEY_REFRESH_TOKEN = "refresh_token";
export const ROUTE_KEY_ROOT_PROPOSITIONS = "list_proposition";
export const ROUTE_KEY_LIST_PROPOSALS = "list_proposals";
export const ROUTE_KEY_GET_PROPOSAL = "get_proposal";
export const ROUTE_KEY_LOGIN_REDIRECT = "login_redirect";
export const ROUTE_KEY_RESET_PASSWORD = "reset_password";
export const ROUTE_KEY_ASK_RESET_PASSWORD = "ask_reset_password";
export const ROUTE_KEY_LIST_CGU = "list_cgu";
export const ROUTE_KEY_LOGIN = "login";
export const ROUTE_KEY_LOGOUT = "logout";
export const ROUTE_KEY_SSO_GET_TOKEN = "get_bearer";
export const ROUTE_KEY_SSO_LOGOUT = "logout_saml";
export const URL_ECHOPARK_LOGIN_PAGE = "login_saml";
export const ROUTE_KEY_FOLDER = "list_folder";
export const ROUTE_KEY_LIST_ESTATEAGENTS = "list_estateagents";
export const ROUTE_KEY_CREATE_MANUAL_VISIT = "create_visit";
export const ROUTE_KEY_CHANGE_FOLDER_MANAGER = "housingrequest_reassign";
export const ROUTE_KEY_HOUSING_REQUEST_HISTORIES = "housing_request_histories";
export const ROUTE_KEY_GET_USER_LOGS = "get_user_logs";
export const ROUTE_KEY_GET_DASHBOARD_STATISTICS = "statistics_housingrequest_folder";
export const ROUTE_KEY_LIST_TASKS = "list_task";
export const ROUTE_KEY_GET_APPOINTMENTS = "list_visit";

export const ROUTE_KEY_LIST_ESTATEAGENTS_CALENDLY = "list_estateagentscalendly";
export const ROUTE_KEY_POST_CALENDLY = "create_calendly";
export const ROUTE_KEY_REASSIGN_CALENDLY = "reassign_calendly";
export const ROUTE_KEY_IFRAME_CALENDLY = "iframe_calendly";
export const ROUTE_KEY_IFRAME_CALENDLY_PROPOSAL = "iframe_calendly_proposal";
export const ROUTE_KEY_LIST_CALENDLY = "list_calendly";
export const ROUTE_KEY_LIST_CALENDLY_FOR_AGENT = "list_calendly_foragent";
export const ROUTE_KEY_SAVE_CALENDLY_KEY = "save_api_key_calendly";
export const ROUTE_KEY_CANCEL_CALENDLY_APPOINTMENT = "cancel_calendly";
export const ROUTE_KEY_IFRAME_CALENDLY_MOCK = "mock_calendly_hook";

export const ROUTE_KEY_HOUSINGREQUEST = "request";
export const ROUTE_KEY_LIST_HOUSINGREQUEST = "list_request";
export const ROUTE_KEY_GET_HOUSINGREQUEST = "get_request";
export const ROUTE_KEY_HOUSINGREQUEST_CHANGE_STATUS = "housing_request_change_status";

export const ROUTE_KEY_REQUEST_NEXT_STEPS = "request_nextstep";
export const ROUTE_KEY_REQUEST_CHANGE_STEPS = "request_move";

export const ROUTE_KEY_APPLICANTFILE = "root_applicant_file";
export const ROUTE_KEY_APPLICANTFILE_SAVE = "root_applicant_file_save";
export const ROUTE_KEY_APPLICANTFILE_COMPLETE = "root_applicant_file_complete";
export const ROUTE_KEY_APPLICANTFILE_VALIDATE = "root_applicant_file_validate";
export const ROUTE_KEY_APPLICANTFILE_LIST = "applicant_file_list";
export const ROUTE_KEY_DOCUMENT = "root_applicant_file_document";
export const ROUTE_KEY_DOCUMENT_ID = "root_applicant_file_document_id";
export const ROUTE_KEY_APPLICANTFILE_REMIND = "applicant_file_remind"

export const ROUTE_KEY_VALIDATE_CHECK = "securitydeposit_validate_check";

export const ROUTE_KEY_LIST_CONVERSATION = "list_conversation";
export const ROUTE_KEY_GET_CONVERSATION = "get_conversation";
export const ROUTE_KEY_READ_CONVERSATION = "read_conversation";
export const ROUTE_KEY_POST_MESSAGE_CONVERSATION = "post_message_conversation";
export const ROUTE_KEY_LIST_CONVERSATION_PROSPECT = "message_prospect";
export const ROUTE_KEY_LIST_CONVERSATION_PROSPECT_READ = "message_prospect_read";
export const ROUTE_KEY_UPLOAD_MESSAGE_DOCUMENT = "upload_message_document";
export const ROUTE_KEY_DOWNLOAD_MESSAGE_DOCUMENT = "conversation_download_file";
export const ROUTE_KEY_ROUTING = "list_routing";
export const ROUTE_KEY_ROUTING_EXECUTE = "message_v1_routing_execute";
export const ROUTE_KEY_BREADCRUMB = "housing_request_breadcrumb_tail";
export const ROUTE_KEY_LIST_TASK_TYPES = "list_task_types";

export const ROUTE_KEY_CHECKIN_GET = "housingrequest_lease_checkin_get";
export const ROUTE_KEY_CHECKIN_PLAN = "housingrequest_lease_checkin_plan";
export const ROUTE_KEY_CHECKIN_ANSWER = "housingrequest_lease_checkin_confirm";

export const ROUTE_KEY_GET_COMPANY_INFO = "proposition_v1_company_list";
export const ROUTE_KEY_GET_AGENCY_INFO = "proposition_v1_agency_list";
export const ROUTE_KEY_LIST_COMPANY_PAYMENT_MODE = "list_companypaymentmode";
export const ROUTE_KEY_LIST_SECURITY_DEPOSIT_PAYMENT_MODE = "list_security_deposit_mode";
export const ROUTE_KEY_GET_SECURITY_PAYMENT = "get_security_payment";
export const ROUTE_KEY_SCELLIUS_REDIRECTION_FORM = "scellius_redirection_form";
export const ROUTE_KEY_SCELLIUS_REDIRECTION_FORM_MOCK = "mock_scellius_redirection_form";
export const ROUTE_KEY_GET_SCELLIUS_REDIRECTION_FORM = "get_scellius_redirection_form";
export const ROUTE_KEY_ROOT_PROPOSITION = "root_proposition";
export const ROUTE_KEY_REMIND_SECURITY_DEPOSIT = "remind_lease_security_deposit";

export const ROUTE_KEY_CREATE_HOUSINGCOMISSION = "create_housingcommission";
export const ROUTE_KEY_UPDATE_HOUSINGCOMISSION_STATUS = "update_housingcommission_status";

export const ROUTE_KEY_LIST_INSURERS = "list_insurers";
export const ROUTE_KEY_CREATE_INSURANCE = "create_insurance";
export const ROUTE_KEY_PUT_INSURANCE = "put_insurance";
export const ROUTE_KEY_PUT_INSURANCE_STATUS = "put_insurance_status";
export const ROUTE_KEY_PUT_INSURANCE_FILE = "put_insurance_file";
export const ROUTE_KEY_DOWNLOAD_INSURANCE_FILE = "insurance_download_file";
export const ROUTE_KEY_POSSIBLE_COUNTRIES = "directdebit_countries";
export const ROUTE_KEY_POSSIBLE_DEBIT_DAYS = "companies_periodedays";
export const ROUTE_KEY_POSSIBLE_DEBIT_DAYS_FOR_COMPANY = "companies_periodedays_for_company";
export const ROUTE_KEY_MANDATE_DEBIT = "housingrequest_directdebit";
export const ROUTE_KEY_MANDATE_DEBIT_DOCUMENT = "housingrequest_directdebit_document";
export const ROUTE_KEY_MANDATE_REDIRECT_URL = "slimpay_redirecturl";
export const ROUTE_KEY_MANDATE_REDIRECT_URL_MOCK = "mock_slimpay_redirecturl";
export const ROUTE_KEY_FOLDER_SPREADSHEET = "folder_spreadsheet";
export const ROUTE_KEY_GET_BAIL_DOCUMENTS = "get_bail_documents";
export const ROUTE_KEY_GET_BAIL_DOCUMENT_STREAM = "get_bail_document_stream";

export const ROUTE_KEY_STATISTICS_PROPOSAL = "statistics_proposition_proposal";
export const ROUTE_KEY_STATISTICS_APPOINTMENT = "statistics_propostion_appointment";
export const ROUTE_KEY_STATISTICS_APPLICANTFILE = "statistics_applicantfile_folder";
export const ROUTE_KEY_STATISTICS_LEASE = "statistics_request_lease";

export const ROUTE_KEY_GET_EDL = "housingrequest_edl";
export const ROUTE_KEY_GET_EDL_STATUS = "housingrequest_edl_status";

export const ROUTE_KEY_PARAM_APP_LIST = "parameter_application_list";

export const ROUTE_KEY_PUT_KEY_HANDOVER = "put_key_handover_mode";
export const ROUTE_KEY_LIST_KEYHANDOVER_MODE = "keyhandovermode_list";
export const ROUTE_KEY_UPDATE_KEY_HANDOVER = "rentalfile_v1_keyhandovermode_update";
export const ROUTE_KEY_PUT_KEY_HANDOVER_HOUR = "keyhandover_attribute_appointment";
export const ROUTE_KEY_PUT_KEYBOX_CODE = "keyhandover_attribute_keyboxcode";
export const ROUTE_KEY_CANCEL_KEY_HANDOVER_CHOICES = "keyhandover_mode_choice_cancel";

export const ROUTE_KEY_GET_ASSISTANCE_MEANS = "housingassistance_list";
export const ROUTE_KEY_POST_ASSISTANCE_MEANS = "housingassistance_update";
export const ROUTE_KEY_FIRST_LOGIN = "init_passwordlogin";
export const ROUTE_KEY_ASK_FIRST_CONNEXION_MAIL = "ask_manual_user_activation";

export const ROUTE_KEY_CANCEL_EDL = "cancel_edl";

export const PLACEHOLDER_GENERIC_ID = "{id}";
export const PLACEHOLDER_HOUSING_REQUEST_ID = "{housing_request_id}";
export const PLACEHOLDER_HOUSING_COMMISSION_ID = "{housing_commission_id}";
export const PLACEHOLDER_STATUS = "{status}";
export const PLACEHOLDER_PROPOSAL_ID = "{proposition_id}";
export const PLACEHOLDER_DOCUMENT_ID = "{document_id}";
export const PLACEHOLDER_DOCUMENT_TYPE = "{document_type}";
export const PLACEHOLDER_FRONT_URL = "{front_url}";
export const PLACEHOLDER_PAYMENT_ID = "{payment_id}";
export const PLACEHOLDER_COMPANY_ID = "{company_id}";
export const PLACEHOLDER_INSURANCE_ID = "{insurance_id}";
export const PLACEHOLDER_REQUEST_ID = "{request_id}";
export const PLACEHOLDER_STEP_ID = "{step_id}";
export const PLACEHOLDER_RENTALFILE_ID = "{rental_file_id}";
export const PLACEHOLDER_CALENDLY_ID = "{calendly_visit_id}";
export const PLACEHOLDER_ESTATEAGENT_ID = "{estateagent_id}";
export const PLACEHOLDER_CONVERSATION_ID = "{conversation_id}";
export const PLACEHOLDER_ROUTING_ID = "{routing_id}";