import React from "react";
import PropTypes from "prop-types";

import StyledFormCheckbox from "./StyledFormCheckbox";

const FormCheckbox = ({ className, name, value, label, labelHtmlUnsafe, ...props }) => (
  <StyledFormCheckbox {...props} className={className}>
    <input type="checkbox" name={name} value={value} /> {labelHtmlUnsafe ? <span dangerouslySetInnerHTML={{__html: labelHtmlUnsafe}}></span> : label}
  </StyledFormCheckbox>
);

FormCheckbox.propTypes = {
  /** Classes supplémentaires à attribuer au composant */
  className: PropTypes.oneOf(["checkbox-cgu"]),
  /** Libellé à aficher pour la checkbox */
  label: PropTypes.string,
  /** Libellé HTML à aficher pour la checkbox */
  labelHtmlUnsafe: PropTypes.string,
  /** Nom du groupe de checkbox */
  name: PropTypes.string,
  /** Valeur de la checkbox */
  value: PropTypes.string,
};

export default FormCheckbox;
