export const COOKIE_PORTAIL_URI = "portail_uri";

// FIXME : TO REMOVE AS SOON AS POSSIBLE, DEV CODE
export const URL_DEV_PORTAIL = "https://localhost:3001";
export const URL_DEV_ESPACE_PROSPECT = "https://localhost:3002";
export const URL_DEV_ESPACE_GESTIONNAIRE = "https://localhost:3004";

export const PROFIL_PROSPECT = "prospect";
export const PROFIL_ESTATEAGENT = "estateagent";

export const LIST_URL_DEV_BY_USER_TYPE = {
  [PROFIL_PROSPECT]: URL_DEV_ESPACE_PROSPECT,
  [PROFIL_ESTATEAGENT]: URL_DEV_ESPACE_GESTIONNAIRE,
};

export const CALENDLY_URI = "https://assets.calendly.com/assets/external/widget.js";
export const CALENDLY_CANCEL_URL = "https://calendly.com/cancellations";
