import styled, { css } from "styled-components";
import { ButtonStyle, ButtonSecondaryStyle, ButtonTernaryStyle, ButtonWhiteLabel, ButtonCustom } from "./ButtonStyle";
import { THEME_WHITE_LABEL, THEME_CUSTOM } from "@agence-numerique/common/lib/consts/ParametersApplication.const";

const StyledButton = styled.button`
  ${ButtonStyle};
  width: ${props => props.width || "100%"};
  height: ${props => props.height || "inherit"};
  padding: ${props => props.padding || "0.625rem 2rem"} ;
  display: ${props => props.display || "block"} ;

  ${props =>
    props.icon &&
    css`
      display: flex;
      padding: ${props => props.padding || "0.625rem 2rem 0.625rem 1rem"};
      svg {
        height: 1rem;
        margin-left: 0;
        margin-right: 0.25rem;
      }
      path {
        fill: var(--color-whiteColor);
      }
    `}

${props =>
  props.size &&
  props.size === "small" &&
  css`
    padding: 0.625rem;
  `}

  ${props =>
    props.unavailable &&
    css`
      filter: blur(1px);
      opacity: 0.7;
      pointer-events: none;
    `}

  ${props =>
    props.theme &&
    props.theme === "secondary" &&
    css`
      ${ButtonSecondaryStyle};
      ${props =>
        props.icon &&
        css`
          path {
            fill: var(--color-secondary);
          }
        `}
    `}

  ${props =>
    props.theme &&
    props.theme === "ternary" &&
    css`
      ${ButtonTernaryStyle};
    `}

   ${props =>
    props.theme &&
    props.theme === THEME_WHITE_LABEL &&
    css`
      ${ButtonWhiteLabel};
    `}

  ${props =>
    props.theme &&
    props.theme === THEME_CUSTOM &&
    css`
      ${ButtonCustom};
    `}

  ${props =>
    props.align &&
    props.align === "right" &&
    css`
      margin: 0 0 0 auto;
    `}

  ${props =>
    props.align &&
    props.align === "left" &&
    css`
      margin: 0 auto 0 0;
    `}

    ${props =>
    props.align &&
    props.align === "center" &&
    css`
      margin: 0 auto;
    `}

    ${props =>
    props.width &&
    props.width === "justified" &&
    css`
      width: none;
    `}


  ${props =>
    props.inactiv &&
    css`
      background: var(--color-neutralSecondary);
      border: 1px solid var(--color-neutralSecondary);
      color: var(--color-whiteColor) !important;
      &:hover {
        background: var(--color-neutralSecondary);
        color: var(--color-whiteColor);
        cursor: default;
        pointer-event: none;
      }
    `};

  ${props =>
    props.isLoading &&
    css`
      position: relative;
      cursor: not-allowed;
      .button__label {
        opacity: 0;
      }
    `};

  margin: ${props => props.margin || null};
`;

export default StyledButton;
