import styled, { css } from "styled-components";
import { ButtonStyle, ButtonSecondaryStyle, ButtonTernaryStyle } from "components/Button/ButtonStyle";
import { AIconStyle } from "./AStyle";
import selectArrow from "assets/images/select-arrow.png";

const StyledA = styled.a`
  font-size: ${props => (props.fsize ? props.fsize : "14px")};
  text-decoration: ${props => (props.notUnderline ? null : "underline")};
  color: ${props => props.color || "var(--color-neutralDark)"};

  ${props =>
    props.icon &&
    css`
      ${AIconStyle}
      &:before {
        background-image: url(${props.icon});
      }
    `}

  ${props =>
    props.expandable &&
    css`
      ${AIconStyle}
      &:before {
        background-image: url(${selectArrow});
        transform: rotate(-90deg);
      }
      &.expandable--isOpen:before {
        transform: rotate(0deg);
      }
    `}

  ${props =>
    props.skin &&
    css`
      text-decoration: none;
      cursor: pointer;
      ${props.skin === "button" &&
        css`
          ${ButtonStyle};
        `}
      ${props.skin === "button-secondary" &&
        css`
          ${ButtonSecondaryStyle};
        `}
      ${props.skin === "button-ternary" &&
        css`
          ${ButtonTernaryStyle};
        `}
      ${props.skin === "link" &&
        css`
          color: var(--color-primaryColor);
          text-decoration: underline;
        `};
    `}

    ${props =>
      props.skin &&
      props.inactiv &&
      css`
        background: var(--color-neutralSecondary);
        border: 1px solid var(--color-neutralSecondary);
        color: var(--color-whiteColor) !important;
        pointer-events: none;
        &:hover {
          background: var(--color-neutralSecondary);
          color: var(--color-whiteColor);
          cursor: default;
        }
      `};

    
`;

export default StyledA;
