import API from "@agence-numerique/common/lib/utils/API";
import docCookies from "@agence-numerique/common/lib/utils/Cookie";
import {
  ROUTE_KEY_LOGIN,
  ROUTE_KEY_RESET_PASSWORD,
  ROUTE_KEY_ASK_RESET_PASSWORD,
  ROUTE_KEY_ME,
  ROUTE_KEY_LOGIN_REDIRECT,
  ROUTE_KEY_FIRST_LOGIN,
  ROUTE_KEY_SSO_GET_TOKEN,
  URL_ECHOPARK_LOGIN_PAGE,
} from "@agence-numerique/common/lib/consts/Parameters.const";

import { ConnectMe } from "connectors/Me.connector";
import store from "store";

function getParameterUrlFromStore(key) {
  return store.getState().CommonReducers.parameters[key];
}

async function login(login, password, remember) {
  const endpoint = getParameterUrlFromStore(ROUTE_KEY_LOGIN);

  const response = await API.post(endpoint, {
    _username: login,
    _password: password,
  });
  if (response.data?.token && response.data?.refresh_token) {
    docCookies.setItem("token", response.data.token, remember ? parseFloat(process.env.REACT_APP_COOKIE_EXPIRE) : null);
    if (remember) {
      docCookies.setItem(
          "refresh_token",
          response.data.refresh_token,
          remember ? parseFloat(process.env.REACT_APP_COOKIE_EXPIRE) : null,
        );
    } else {
      docCookies.removeItem("refresh_token");
    }
  }

  return response.data;
}

async function resetPassword(password, token) {
  const endpoint = getParameterUrlFromStore(ROUTE_KEY_RESET_PASSWORD);
  const response = await API.post(endpoint, {
    password,
    token,
  });
  return response;
}

async function firstLogin(password, token, login) {
  const endpoint = getParameterUrlFromStore(ROUTE_KEY_FIRST_LOGIN);
  const response = await API.post(endpoint, {
   password,
   token,
  login
  });
  return response;
}

async function requestResetMail(email) {
  const endpoint = getParameterUrlFromStore(ROUTE_KEY_ASK_RESET_PASSWORD);
  const response = await API.post(endpoint, {
    email: email,
  });
  return response;
}

async function getMe() {
  const endpoint = getParameterUrlFromStore(ROUTE_KEY_ME);
  const response = await API.get(endpoint);
  return ConnectMe(response.data);
}

async function getRedirect() {
  const endpoint = getParameterUrlFromStore(ROUTE_KEY_LOGIN_REDIRECT);
  const response = await API.get(endpoint);
  return response.data.espace_url;
}

async function loginOnBeHalf(emailOrUsername) {
  const endpoint = '/api/re/v1/users/switch/{emailOrUsername}';
  const response = await API.get(endpoint.replace('{emailOrUsername}', emailOrUsername));
  return response.data;
}

async function getSSOTokenAndLogin(token) {
  const endpoint = getParameterUrlFromStore(ROUTE_KEY_SSO_GET_TOKEN);
  const tokens = await API.post(endpoint, {
    token: token,
  });
   if (tokens.data?.token && tokens.data?.refresh_token) {
    docCookies.setItem("token", tokens.data.token,parseFloat(process.env.REACT_APP_COOKIE_EXPIRE));
    docCookies.setItem(
          "refresh_token",
          tokens.data.refresh_token,
          parseFloat(process.env.REACT_APP_COOKIE_EXPIRE),
        );
  }
  return (tokens.data?.token && tokens.data?.refresh_token);
}

async function getSSOUrlSaml() {
  const endpoint = getParameterUrlFromStore(URL_ECHOPARK_LOGIN_PAGE);
  return endpoint;
}

export default { login, resetPassword, requestResetMail, getMe, getRedirect, loginOnBeHalf, firstLogin, getSSOTokenAndLogin, getSSOUrlSaml };
