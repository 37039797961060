import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { breakpoints, settings } from "../../global-styles.js";

const SectionStyle = styled.section`
  background-color: ${props => props.bgColors || null};
  box-shadow: ${props => props.shadowed || null};
  max-width: ${settings.SectionLargeMaxWidth};
  margin: ${props => props.margin || "auto"};
  padding: ${props => props.padding || null};

  ${props =>
    props.backgroundImg &&
    css`
      background-image: url(${props.backgroundImg});
      height: 100%;
      background-size: cover;
    `}

  &.section-home {
    padding-top: 1rem;
    ${breakpoints.tablet`
        padding-top: 3rem;
        max-width: 100%;
      `}
  }

  &.section__cgu {
    padding: 1.25rem 0.5rem;
    position: relative;
  }

  &.section__cookies {
    bottom: 0;
    left: 0;
    border-radius: 0;
    background: var(--color-black-70);
    padding: 1.25rem;
    position: fixed;
    width: 100%;
  }

  &.section__bordered {
    border: 1px solid var(--color-secondaryColorLighter);
    margin-bottom: 1rem;
    padding: 1rem;
  }
  
  &.section__topped {
    padding-top: 1rem;
    padding-left: 8px;
    padding-right: 8px;
  }

  &.section__timeline {
    background: var(--color-primaryColor);
    padding: 0.5rem;

    ${breakpoints.tablet`
      margin: 0 0 0;
      padding: 2rem 1rem 0;
    `}
  }

  ${props =>
    props.size &&
    props.size === "medium" &&
    css`
      max-width: ${settings.SectionMediumMaxWidth};
    `};

  ${props =>
    props.size &&
    props.size === "xlarge" &&
    css`
      max-width: ${settings.SectionXLargeMaxWidth};
    `};

  ${props =>
    props.size &&
    props.size === "fullWidth" &&
    css`
      max-width: 100%;
    `};

  & > section {
    max-width: 100%;
  }
`;

SectionStyle.propTypes = {
  bgColors: PropTypes.string,
  backgroundImg: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  shadowed: PropTypes.string,
  size: PropTypes.string,
};

export default SectionStyle;
