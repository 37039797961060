import styled from "styled-components";
import StyledFormField from "./StyledFormField";

const StyledFormFieldFileWrapper = styled.div`
  width: 100%;
  > span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 1rem 0 0;
  }

  &.form_field_wrapper_applicant {
    border: 1px solid var(--color-secondary);
    border-radius: 2rem;
    padding: 0;

    > span {
      margin: 0;
    }

    .custom-button {
      margin: 0;
      max-width: 50%;
      max-width: 300px;
    }

    label {
      background: transparent;
      border: none;
    }
  }

  & + & {
    margin-top: 1rem;
  }
  &.form_field_wrapper--isLoading label,
  &.form_field_wrapper--isLoading input,
  &.form_field_wrapper--isLoading button {
    cursor: not-allowed !important;
    pointer-events: none !important;
  }

  input[type="file"] {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  label {
    flex: 1;
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    background: var(--color-whiteColor);
    cursor: pointer;
    display: block;
    ${StyledFormField};
    line-height: 35px;
    font-size: 14px;
    margin: 0 0.5rem 0 0;
  }

  label button,
  label button.button--isLoading {
    padding: 0 2rem;
    margin: 0;
    height: 100%;
  }

  a {
    position: absolute;
    right: -30px;
    cursor: pointer;
    img {
      height: 20px;
    }
  }
`;

export default StyledFormFieldFileWrapper;
