import React from "react";
import { Text } from "@agence-numerique/common/lib/components/Paragraph";
import PropTypes from "prop-types";

const LoginErrorText = ({ isfieldValid, error_text }) => {

  return (
    <Text
      padding={"0 0 0"}
      fweight={isfieldValid ? "bold" : "regular"}
      color={isfieldValid ? "var(--color-successColor)" : "var(--color-neutralDark)"}
    >
      {error_text}
    </Text>
  );
};

LoginErrorText.propTypes = {
  isfieldValid: PropTypes.bool,
  error_text: PropTypes.string,
};

export default LoginErrorText;
