import styled, { css } from "styled-components";

const StyledLoaderContent = styled.div`
  position: relative;
  width: 100%;
  height: 50px;

  ${props =>
    props.size === "small" &&
    css`
      display: inline-block;
      width: 20px;
      height: 20px;
    `}
`;

export default StyledLoaderContent;
